import React, { useState, useCallback } from 'react';
import { useUserPreferences } from '../../contexts/UserPreferencesContext';
import {type Marker, MarkerClusterer, MarkerUtils} from '@googlemaps/markerclusterer';
import PoiMarker from "./PoiMarker";

const PoiMarkerMemo = React.memo(PoiMarker);

const PoiComponent = () => {
    const [markers, setMarkers] = useState<{[key: string]: Marker}>({});
    const { pois } = useUserPreferences();
  
    const setMarkerRef = useCallback((marker: Marker | null, id: number) => {
      setMarkers(prevMarkers => {
        if (prevMarkers[id] === marker) {
          return prevMarkers; // No hacer nada si no hay cambios
        }
        const newMarkers = { ...prevMarkers };
        if (marker) {
          newMarkers[id] = marker;
        } else {
          delete newMarkers[id];
        }
        return newMarkers;
      });
    }, []);
  
    return (
      <>
        {pois.map(poi => (
          <PoiMarkerMemo key={poi.id} poi={poi} setMarkerRef={setMarkerRef} />
        ))}
      </>
    )
  };

export default PoiComponent;