import React, { createContext, useContext, useState, ReactNode, useEffect, useRef } from 'react';
import { UserPreferenceData } from '../types/user_preferences';
import { storage } from '../services/storage';
import { getMarkers, getCircularGeofences, getPolygonalGeofences, getLinearGeofences } from '../features/map/Map';
import { useMap } from "@vis.gl/react-google-maps";
import { GroupForSelect } from '../types/user_preferences';
import axios, { CancelTokenSource } from 'axios';

interface UserPreferenceContextProps {
    preferences: UserPreferenceData | null;
    updatePreferences: (newPreferences: UserPreferenceData) => void;

    show_tracking: boolean;
    setShowTracking: (show_tracking: boolean) => void;

    show_device_names: boolean;
    setShowDeviceNames: (showDeviceNames: boolean) => void;

    geofences_names: boolean;
    setGeofencesNames: (geofences_names: boolean) => void;
    
    poi_names: boolean;
    setPoiNames: (poi_names: boolean) => void;
    show_pois: boolean;
    setShowPois: (show_pois: boolean) => void;
    pois: any[];


    show_traffic: boolean;
    setShowTraffic: (show_traffic: boolean) => void;
    
    show_circular_geofences: boolean;
    setShowCircularGeofences: (show_circular_geofences: boolean) => void;
    circular_geofences: any[];
    
    show_polygonal_geofences: boolean;
    setShowPolygonalGeofences: (show_polygonal_geofences: boolean) => void;
    polygonal_geofences: any[];

    show_linear_geofences: boolean;
    setShowLinearGeofences: (show_linear_geofences: boolean) => void;
    linear_geofences: any[];

    map_style: string;
    setMapStyle: (map_style: string) => void;

    show_information: boolean;
    setShowInformation: (information: boolean) => void;
    show_drivers: boolean;
    setShowDrivers: (drivers: boolean) => void;
    show_actions: boolean;
    setShowActions: (actions: boolean) => void;
    show_trailers: boolean;
    setShowTrailers: (trailers: boolean) => void;
    show_events: boolean;
    setShowEvents: (events: boolean) => void;
    show_device_status: boolean;
    setShowDeviceStatus: (device_status: boolean) => void;
    show_device_properties: boolean;
    setShowDeviceProperties: (device_properties: boolean) => void;
    show_quick_view: boolean;
    setShowQuickView: (quick_view: boolean) => void;
    show_foot_print_view: boolean;
    setShowFootPrintView: (foot_print_view: boolean) => void;
    show_individual_view: boolean;
    setShowIndividualView: (individual_view: boolean) => void;
    addNewLinearGeofence: (newLinearGeofences: any) => void;

    measuring_tool_active: boolean;
    setMeasuringToolActive: (measuring_tool_active: boolean) => void;
    search_places_active: boolean;
    setSearchPlacesActive: (search_places_active: boolean) => void;

    new_route_active: boolean;
    setNewRouteActive: (new_route_active: boolean) => void;
    groups_for_select: GroupForSelect[];
    loading_preferences: boolean;

    show_new_circle_geofence: boolean;
    setShowNewCircleGeofence: (show_new_circle_geofence: boolean) => void;
    show_new_polygonal_geofence: boolean;
    setShowNewPolygonalGeofence: (show_new_polygonal_geofence: boolean) => void;

    addCircularGeofence: (newCircularGeofence: any) => void;
    addPolygonalGeofence: (newPolygonalGeofence: any) => void;

    setNewPoint: (newPoint: boolean) => void;
    new_point: boolean;
    addNewPoint: (newPoint: any) => void;
    handleToolActivation: (toolName: string) => void;
    notification_sound_url: string;

}

const UserPreferenceContext = createContext<UserPreferenceContextProps | undefined>(undefined);

interface UserPreferenceProviderProps {
    children: ReactNode;
    initialPreferences: UserPreferenceData;
}
  

export const UserPreferenceProvider: React.FC<UserPreferenceProviderProps> = ({ children, initialPreferences }) => {
    const [preferences, setPreferences] = useState<UserPreferenceData | null>(initialPreferences);
    const [show_tracking, setShowTracking] = useState<boolean>(true);
    const [show_device_names, setShowDeviceNames] = useState<boolean>(initialPreferences.view_preferences.vizualization_options.devices_name);
    const [geofences_names, setGeofencesNames] = useState<boolean>(initialPreferences.view_preferences.vizualization_options.geofences.names);
    const [show_circular_geofences, setShowCircularGeofences] = useState<boolean>(initialPreferences.view_preferences.vizualization_options.geofences.circular);
    
    const [show_polygonal_geofences, setShowPolygonalGeofences] = useState<boolean>(initialPreferences.view_preferences.vizualization_options.geofences.polygonal);
    const [show_linear_geofences, setShowLinearGeofences] = useState<boolean>(initialPreferences.view_preferences.vizualization_options.geofences.linear);
    const [poi_names, setPoiNames] = useState<boolean>(initialPreferences.view_preferences.vizualization_options.points_of_interest.names);
    const [show_pois, setShowPois] = useState<boolean>(false);
    const [show_traffic, setShowTraffic] = useState<boolean>(initialPreferences.view_preferences.vizualization_options.traffic);
    const map = useMap(); 
    const trafficLayerRef = useRef<google.maps.TrafficLayer | null>(null);
    const [map_style, setMapStyle] = useState<string>(initialPreferences.view_preferences.map_style);
    const [pois, setPois] = useState([]);
    const [circular_geofences, setCircularGeofences] = useState([]);
    const [polygonal_geofences, setPolygonalGeofences] = useState([]);
    const [linear_geofences, setLinearGeofences] = useState([]);

    const [show_information, setShowInformation] = useState<boolean>(initialPreferences.view_preferences.device_menu.information);
    const [show_drivers, setShowDrivers] = useState<boolean>(initialPreferences.view_preferences.device_menu.drivers);
    const [show_actions, setShowActions] = useState<boolean>(initialPreferences.view_preferences.device_menu.actions);
    const [show_trailers, setShowTrailers] = useState<boolean>(initialPreferences.view_preferences.device_menu.trailers);
    const [show_events, setShowEvents] = useState<boolean>(initialPreferences.view_preferences.device_menu.events);
    const [show_device_status, setShowDeviceStatus] = useState<boolean>(initialPreferences.view_preferences.device_menu.device_status);
    const [show_device_properties, setShowDeviceProperties] = useState<boolean>(initialPreferences.view_preferences.device_menu.device_properties);
    const [show_quick_view, setShowQuickView] = useState<boolean>(initialPreferences.view_preferences.device_menu.quick_view);
    const [show_foot_print_view, setShowFootPrintView] = useState<boolean>(initialPreferences.view_preferences.device_menu.foot_print_view);
    const [show_individual_view, setShowIndividualView] = useState<boolean>(initialPreferences.view_preferences.device_menu.individual_view);
    const [measuring_tool_active, setMeasuringToolActive] = useState<boolean>(false);
    const [search_places_active, setSearchPlacesActive] = useState<boolean>(false);
    const [new_route_active, setNewRouteActive] = useState<boolean>(false);
    const [groups_for_select, setGroupsForSelect] = useState<GroupForSelect[]>(initialPreferences.groups_for_select);
    const [loading_preferences, setLoadingPreferences] = useState<boolean>(true);
    const cancelTokenRef = useRef<CancelTokenSource | null>(null);
    const cancelGeoRoutesTokenRef = useRef<CancelTokenSource | null>(null);
    const cancelPolygonalGeofencesTokenRef = useRef<CancelTokenSource | null>(null);
    const cancelCircularGeofencesTokenRef = useRef<CancelTokenSource | null>(null);

    const [show_new_circle_geofence, setShowNewCircleGeofence] = useState<boolean>(false);
    const [show_new_polygonal_geofence, setShowNewPolygonalGeofence] = useState<boolean>(false);
    const [previousShowCircularGeofences, setPreviousShowCircularGeofences] = useState<boolean | null>(null);  // Guardar el estado anterior
    const [previousShowPolygonalGeofences, setPreviousShowPolygonalGeofences] = useState<boolean | null>(null);  // Guardar el estado anterior
    const [previousShowLinearGeofences, setPreviousShowLinearGeofences] = useState<boolean | null>(null);  // Guardar el estado
    const [previousShowPois, setPreviousShowPois] = useState<boolean | null>(null);  // Guardar el estado anterior
    const [previousNewRouteActive, setPreviousNewRouteActive] = useState<boolean | null>(null);  // Guardar el estado anterior
    const [previousShowNewCircleGeofence, setPreviousShowNewCircleGeofence] = useState<boolean | null>(null);  // Guardar el estado anterior
    const [previousShowNewPolygonalGeofence, setPreviousShowNewPolygonalGeofence] = useState<boolean | null>(null);  // Guardar el estado anterior
    const [previousPoint, setPreviousPoint] = useState<boolean | null>(null);  // Guardar el estado anterior
    const [new_point, setNewPoint] = useState<boolean>(false);
    const [notification_sound_url, setNotificationSoundUrl] = useState<string>(initialPreferences.notification_sound);

    useEffect(() => {
        storage.setUserInfo(preferences).then(() => {
            storage.setUserInfo(preferences);
            setLoadingPreferences(false);
        }).catch((error) => {
            console.error('Error saving preferences:', error);
        })
    }, [preferences]);

    useEffect(() => {
        if (poi_names && !loading_preferences) {
            setShowPois(true);
            
            const fetchMarkers = async () => {

                // Si existe una solicitud en curso, la cancelamos antes de hacer una nueva
                if (cancelTokenRef.current) {
                    cancelTokenRef.current.cancel('Request canceled due to new request.');
                }
                
                // Creamos un nuevo token de cancelación para la nueva solicitud
                cancelTokenRef.current = axios.CancelToken.source();
                try {
                    const res = await getMarkers(cancelTokenRef.current);
                    if (res.success) {
                        setPois(res.markers);
                    }

                } catch (error) {
                    console.error(error);
                    setShowPois(false);
                }
            }

            fetchMarkers();
        }

        if (!poi_names) {
            setShowPois(false);
        }

        return () => {
            if (cancelTokenRef.current) {
                cancelTokenRef.current.cancel('Request canceled due to component unmount.');
            }
        }

    }, [poi_names, loading_preferences]);

    useEffect(() => {
        if (show_traffic) {
            if (map && !trafficLayerRef.current) {
                const trafficLayer = new google.maps.TrafficLayer();
                trafficLayer.setMap(map);
                trafficLayerRef.current = trafficLayer;
            }
        } else {
            if (trafficLayerRef.current) {
                trafficLayerRef.current.setMap(null);
                trafficLayerRef.current = null;
            }
        }

        return () => {
            if (trafficLayerRef.current) {
                trafficLayerRef.current.setMap(null);
                trafficLayerRef.current = null;
            }
        };

    }, [show_traffic, map]);

    useEffect(() => {
        const fetchCircularGeofences = async () => {
            if (cancelCircularGeofencesTokenRef.current) {
                cancelCircularGeofencesTokenRef.current.cancel('Canceling previous circular geofence request.');
            }
            cancelCircularGeofencesTokenRef.current = axios.CancelToken.source();

            if (show_circular_geofences && !loading_preferences) {
                try {
                    const res = await getCircularGeofences(cancelCircularGeofencesTokenRef.current);
                    if (res.success) {
                        setCircularGeofences(res.circular_geofences);
                    }
                } catch (error) {
                    console.error("Error fetching circular geofences:", error);
                }
            }
        };
    
        fetchCircularGeofences();

        return () => {
            if (cancelCircularGeofencesTokenRef.current) {
                cancelCircularGeofencesTokenRef.current.cancel('Canceling circular geofence request.');
            }
        }


    }, [show_circular_geofences, loading_preferences]);

    useEffect(() => {
        const fetchPolygonalGeofences = async () => {
            if (cancelGeoRoutesTokenRef.current) {
                cancelGeoRoutesTokenRef.current.cancel('Canceling previous circular geofence request.');
            }
            cancelGeoRoutesTokenRef.current = axios.CancelToken.source();

            if (show_polygonal_geofences && !loading_preferences) {
                try {
                    const res = await getPolygonalGeofences(cancelGeoRoutesTokenRef.current);
                    if (res.success) {
                        setPolygonalGeofences(res.geofences);
                    }
                } catch (error) {
                    console.error("Error fetching circular geofences:", error);
                }
            }
        };
    
        fetchPolygonalGeofences();

        return () => {
            if (cancelGeoRoutesTokenRef.current) {
                cancelGeoRoutesTokenRef.current.cancel('Canceling circular geofence request.');
            }
        }

    }, [show_polygonal_geofences, loading_preferences]);

    useEffect(() => {
        const fetchLinearGeofences = async () => {
            if (cancelPolygonalGeofencesTokenRef.current) {
                cancelPolygonalGeofencesTokenRef.current.cancel('Canceling previous circular geofence request.');
            }
            cancelPolygonalGeofencesTokenRef.current = axios.CancelToken.source();

            if (show_linear_geofences && !loading_preferences) {
                try {
                    const res = await getLinearGeofences(cancelPolygonalGeofencesTokenRef.current);
                    if (res.success) {
                        setLinearGeofences(res.georoutes);
                    }
                } catch (error) {
                    console.error("Error fetching circular geofences:", error);
                }
            }
        };
    
        fetchLinearGeofences();

        return () => {
            if (cancelPolygonalGeofencesTokenRef.current) {
                cancelPolygonalGeofencesTokenRef.current.cancel('Canceling circular geofence request.');
            }
        }

    }, [show_linear_geofences, loading_preferences]);
    

    const updatePreferences = (newPreferences: UserPreferenceData) => {
        setPreferences(newPreferences);
    };

    const addNewLinearGeofence = (newLinearGeofence: any) => {
        setLinearGeofences([...linear_geofences, newLinearGeofence]);
    }

    const addCircularGeofence = (newCircularGeofence: any) => {
        setCircularGeofences([...circular_geofences, newCircularGeofence]);
    }

    const addPolygonalGeofence = (newPolygonalGeofence: any) => {
        setPolygonalGeofences([...polygonal_geofences, newPolygonalGeofence]);
    }

    const addNewPoint = (newPoint: boolean) => {
        setPois([...pois, newPoint]);
    }

    useEffect(() => {
        // Verifica si ninguna herramienta está activa
        if (!new_route_active && !measuring_tool_active && !show_new_circle_geofence && !show_new_polygonal_geofence && !new_point) {
        //   console.log("Restaurando los shows previos");
          if (previousShowCircularGeofences !== null) setShowCircularGeofences(previousShowCircularGeofences);
          if (previousShowPolygonalGeofences !== null) setShowPolygonalGeofences(previousShowPolygonalGeofences);
          if (previousShowLinearGeofences !== null) setShowLinearGeofences(previousShowLinearGeofences);
          if (previousShowPois !== null) setShowPois(previousShowPois);
        }
      }, [new_route_active, measuring_tool_active, show_new_circle_geofence, show_new_polygonal_geofence, new_point]); 

      const handleToolActivation = (toolName) => {
        const isActive = {
          new_route: new_route_active,
          measuring_tool: measuring_tool_active,
          new_circle_geofence: show_new_circle_geofence,
          new_polygonal_geofence: show_new_polygonal_geofence,
          new_point: new_point,
        }[toolName];
      
        if (isActive) {
          // Si la herramienta está activa, desactivarla
          setNewRouteActive(false);
          setMeasuringToolActive(false);
          setShowNewCircleGeofence(false);
          setShowNewPolygonalGeofence(false);
          setNewPoint(false);
        } else {
          // Guardar los estados actuales de los shows antes de activar una herramienta
          if (!new_route_active && !measuring_tool_active && !show_new_circle_geofence && !show_new_polygonal_geofence && !new_point) {
            setPreviousShowCircularGeofences(show_circular_geofences);
            setPreviousShowPolygonalGeofences(show_polygonal_geofences);
            setPreviousShowLinearGeofences(show_linear_geofences);
            setPreviousShowPois(show_pois);
          }
      
          // Desactivar todas las herramientas excepto la seleccionada
          setNewRouteActive(toolName === 'new_route');
          setMeasuringToolActive(toolName === 'measuring_tool');
          setShowNewCircleGeofence(toolName === 'new_circle_geofence');
          setShowNewPolygonalGeofence(toolName === 'new_polygonal_geofence');
          setNewPoint(toolName === 'new_point');
      
          // Ocultar los shows mientras la herramienta está activa
          setShowCircularGeofences(false);
          setShowPolygonalGeofences(false);
          setShowLinearGeofences(false);
          setShowPois(false);
        }
      };
      
      
      


    return (
        <UserPreferenceContext.Provider value={{ 
            preferences, 
            updatePreferences,
            show_tracking,
            setShowTracking,
            show_device_names,
            setShowDeviceNames,
            geofences_names,
            setGeofencesNames,
            
            show_circular_geofences,
            setShowCircularGeofences,
            circular_geofences,

            show_polygonal_geofences,
            setShowPolygonalGeofences,
            polygonal_geofences,

            show_linear_geofences,
            setShowLinearGeofences,
            linear_geofences,

            poi_names,
            setPoiNames,
            show_pois,
            setShowPois,
            show_traffic,
            setShowTraffic,
            pois,

            map_style,
            setMapStyle,

            show_information,
            setShowInformation,
            show_drivers,
            setShowDrivers,
            show_actions,
            setShowActions,
            show_trailers,
            setShowTrailers,
            show_events,
            setShowEvents,
            show_device_status,
            setShowDeviceStatus,
            show_device_properties,
            setShowDeviceProperties,
            show_quick_view,
            setShowQuickView,
            show_foot_print_view,
            setShowFootPrintView,
            show_individual_view,
            setShowIndividualView,
            addNewLinearGeofence,

            measuring_tool_active,
            setMeasuringToolActive,
            search_places_active,
            setSearchPlacesActive,
            new_route_active,
            setNewRouteActive,
            groups_for_select,
            loading_preferences,
            show_new_circle_geofence,
            setShowNewCircleGeofence,
            show_new_polygonal_geofence,
            setShowNewPolygonalGeofence,
            addCircularGeofence,
            addPolygonalGeofence,
            setNewPoint,
            new_point,
            addNewPoint,
            handleToolActivation,
            notification_sound_url
        }}>

            {children}
        </UserPreferenceContext.Provider>
    );
};

export const useUserPreferences = (): UserPreferenceContextProps => {
    const context = useContext(UserPreferenceContext);
    if (!context) {
        throw new Error('useUserPreferences must be used within a UserPreferenceProvider');
    }
    return context;
};
