import React, { createContext, useContext, ReactNode, useEffect } from 'react';
import useWebSocket from "../services/websocket";
import { useUserPreferences } from './UserPreferencesContext';


type WebSocketContextType = {
    connectionStatus: 'connected' | 'disconnected' | 'connecting';
    handleDisconnect: () => void;
};

const WebSocketContext = createContext<WebSocketContextType | undefined>(undefined);


type WebSocketProviderProps = {
    children: ReactNode;
};

export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({ children }) => {
    const {preferences} = useUserPreferences();

    const url = `${preferences?.ws_protocol}://${preferences?.api_domain}:${preferences?.port}/api/v1/channel/${preferences?.user_preferences.code}/ws`;
    const webSocketHook = useWebSocket(url);

    if (!preferences) {
        return <WebSocketContext.Provider value={{ 
            connectionStatus: "disconnected",
            handleDisconnect: webSocketHook.handleDisconnect 
        }}>
            {children}
        </WebSocketContext.Provider>
    }
    

    return <WebSocketContext.Provider value={{ 
        connectionStatus: webSocketHook.connectionStatus, 
        handleDisconnect: webSocketHook.handleDisconnect 
    }}>
        {children}
    </WebSocketContext.Provider>
};

export const useWebSocketContext = (): WebSocketContextType => {
    const context = useContext(WebSocketContext);
    if (!context) {
        throw new Error('useWebSocketContext debe ser usado dentro de un WebSocketProvider');
    }
    return context;
};