import ApiClient from "../../services/api_client";
import MapiClient from "../../services/mapi_client";
import { CancelTokenSource } from 'axios';
import { GpsDeviceType } from "../../types/device";

export const getMarkers = async (cancelToken?: CancelTokenSource): Promise<any> => {
    try {
        const response = await ApiClient.get({
            endpoint: `/markers/`,
            cancelToken: cancelToken?.token 
        });
        return response;

    } catch (error) {
        throw error;
    }
}

export const getCircularGeofences = async (cancelToken?: CancelTokenSource): Promise<any> => {
    try {
        const response = await ApiClient.get({
            endpoint: `/circular_geofences/`,
            cancelToken: cancelToken?.token 
        });
        return response;

    } catch (error) {
        throw error;
    }
}


export const getPolygonalGeofences = async (cancelToken?: CancelTokenSource): Promise<any> => {
    try {
        const response = await ApiClient.get({
            endpoint: `/geofences/`,
            cancelToken: cancelToken?.token 
        });
        return response;

    } catch (error) {
        throw error;
    }
}

export const getLinearGeofences = async (cancelToken?: CancelTokenSource): Promise<any> => {
    try {
        const response = await ApiClient.get({
            endpoint: `/georoutes/`,
            cancelToken: cancelToken?.token 
        });
        return response;

    } catch (error) {
        throw error;
    }
}

export const createLinearGeofence = async (data: any, cancelToken?: CancelTokenSource): Promise<any> => {
    try {
        const response = await ApiClient.post({
            endpoint: `/georoutes/create`,
            data,
            cancelToken: cancelToken?.token 
        });
        return response;

    } catch (error) {
        throw error;
    }
}

export const getDeviceQuickView = async (imei: string, startDateTiem: string, endDateTiem: string, cancelToken?: CancelTokenSource): Promise<any> => {
    try {
        const response = await ApiClient.get({
            endpoint: `/points/${imei}?startDateTime=${startDateTiem}&endDateTime=${endDateTiem}`,
            cancelToken: cancelToken?.token 
        });
        return response;

    } catch (error) {
        return error;
    }
}

export const createCircularGeofence = async (data: any, cancelToken?: CancelTokenSource): Promise<any> => {
    try {
        const response = await ApiClient.post({
            endpoint: `/circular_geofences`,
            data,
            cancelToken: cancelToken?.token 
        });
        return response;

    } catch (error) {
        throw error;
    }
}

export const createPolygonalGeofence = async (data: any, cancelToken?: CancelTokenSource): Promise<any> => {
    try {
        const response = await ApiClient.post({
            endpoint: `/geofences`,
            data,
            cancelToken: cancelToken?.token 
        });
        return response;

    } catch (error) {
        throw error;
    }
}

export const createMarker = async (data: any, cancelToken?: CancelTokenSource): Promise<any> => {
    try {
        const response = await ApiClient.post({
            endpoint: `/markers`,
            data,
            cancelToken: cancelToken?.token 
        });
        return response;

    } catch (error) {
        throw error;
    }
}