import React, { useCallback, useMemo, useState, useEffect } from "react";
import { getMarkerIcon } from "../map/MarkerIcon";
import type { Marker } from '@googlemaps/markerclusterer';
import { AdvancedMarker, InfoWindow } from '@vis.gl/react-google-maps';
import { Odometer, Speed, EngineStatus, Driver, LastConnection } from "../devices/DeviceHelpers";
import { engineStatuColors } from "../../constants/devices";
import { APP_URL } from "../../utils/constants";
import "./CustomMarker.scss";
import { getGeocoding } from "../../features/geocode/Geocoding";
import { useExternalLinks } from "../../contexts/ExternalLinksContext";
import momentVariant from "../../utils/momentConfig";
import { useMap } from "@vis.gl/react-google-maps";


const CustomMarker = ({ device, setMarkerRef }) => {
  
  const [isInfoWindowOpen, setIsInfoWindowOpen] = useState(false);
  const [address, setAddress] = useState<string | null>(null);
  const [reference, setReference] = useState<string | null>(null);
  const [loadingAddress, setLoadingAddress] = useState<boolean>(false);
  const [loadingReference, setLoadingReference] = useState<boolean>(false);
  const { car_images, engine_status } = useExternalLinks();
  const [parsedDevice, setParsedDevice] = useState(null);
  const map = useMap();

  const getEngineStatus = (device) => {
    const defaultEngineStatus = 'no_data';
    const engine_statuses = engine_status ? engine_status[device.engine_status] : defaultEngineStatus;
    const interval_inactivity = 24;
    const customMomentVariant = momentVariant();
  
    const lastReportDate = customMomentVariant(device.datetime);
    const currentDate = customMomentVariant();
  
    const hoursSinceLastReport = currentDate.diff(lastReportDate, 'hours');
    let inactive = false;
    if (hoursSinceLastReport >= interval_inactivity) {
      inactive = true;
    }
  
    if (inactive) return 'no_data';
    if (engine_statuses === 'off') return 'engine_off';
    if (engine_statuses === 'on' && device.speed <= 5) return 'ralenti';
    if (engine_statuses === 'on' && device.speed > 5) return 'engine_on';
  
    return defaultEngineStatus; // Fallback return value
  };

  useEffect(() => {
    // console.log(device.connection);
    
    const parsedDevice = {
      ...device,
      engine_status: getEngineStatus(device.connection),
      coordinates: {
          lat: device.connection.current_coordinates.coordinates[1] || 0,
          lng: device.connection.current_coordinates.coordinates[0] || 0,
      },
      orientation: device.connection.orientation,
      speed: device.connection.speed,
      last_connection: device.connection.datetime,
      odometer: device.connection.mileage,
    };
    setParsedDevice(parsedDevice);
  }, [device]);


  const ref = useCallback((marker: Marker | null) => setMarkerRef(marker, device.imei), [setMarkerRef, device.imei]);

  useEffect(() => {
    if (isInfoWindowOpen) {
      const getAddress = async () => {
        setLoadingAddress(true);
        try {
          const { coordinates } = parsedDevice;
          const address = await getGeocoding(coordinates.lat, coordinates.lng);
          setAddress(address.label);
          setReference(`${address.reference} a ${address.distance.toFixed(2)} km`);
        } catch (error) {
          setAddress("Error al obtener la dirección");
          setReference("Error al obtener la referencia");
        } finally {
          setLoadingAddress(false);
          setLoadingReference(false);
        }
      };

      getAddress();
    }
  }, [parsedDevice, isInfoWindowOpen]);

  const handleMarkerClick = useCallback(() => {
    if (!map || !parsedDevice) return;
    map.panTo(parsedDevice.coordinates);
    map.setZoom(16);
  }, [map, parsedDevice]);

  const markerStyle = useMemo<React.CSSProperties>(() => ({
    position: 'absolute',
    transform: 'translate(-50%, -50%) translateY(-35%)',
  }), []);

  const infoWindowStyle: React.CSSProperties = {
    width: "250px",
    fontSize: 10,
  };

  const iconContainerStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  const labelStyle = useMemo<React.CSSProperties>(() => ({
    position: 'absolute',
    top: '-20px',
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: 'black',
    color: 'white',
    padding: '2px 5px',
    borderRadius: '5px',
    boxShadow: '0 2px 6px rgba(0,0,0,0.3)',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '100px',
    zIndex: 1,
    fontSize: '12px',
  }), []);

  const imageStyle = useMemo<React.CSSProperties>(() => ({
    width: '30px',
    height: '30px',
  }), []);

  if(!parsedDevice) return null;

  return (
    <>
      <AdvancedMarker 
        position={{ lat: parsedDevice.coordinates.lat, lng: parsedDevice.coordinates.lng }} 
        ref={ref} 
        style={markerStyle}
        onClick={handleMarkerClick}
      >
        <div style={iconContainerStyle}>
          <div style={labelStyle}>{`${device.name}`}</div>
          <img 
            src={getMarkerIcon(device)} 
            style={{ 
              ...imageStyle, 
              transform: parsedDevice.engine_status === 'engine_on' ? `rotate(${parsedDevice.orientation}deg)` : 'none' 
            }} 
            alt="marker-icon" 
          />
        </div>
        <img src="/assets/images/marker.png" style={{ width: '15px', height: '15px', padding: "0 10px" }} alt="marker" />
      </AdvancedMarker>
    </>
  );
};

export default CustomMarker;
