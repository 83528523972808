import React, { useEffect, useState } from 'react';
import { UserPreferenceProvider } from "../../contexts/UserPreferencesContext";
import { DevicesProvider } from "../../contexts/GpsDeviceContext";
import { StatusCountsProvider } from "../../contexts/StatusCountContext";
import { FilterProvider } from "../../contexts/FilterDevicesContext";
import { WebSocketProvider } from "../../contexts/WebsocketContext";
import { TourProvider } from "../../contexts/TourContext";
import { NotificationProvider } from "../../contexts/NotificationContext";
import { APIProvider } from "@vis.gl/react-google-maps";
import AlertModal from '../alert_modal/AlertModal';
import { AlertProvider } from '../../contexts/AlertContext';
import IndividualTopBar from './IndividualTopBar';
import IndividualMap from './IndividualMap';
import { useDevices } from '../../contexts/GpsDeviceContext';





const Content = () => {
  const [imei, setImei] = useState(null)
  const [device, setDevice] = useState(null)
  const { devices, devicesLoading } = useDevices();

  useEffect(() => {
    // Obtener los parámetros de la URL directamente de window.location
    const params = new URLSearchParams(window.location.search);
    const imeiParam = params.get('imei');
    setImei(imeiParam);
  }, []);

  useEffect(() => {
    if (imei && !devicesLoading) {
      const device = devices.find(device => device.imei === imei);
      setDevice(device);
    }
  }
  , [imei, devicesLoading, devices]);


  return (
    <div className="app-container">
      <IndividualTopBar />
      {device && <IndividualMap device={device} />}
      <AlertModal />
    </div>
  );
}
const IndividualTracking: React.FC<any> = (props) => {

  const [preferences, setPreferences] = React.useState(props);

  useEffect(() => {
    setPreferences(props);
  }, [props]);
  
  return (
    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
      <AlertProvider>
        <UserPreferenceProvider initialPreferences={preferences}>
          <NotificationProvider>
            <DevicesProvider initialGroups={preferences.groups}>
              <StatusCountsProvider>
                <FilterProvider>
                  <WebSocketProvider>
                    <TourProvider>
                        <Content />
                    </TourProvider>
                  </WebSocketProvider>
                </FilterProvider>
              </StatusCountsProvider>
            </DevicesProvider>
          </NotificationProvider>
        </UserPreferenceProvider>
      </AlertProvider>
    </APIProvider>
  );
};

export default IndividualTracking;
