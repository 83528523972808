import React, { useState } from 'react';
import { GpsDeviceType } from '../../../types/device';
import { Button } from 'rsuite';
import './Status.scss';


import StatusModal from  './StatusModal';

const Status: React.FC<{ device: GpsDeviceType }> = ({ device }) => {
    
    const [open, setOpen] = useState(false);
    
    return (
        <div className="datasheet">
            <div className='flex-column mb-2'>
                <span className='title'>Estados</span>
            </div>
            <Button className='text-bold' appearance='primary' onClick={() => setOpen(true)}>Asignar estado</Button>
            <StatusModal
                open={open}
                device={device}
                onClose={() => setOpen(false)}
            />
        </div>
    );
};

export default Status;
