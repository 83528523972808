import React, { useEffect, useState } from 'react';
import { useUserPreferences } from "../../contexts/UserPreferencesContext";
import momentVariant from "../../utils/momentConfig";

import Icon from '../Icon';

export const Battery = ({ percentage, styleContainer }: { percentage: number, styleContainer?: any }) => {
    const [batteryColor, setBatteryColor] = useState('#bffc66');
    
    useEffect(() => {
        if (percentage >= 0 && percentage <= 33) {
            setBatteryColor('#FF4141');
        } else if (percentage >= 34 && percentage <= 66) {
            setBatteryColor('#FF9019');
        } else if (percentage >= 67 && percentage <= 100) {
            setBatteryColor('#80D602');
        } else {
            setBatteryColor('#000');
        }
    }, [percentage]);

    return (
        <div className="icon-card" style={styleContainer}>
            <div className="iconWrapper">
                <Icon icon={"battery"} color={batteryColor} size={16} />
            </div>
            <div className="divisor" />
            <div className="textWrapper">
                <span className="cardText">{percentage.toFixed(2)}%</span>
            </div>
        </div>
    );
}

export const Satellites = ({ satellites, styleContainer }: { satellites: number, styleContainer?: any }) => {
    const [satellitesColor, setSatellitesColor] = useState('#bffc66');<s></s>
    useEffect(() => {
        if (satellites <= 5) {
            setSatellitesColor('#FF4141');
        } else if (satellites <= 10) {
            setSatellitesColor('#fed099');
        } else if (satellites <= 15) {
            setSatellitesColor('#FF9019');
        } else if (satellites <= 20) {
            setSatellitesColor('#80D602');
        } else {
            setSatellitesColor('#80D602');
        }
    }, [satellites]);

    return (
        <div className="icon-card" style={styleContainer}>
            <div className="iconWrapper">
                <Icon icon={"signal"} color={satellitesColor} size={16} />
            </div>
            <div className="divisor" />
            <div className="textWrapper">
                <span className="cardText">{satellites}</span>
            </div>
        </div>
    );
}

export const LastConnection = ({ last_connection, styleContainer, show_title }: { last_connection: string, styleContainer?: any , show_title?: boolean }) => {
    const customMomentVariant = momentVariant();

    const [timeAgo, setTimeAgo] = useState<string>("");

    useEffect(() => {
        const updateTimeAgo = () => {
          setTimeAgo(customMomentVariant(last_connection).fromNow());
        };
    
        // Actualizamos la primera vez
        updateTimeAgo();
    
        // Opcional: si deseas que se actualice dinámicamente cada minuto
        const intervalId = setInterval(updateTimeAgo, 30000);
    
        // Limpiamos el intervalo al desmontar el componente
        return () => clearInterval(intervalId);
      }, [last_connection]);

    return (
        <div className="icon-card" style={styleContainer}>
            <div className="iconWrapper">
                <Icon icon={"clock-1"} size={16} color={"#3F3F3F"}/>
            </div>
            <div className="divisor" />
            {show_title && <span>Último reporte</span>}
            <div className="textWrapper">
                <span className="cardText">{timeAgo}</span>
            </div>
        </div>
    );
}

export const Speed = ({ speed, unit, styleContainer, show_title = true }: { speed: number, unit: string | undefined, styleContainer?: any, show_title?: boolean }) => {
    return (
        <div className="icon-card" style={styleContainer}>
            <div className="iconWrapper">
                <Icon icon={"velocity"} color={"#3F3F3F"} size={20} />
            </div>
            <div className="divisor" />
            {show_title && <span>Velocidad</span>}
            <div className="textWrapper">
                <span className="cardText">{Math.round(speed).toLocaleString("es-MX")} {unit}/h</span>
            </div>
        </div>
    );
}

export const EngineStatus = ({ engine_status, styleContainer, show_title = true }: { engine_status: string, styleContainer?: any, show_title?: boolean }) => {
    const {preferences} = useUserPreferences();

    return (
        <div className="icon-card" style={styleContainer}>
            <div className="iconWrapper">
                <Icon icon={"ignition"} color={"#3F3F3F"} size={20} />
            </div>
            <div className="divisor" />
            {show_title && <span>Ignición</span>}
            <div className="textWrapper">
                <span className="cardText">{preferences?.locales.engine[engine_status] || engine_status}</span>
            </div>
        </div>
    );
}

export const Odometer = ({ odometer, unit, styleContainer, show_title = true }: { odometer: number, unit: string | undefined, styleContainer?: any, show_title?: boolean }) => {

    let odometerFormated = parseFloat(odometer.toFixed(0));

    return (
        <div className="icon-card" style={styleContainer}>
            <div className="iconWrapper">
                <Icon icon={"odometer"} color={"#3F3F3F"} size={20} />
            </div>
            <div className="divisor" />
            {show_title && <span>Odometro</span>}
            <div className="textWrapper">
                <span className="cardText">{odometerFormated.toLocaleString("es-MX")} {unit}</span>
            </div>
        </div>
    );
}

export const Driver = ({ driver_name, styleContainer, show_title = true }: { driver_name: string, styleContainer?: any, show_title?: boolean }) => {
    return (
        <div className="icon-card" style={styleContainer}>
            <div className="iconWrapper">
                <Icon icon={"users"} color={"#3F3F3F"} size={20} />
            </div>
            <div className="divisor" />
            {show_title && <span>Conductor</span>}
            <div className="textWrapper">
                <span className="cardText">{driver_name}</span>
            </div>
        </div>
    );
}

