import React from 'react';
import { useAlert } from '../../contexts/AlertContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExclamation, faTimes, faInfo } from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'antd';
import { Button } from 'rsuite';

const AlertModal: React.FC = () => {
  const { alert, closeAlert } = useAlert();

  if (!alert.isOpen) return null;

  const getIcon = () => {
    switch (alert.type) {
      case 'success':
        return <FontAwesomeIcon icon={faCheck} />;
      case 'warning':
        return <FontAwesomeIcon icon={faExclamation} />;
      case 'error':
        return <FontAwesomeIcon icon={faTimes} />;
      default:
        return alert.icon || <FontAwesomeIcon icon={faInfo} />;
    }
  };

  const getTitle = () => {
    switch (alert.type) {
      case 'success':
        return 'Acción realizada con éxito';
      case 'warning':
        return 'Ocurrió un error';
      case 'error':
        return 'Solictud rechazada';
      default:
        return 'Información';
    }
  }

  return (
    <Modal
      title={null}
      closable={false}
      open={alert.isOpen}
      onOk={closeAlert}
      onCancel={closeAlert}
      footer={null}
    >
      <div className="alert-content">
        <div className={`alert-circle ${alert.type}`}>
          <span className="alert-icon">{getIcon()}</span>
        </div>
        <div className='alert-message-container'>
          <h3 className="alert-message-title">{getTitle()}</h3>
          <p className="alert-message">{alert.message}</p>
        </div>
        <div className='horizontal-divider'></div>
        <div className='alert-buttons'>
          <Button appearance='primary' onClick={closeAlert}>Continuar</Button>
        </div>
      </div>
    </Modal>
  );
};

export default AlertModal;
