import { useState, useEffect } from 'react';
import { getTrailers } from '../features/trailers/Trailer';

let cachedTrailers = null;

export const useTrailers = () => {
    const [trailers, setTrailers] = useState(cachedTrailers || []);
    const [selectedTrailer, setSelectedTrailer] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [newTrailer, setNewTrailer] = useState(null);

    const fetchTrailers = async () => {
        const res = await getTrailers();
        if (res.success) {
            cachedTrailers = res.trailers;
            setTrailers(res.trailers);
            // Si el conductor seleccionado no está en la nueva lista, deseleccionarlo
            if (!res.trailers.some(driver => driver.id === selectedTrailer)) {
                setSelectedTrailer(null);
            }
        }
    };

    useEffect(() => {
        if (!cachedTrailers) {
            fetchTrailers();
        }
    }, []);

    const refreshTrailers = () => {
        cachedTrailers = null;
        fetchTrailers();
    };

    const handleTrailerSelection = (driverId) => {
        setSelectedTrailer(driverId);
    };

    const handleTrailerCreation = (newTrailer) => {
        setTrailers(prevTrailers => [...prevTrailers, newTrailer]);
        setNewTrailer(newTrailer);
        handleTrailerSelection(newTrailer.id);
    };

    return {
        trailers,
        selectedTrailer,
        submitting,
        newTrailer,
        setSubmitting,
        handleTrailerSelection,
        refreshTrailers,
        handleTrailerCreation,
    };
};
