import React, { useState, useEffect } from "react";
import { Select, Modal, Form } from "antd";
import { Button, Tabs } from 'rsuite';
import Icon from "../../Icon";
import DriverForm from "./DriverForm";
import { useDrivers } from "../../../hooks/useDrivers";
import { setDriverToVehicle } from '../../../features/devices/Device';
import { GpsDeviceType } from "../../../types/device";
import { useAlert } from "../../../contexts/AlertContext";

const { Option } = Select;

type DriverModalProps = {
    open: boolean;
    onClose: () => void;
    device: GpsDeviceType;
};

const DriverModal: React.FC<DriverModalProps> = ({
    open,
    onClose,  
    device,
}) => {

    const [activeTab, setActiveTab] = useState("1");
    const [form] = Form.useForm();
    const { drivers, handleDriverSelection, selectedDriver, submitting, setSubmitting, handleDriverCreation, newDriver } = useDrivers();
    const { showAlert } = useAlert();

    useEffect(() => {
        if (device?.driver?.id != 0) {
            handleDriverSelection(device.driver.id);
        }
    }, [device, drivers]);

    useEffect(() => {

        if (newDriver) {
            handleSubmitSelectedDriver();
        }

    }, [newDriver])

    const handleSubmitSelectedDriver = async () => {
        setSubmitting(true);
        try {
            const updateDevice = {
                imei: device.imei,
                driver_id: selectedDriver || newDriver?.id,
            };
            const res = await setDriverToVehicle(device.imei, updateDevice);
            if (res.success) {
                onClose();
                showAlert("success", "Conductor asignado correctamente");
            }else {
                showAlert("error", "Error al asignar conductor");
            }
        } catch (error) {
            showAlert("error", "Error al asignar conductor, intente nuevamente" + error);
        }finally {
            setSubmitting(false);
        }
    };

    const handleSave = () => {
        if (activeTab === "2") {
            form.submit(); // Enviar el formulario de creación de conductor
        } else {
            handleSubmitSelectedDriver(); // Guardar el conductor seleccionado
        }
    };

    const handleDriverCreated = (newDriver) => {
        handleDriverCreation(newDriver); // Añadir y seleccionar el nuevo conductor
    };

    return (
        <Modal
            title={
                <div className="flex-row align-center">
                    <div className="modal-icon-title">
                        <Icon icon="users" size="18" />
                    </div>
                    <span className="modal-title">Asignar conductor</span>
                </div>
            }
            width={721}
            centered
            maskClosable={false}
            open={open}
            onCancel={() => {
                onClose();
                form.resetFields(); // Restablecer el formulario y la pestaña activa
                setActiveTab("1");
            }}
            afterClose={() => {
                form.resetFields();
                setActiveTab("1");
            }}
            footer={null}
        >
            <Tabs activeKey={activeTab} onSelect={(key) => setActiveTab(String(key))} className="driver-tabs" appearance="subtle">
                <Tabs.Tab eventKey="1" title="Elegir conductor">
                    <div className="modal-body">
                        {drivers.length > 0 ? (
                            <div className="select-driver">
                                <label>Selecciona un conductor de la lista</label>
                                <Select
                                    showSearch
                                    style={{ width: 400 }}
                                    placeholder="Selecciona un conductor de la lista"
                                    optionLabelProp="label"
                                    filterOption={(input, option) => {
                                        const label = option?.label;
                                        return typeof label === "string" && label.toLowerCase().includes(input.toLowerCase());
                                    }}
                                    value={selectedDriver}
                                    onChange={handleDriverSelection}
                                    allowClear
                                >
                                    <Option value="" label="No asignar conductor" className="no-driver">
                                        <span className="flex-row align-center justify-between">
                                            No asignar conductor <Icon icon="exit" color="#FF0000" size={12} />
                                        </span>
                                    </Option>
                                    {drivers.map((driver) => (
                                        <Option key={driver.id} value={driver.id} label={driver.name}>
                                            {driver.name}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        ) : (
                            <span style={{ textAlign: "center", fontWeight: "bold" }}>Cargando conductores...</span>
                        )}
                    </div>
                    <div className="modal-footer">
                        <Button onClick={handleSave} className="save" appearance="primary" loading={submitting}>
                            Guardar
                        </Button>
                    </div>
                </Tabs.Tab>
                <Tabs.Tab eventKey="2" title="Crear conductor">
                    <div className="modal-body">
                        <DriverForm 
                            form={form} 
                            onSubmiting={setSubmitting} // Pasar el estado de carga al formulario
                            onDriverCreated={handleDriverCreated} // Pasar la función de callback para manejar la creación de un nuevo conductor
                        />
                    </div>
                    <div className="modal-footer">
                        <Button onClick={handleSave} className="save" appearance="primary" loading={submitting}>
                            Guardar
                        </Button>
                    </div>
                </Tabs.Tab>
            </Tabs>
        </Modal>
    );
};

export default DriverModal;
