import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { type Marker, MarkerClusterer, MarkerUtils } from '@googlemaps/markerclusterer';
import { useMap } from '@vis.gl/react-google-maps';
import { useDevices } from '../../contexts/GpsDeviceContext';
import { useFilters } from '../../contexts/FilterDevicesContext';
import CustomMarker from './CustomMarker';
import { useUserPreferences } from '../../contexts/UserPreferencesContext';

const CustomMarkerMemo = React.memo(CustomMarker);

const ClusterComponent = () => {
  const { activeDevice } = useDevices();
  const { devicesFiltered } = useFilters();
  const [markers, setMarkers] = useState<{ [key: string]: Marker }>({});
  const map = useMap();
  const sidebarWidth = 440; // Ancho del sidebar en píxeles
  const {preferences, loading_preferences} = useUserPreferences();

  const visibleDevices = useMemo(() => devicesFiltered.filter(device => device.visible), [devicesFiltered]);

  const clusterer = useMemo(() => {
    console.log({cluster: preferences.user_preferences.cluster})
    if (!map || !preferences.user_preferences.cluster) return null;
    return new MarkerClusterer({
      map,
      onClusterClick: (event, cluster) => {
        const bounds = cluster.bounds;
        if (bounds) {
          const padding = { top: 0, right: 0, bottom: 0, left: sidebarWidth };
          map.fitBounds(bounds, padding);
        }
      }
    });
    
  }, [map, sidebarWidth, preferences]);

  useEffect(() => {
    if (preferences.user_preferences.cluster && clusterer) {
      clusterer.clearMarkers();
      clusterer.addMarkers(Object.values(markers));
    } else {
      clusterer?.clearMarkers();
    }

    if (!activeDevice) {
      adjustMapBounds(Object.values(markers));
    }
  }, [clusterer, markers, activeDevice, preferences]);

  const adjustMapBounds = (markersArray) => {
    if (!map || markersArray.length === 0) return;

    const bounds = new window.google.maps.LatLngBounds();
    markersArray.forEach(marker => {
      const position = MarkerUtils.getPosition(marker);
      //validate if the position is valid
      if (position.lat() != 0 && position.lng() != 0) {
        bounds.extend(position);
      } else {
        //set position to lat 0 and lng 0
        console.log('Invalid position');
      }
    });

    if (!bounds.isEmpty()) {
      const padding = { top: 0, right: 0, bottom: 0, left: sidebarWidth };
      map.fitBounds(bounds, padding);
    }
  };

  const setMarkerRef = useCallback((marker: Marker | null, id: number) => {
    setMarkers(prevMarkers => {
      if (prevMarkers[id] === marker) {
        return prevMarkers; // No hacer nada si no hay cambios
      }
      const newMarkers = { ...prevMarkers };
      if (marker) {
        newMarkers[id] = marker;
      } else {
        delete newMarkers[id];
      }
      return newMarkers;
    });
  }, []);

  return (
    <>
      {visibleDevices.map(device => (
        <CustomMarkerMemo key={device.id} device={device} setMarkerRef={setMarkerRef} />
      ))}
    </>
  );
};

export default ClusterComponent;
