enum StorageKeys {
  USER_INFO = 'USER_INFO',
  PARTNER_INFO = 'PARTNER_INFO',
  PREFERENCES = 'PREFERENCES',
  UUID = 'UUID',
}

const setItem = async (key: StorageKeys, data: any) => {
  try {
      // console.log('Saving to AsyncStorage:', key, data);
      await localStorage.setItem(key, JSON.stringify(data));
      return true;
  } catch (error) {
      console.error("Error saving to AsyncStorage:", error);
      return null;
  }
};

const getItem = async (key: StorageKeys): Promise<any | null> => {
  try {
    const data = await localStorage.getItem(key);
    // console.log('Reading from AsyncStorage:', key, data);
      return data ? JSON.parse(data) : null;
  } catch (error) {
      console.error("Error reading from AsyncStorage:", error);
      return null;
  }
};

const removeItem = async (key: StorageKeys) => {
  try {
      // console.log('Removing from AsyncStorage:', key);
      await localStorage.removeItem(key);

      return true;

  } catch (error) {
      console.error("Error removing from AsyncStorage:", error);
      return false;
  }
};



export const storage = {
  setUserInfo: (data: any) => setItem(StorageKeys.USER_INFO, data),
  getUserInfo: () => getItem(StorageKeys.USER_INFO),
  removeUserInfo: () => removeItem(StorageKeys.USER_INFO),

  setPartnerInfo: (data: any) => setItem(StorageKeys.PARTNER_INFO, data),
  getPartnerInfo: () => getItem(StorageKeys.PARTNER_INFO),
  removePartnerInfo: () => removeItem(StorageKeys.PARTNER_INFO),

  getUUID: async (): Promise<string | null> => {
    const userInfo = await getItem(StorageKeys.USER_INFO);
    return userInfo?.user_preferences?.uuid || null;
  },
  removeUUID: () => removeItem(StorageKeys.UUID),

  getUserToken: async (): Promise<string | null> => {
    const userInfo = await getItem(StorageKeys.USER_INFO);
    return userInfo?.user_preferences?.status || null;
  },
  clear: async () => {
    await removeItem(StorageKeys.USER_INFO);
    await removeItem(StorageKeys.PARTNER_INFO);
    await removeItem(StorageKeys.PREFERENCES);
    await removeItem(StorageKeys.UUID);

    return Promise.all([
      removeItem(StorageKeys.USER_INFO),
      removeItem(StorageKeys.PARTNER_INFO),
      removeItem(StorageKeys.PREFERENCES),
      removeItem(StorageKeys.UUID),
    ]);

  }
};