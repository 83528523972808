import React from "react";
import AvatarIcon from '@rsuite/icons/legacy/Avatar';
import { Select, Form, Upload, Input, DatePicker } from 'antd';
import { newDriver } from "../../../features/drivers/Driver";
import { useUserPreferences } from "../../../contexts/UserPreferencesContext";
import { useToaster, Message } from 'rsuite';

const message = (msg) => (
    <Message showIcon type={"error"} closable>
        <strong>{"error"}!</strong> {msg}
    </Message>
);

const DriverForm: React.FC<{ form: any, onDriverCreated: (newDriver: any) => void, onSubmiting: (submiting: boolean) => void }> = ({ form, onDriverCreated, onSubmiting }) => {
    const { preferences } = useUserPreferences();
    const users = preferences?.users || [];
    const licenses = preferences?.licenses || {};

    const licensesData = Object.keys(licenses).map(key => ({ value: key, label: licenses[key] }));
    const toaster = useToaster();

    const onFinish = async (fieldsValue) => {
        onSubmiting(true);
        // console.log('Received values of form: ', fieldsValue);
    
        // Crear un objeto FormData para enviar los datos del formulario
        const formData = new FormData();
    
        // Añadir los campos de texto y fecha al FormData
        formData.append('driver[name]', fieldsValue['name']);
        formData.append('driver[license]', fieldsValue['license']);
        formData.append('driver[expiration]', fieldsValue['expiration'] && fieldsValue['expiration'].format('YYYY-MM-DD'));
        formData.append('driver[expedition]', fieldsValue['expedition'] && fieldsValue['expedition'].format('YYYY-MM-DD'));
        formData.append('driver[user_id]', fieldsValue['user_id']);
    
        // Añadir la imagen al FormData si existe
        if (fieldsValue.profile_picture && fieldsValue.profile_picture.length > 0) {
            formData.append('driver[profile_picture]', fieldsValue.profile_picture[0].originFileObj);
        }
    
        const res = await newDriver(formData);
        if (res.success) {
            onDriverCreated(res.driver); // Llamar a la callback pasando el conductor creado
            onSubmiting(false);
        } else {
            if (res.errors) {
                for (let key in res.errors) {
                    toaster.push(message(res.errors[key]), { duration: 5000 });
                }
            }
            onSubmiting(false);
        }
    };

    const normFile = (e: any) => {
        // console.log('Upload event:', e);
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    return (
        <div className='driver-form'>
            <div className='driver-form-inputs'>
                <Form
                    form={form}
                    name="driver-form"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    style={{ maxWidth: 600 }}
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <Form.Item label="Foto de perfil" valuePropName="fileList" getValueFromEvent={normFile} name="profile_picture">
                        <Upload action="#" listType="picture-card" beforeUpload={() => false} maxCount={1}>
                            <button style={{ border: 0, background: 'none' }} type="button">
                                <AvatarIcon style={{ fontSize: 28, color: '#999999' }} />
                                <div style={{ marginTop: 8, fontWeight:"bold" }}>Subir</div>
                            </button>
                        </Upload>
                    </Form.Item>
                    <Form.Item
                        label="Nombre"
                        name="name"
                        rules={[
                            { 
                                required: true, message: 'Por favor ingresa el nombre del conductor', 
                            },
                            {
                                min: 5, message: 'El nombre debe tener al menos 5 caracteres',
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="license"
                        label="Tipo de Licencia"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor selecciona un tipo de licencia',
                            },
                        ]}
                    >
                        <Select placeholder="Por favor selecciona un tipo de licencia">
                            {licensesData.map(({ value, label }) => (
                                <Select.Option key={value} value={value}>
                                    {label}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="expiration"
                        label="Fecha de expiración"
                        rules={
                            [
                                {
                                    required: true,
                                    message: 'Por favor selecciona una fecha de expiración',
                                },
                            ]
                        }

                    >
                        <DatePicker format={'DD/MM/YYYY'} />
                    </Form.Item>
                    <Form.Item
                        name="expedition"
                        label="Fecha de expedición"
                        rules={
                            [
                                {
                                    required: true,
                                    message: 'Por favor selecciona una fecha de expedición',
                                },
                            ]
                        }
                    >
                        <DatePicker format={'DD/MM/YYYY'} />
                    </Form.Item>
                    <Form.Item
                        name="user_id"
                        label="Usuario"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor selecciona un usuario',
                            },
                        ]}
                    >
                        <Select placeholder="Por favor selecciona un usuario">
                            {users.map(({ id, name }) => (
                                <Select.Option key={id} value={id}>
                                    {name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default DriverForm;
