import ApiClient from "../../services/api_client";
import { CancelTokenSource } from 'axios';



export const updatePreferences = async (data: any, cancelToken?: CancelTokenSource): Promise<any> => {
    try {
        const response = await ApiClient.put({
            endpoint: `/users/settings/`,
            data,
            cancelToken: cancelToken?.token 
        });
        return response;

    } catch (error) {
        throw error;
    }
}
