import ApiClient from "../../services/api_client";
import { CancelTokenSource } from 'axios';



export const getRoadEvents = async (cancelToken?: CancelTokenSource): Promise<any> => {
    try {
        const response = await ApiClient.get({
            endpoint: `/road_events/`,
            cancelToken: cancelToken?.token 
        });
        return response;

    } catch (error) {
        throw error;
    }
}

export const newEvent = async (data: any, imei:string, cancelToken?: CancelTokenSource): Promise<any> => {
    try {
        const response = await ApiClient.post({
            endpoint: `/devices/new_event/${imei}`,
            data,
            cancelToken: cancelToken?.token 
        });
        return response;

    } catch (error) {
        throw error;
    }
}

