import React from 'react';
import { GpsDeviceType } from '../../../types/device';
import LicenseInfo from './LicenseInfo';
import momentVariant from '../../../utils/momentConfig';

import { useUserPreferences } from '../../../contexts/UserPreferencesContext';


const DriverLicenseInfo: React.FC<{ device: GpsDeviceType }> = ({ device }) => {
    const { preferences } = useUserPreferences();
    const licenses = preferences?.licenses || [];
    const customMomentVariant = momentVariant();
    
    return(
        <div className='driver-info'>
            <div className='driver-license-info two-columns'>
                <LicenseInfo label="Tipo de licencia:" value={licenses[device.driver.license]} />
                <LicenseInfo label="Fecha de expedición:" value={customMomentVariant.utc(device.driver.expedition).format('DD/MM/YYYY')} />
                <LicenseInfo label="Fecha de expiración:" value={customMomentVariant.utc(device.driver.expiration).format('DD/MM/YYYY')} />
            </div>
        </div>
    )
}

export default DriverLicenseInfo;