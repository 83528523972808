import React, { useState, useCallback, useEffect } from "react";
import { Map } from "@vis.gl/react-google-maps";
import CustomMarker from "../map/CustomMarker"; 
import { type Marker, MarkerUtils } from '@googlemaps/markerclusterer';
import { GpsDeviceType } from "../../types/device";
import DeviceInformation from "../devices/generalinfo/DeviceInformation";
import "./IndividualMap.scss";
import { useMap } from '@vis.gl/react-google-maps';


const CustomMarkerMemo = React.memo(CustomMarker);
const MapComponent = ({ device }: { device: GpsDeviceType }) => {

    const [markers, setMarkers] = useState<{ [key: number]: Marker | null }>({});
    const map = useMap();
    const sidebarWidth = 250; // Ancho del sidebar en píxeles

    // Función para establecer la referencia de los marcadores
    const setMarkerRef = useCallback((marker: Marker | null, id: number) => {
        setMarkers(prevMarkers => {
          if (prevMarkers[id] === marker) {
            return prevMarkers; // No hacer nada si no hay cambios
          }
          const newMarkers = { ...prevMarkers };
          if (marker) {
            newMarkers[id] = marker;
          } else {
            delete newMarkers[id];
          }
          return newMarkers;
        });
    }, []);

    // Efecto para centrar el mapa cuando los marcadores o el dispositivo cambian
    useEffect(() => {
        if (markers && map && device) {
            const bounds = new window.google.maps.LatLngBounds();
            Object.values(markers).forEach(marker => {
                const position = MarkerUtils.getPosition(marker);
                if (position.lat() !== 0 && position.lng() !== 0) {
                    bounds.extend(position);
                } else {
                    console.log('Posición no válida');
                }
            });

            if (Object.keys(markers).length === 1) {
                // Si solo hay un marcador, centrar en el dispositivo y ajustar el mapa
                const position = MarkerUtils.getPosition(Object.values(markers)[0]);
                map.setCenter(position);
                map.setZoom(15);  // Ajusta el nivel de zoom según lo que necesites

                // Ajustar el mapa para compensar el ancho del sidebar
                map.panBy(-sidebarWidth / 2, 0); // Desplazar el mapa hacia la derecha
            } else if (Object.keys(markers).length > 1 && !bounds.isEmpty()) {
                // Si hay más de un marcador, usar fitBounds para ajustar los límites
                const padding = { top: 0, right: 0, bottom: 0, left: sidebarWidth };
                map.fitBounds(bounds, padding);
            }
        }
    }, [markers, map, device, device.coordinates]);

    return (
        <div className="map-container">
            <Map
                mapId={"map"}
                defaultZoom={15}
                defaultCenter={{ lat: 8.7768819, lng: -100.0374135 }}
                gestureHandling={"greedy"}
                maxZoom={21}
                minZoom={2}
                disableDefaultUI={true}
            >
                <CustomMarkerMemo key={device.id} device={device} setMarkerRef={setMarkerRef} />
            </Map>

            <div className="device-container-individual">
                <DeviceInformation device={device} />
            </div>
        </div>
    );
};

export default MapComponent;
