import React, { useState } from "react";
import { Select, Modal, Form, Input } from "antd";
import Icon from "../../Icon";
import { useRoadEvents } from "../../../hooks/useRoadEvents";
import { GpsDeviceType } from "../../../types/device";
import { newEvent } from "../../../features/road_events/RoadEvent";
import { useToaster, Button } from 'rsuite';
const { TextArea } = Input;
import { useAlert } from "../../../contexts/AlertContext";
import { message } from "../../form/MessageError";

const { Option } = Select;

type RoadEventModalProps = {
    open: boolean;
    onClose: () => void;
    device: GpsDeviceType;
};



const RoadEventModal: React.FC<RoadEventModalProps> = ({
    open,
    onClose,  
    device,
}) => {
    const [form] = Form.useForm();
    const [other, setOther] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const toaster = useToaster();
    const {showAlert} = useAlert();

    const { road_events, refreshRoadEvents } = useRoadEvents();

    const onFinish = async (values) => {

        setSubmitting(true);
        let event = values.event;
        if (values.event === 'new') {
            event = values.other;
        }

        try{
            const updateDevice = {
                name: event,
                connection: device.connection_id,
            };

            const res = await newEvent({event:updateDevice}, device.imei);
            if (res.success) {
                showAlert('success', "Evento Guardado");
                onClose();
            }else{
                if (res.errors) {
                    for (let key in res.errors) {
                        toaster.push(message(res.errors[key]), { duration: 5000 });
                    }
                }
            }
        } catch (error) {
            showAlert('error', "Error al guardar el evento, error: "+error);
        } finally {
            setSubmitting(false);
        }
    }

    const handleChange = (value) => {
        if (value === 'new') {
            setOther(true);
        }
    }

    return (
        <Modal
            title={
                <div className="flex-row align-center">
                    <div className="modal-icon-title">
                        <Icon icon="warning" size="18" />
                    </div>
                    <span className="modal-title">Crear evento</span>
                </div>
            }
            width={721}
            centered
            maskClosable={false}
            open={open}
            onCancel={() => {
                form.resetFields();
                refreshRoadEvents();
                other && setOther(false);
                onClose()
            }}
            afterClose={() => {
                form.resetFields();
                refreshRoadEvents();
                other && setOther(false);
                onClose()
            }}
            footer={null}
        >
            <div className="modal-body">
                <div className="select-driver">
                    <Form
                        form={form}
                        name="driver-form"
                        layout="vertical"
                        style={{ maxWidth: 600 }}
                        onFinish={onFinish}
                        autoComplete="off"
                    >
                        <Form.Item
                            name="event"
                            label="Selecciona la eventualidad"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor selecciona un evento',
                                },
                            ]}
                        >
                            <Select placeholder="Por favor selecciona una eventualidad" onChange={handleChange}>
                                {road_events.map((event) => (
                                    <Option key={event.id} value={event.name}>
                                        {event.name}
                                    </Option>
                                ))}
                                <Select.Option key="new" value="new" className="other">
                                    Otro
                                </Select.Option>
                            </Select>
                        </Form.Item>
                        {
                            other && (
                                <Form.Item
                                    name="other"
                                    label="Describe la eventualidad"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor ingresa el nombre del evento',
                                        },
                                    ]}
                                >
                                    <TextArea 
                                        rows={4} 
                                        showCount
                                        maxLength={100}
                                    />
                                </Form.Item>
                            )
                        }
                    </Form>
                </div>
            </div>
            <div className="modal-footer">
                <Button onClick={() => {
                    form.submit();
                }} className="text-bold" appearance="primary" loading={submitting}>
                    Crear evento
                </Button>
            </div>
        </Modal>
    );
};

export default RoadEventModal;
