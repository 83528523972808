import React from 'react';

import Groups from "./Groups";
import { FiltersContainer } from "../filter_buttons/FiltersContainer";
import { Drawer } from 'rsuite';
import { useUserPreferences } from '../../contexts/UserPreferencesContext';
import { useAlert } from '../../contexts/AlertContext';
import { Button } from 'antd';

const DevicesList = () => {  
  const { show_tracking } = useUserPreferences();

  return (
        <Drawer size={441} placement={"left"} open={show_tracking} backdrop={false} closeButton={false} className="devices-container">
          <Drawer.Body className='devices-body'>
            <FiltersContainer />        
            <Groups />
          </Drawer.Body>
        </Drawer>
  );
}


export default DevicesList;