

import React from 'react';

import { GroupContainer } from './GroupContainer';

import { useGroups } from '../../hooks/useGroups';
import { Placeholder } from 'rsuite';

const Groups = () => {
  const { groups, groupsLoading } = useGroups();
  if (groupsLoading) {
    return (
        <div className="group-container">
            <Placeholder.Graph active />
            <Placeholder.Graph active />
            <Placeholder.Graph active />
        </div>
    );
  }

  if(groups.length == 0 && !groupsLoading) {
    return (
        <div className="group-container">
            <span style={{fontSize: 20, color: "#bbb"}}>No se encontraron unidades</span>
        </div>
    );
  }


  return (
    <div className="groups-container">
        {groups.sort((a, b) => a.group_name.localeCompare(b.group_name)).map(group => (
            <GroupContainer
              key={group.key}
              group={group}
            />
        ))}
    </div>
  );
};


export default Groups;