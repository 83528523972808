import ApiClient from "../../services/api_client";
import { CancelTokenSource } from 'axios';



export const getStatus = async (cancelToken?: CancelTokenSource): Promise<any> => {
    try {
        const response = await ApiClient.get({
            endpoint: `/device_statuses/`,
            cancelToken: cancelToken?.token 
        });
        return response;

    } catch (error) {
        throw error;
    }
}
