import React, { useEffect } from 'react';
import { useSpecialMessages } from '../../contexts/SpecialMessagesContext';
import { Modal, Carousel } from 'antd';
import { APP_URL } from '../../utils/constants';

const SpecialMessagesModal: React.FC = () => {
    const { specialMessages } = useSpecialMessages();
    const [showModal, setShowModal] = React.useState(false);

    useEffect(() => {
        if (specialMessages.length > 0) {
            setShowModal(true);
        }
    }, [specialMessages]);

    const closeModal = () => {
        setShowModal(false);
    };

    const contentModal = () => {
        if (specialMessages.length > 1) {
            return (
                <Carousel
                    autoplay
                    autoplaySpeed={5000}
                    dots={false}
                    arrows
                    adaptiveHeight
                >
                    {specialMessages.map((message) => (
                        <div key={message.id}>
                            {message.image_url?.url ? (
                                <div className='announcement-content'>
                                    <h3>{message.title}</h3>
                                    <img
                                        src={`${APP_URL}${message.image_url.url}`}
                                        alt={message.title}
                                        style={{ width: '100%', height: 'auto' }}
                                    />
                                </div>
                            ) : (
                                <div className='announcement-content'>
                                    <h3>{message.title}</h3>
                                    <p>{message.message}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </Carousel>
            );
        } else if (specialMessages.length === 1) {
            const message = specialMessages[0];
            return (
                <>
                    {message.image_url?.url ? (
                        <div className='announcement-content p-0'>
                            <h3>{message.title}</h3>
                            <img
                                src={`${APP_URL}${message.image_url.url}`}
                                alt={message.title}
                                style={{ width: '100%', height: 'auto' }}
                            />
                        </div>
                    ) : (
                        <div className='announcement-content p-0'>
                            <h3>{message.title}</h3>
                            <p>{message.message}</p>
                        </div>
                    )}
                </>
            );
        }
    };

    return (
        <Modal
            title={null}
            closable={true}
            open={showModal}
            onOk={closeModal}
            onCancel={closeModal}
            footer={null}
            width={800}
            centered
        >
            <div className='modal-body announcement'>
                {specialMessages.length > 0 && contentModal()}
            </div>
        </Modal>
    );
};

export default SpecialMessagesModal;
