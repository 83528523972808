import React from "react";
import { GpsDeviceType } from "../../../types/device";
import { Button, Avatar, Tabs } from 'rsuite';
import DriverLicenseInfo from "./DriverLicenseInfo";
import { APP_URL } from "../../../utils/constants";

const DriverInfo: React.FC<{ device: GpsDeviceType, onEdit: () => void }> = ({ device, onEdit }) => (
    <>
        <div className='flex-column mb-2'>
            <span className='title'>Conductor</span>
        </div>
        <div className='driver-info flex-row justify-between align-center'>
            <div className='driver-container flex-row justify-between align-center'>
                <figure className='driver-image m-0'>
                    <Avatar src={`${APP_URL}${device.driver.profile_picture}`} size='lg' />
                </figure>
                <span className='driver-name'>{device.driver.name}</span>
            </div>
            <div className='driver-info-action'>
                <Button appearance="ghost" className="text-bold" onClick={onEdit}>Editar conductor</Button>
            </div>
        </div>
        <DriverLicenseInfo device={device} />
    </>
);

export default DriverInfo;