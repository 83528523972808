import React, { forwardRef, useRef } from "react";
import { GpsDeviceType } from "../../../types/device";
import { Button } from "rsuite";
import "./Properties.scss";
import html2pdf from "html2pdf.js";
import momentVariant from "../../../utils/momentConfig";

// Componente que contiene la información a exportar, oculto por defecto
const PrintableContent = forwardRef<HTMLDivElement, { device: GpsDeviceType }>(({ device }, ref) => {
    const customMomentVariant = momentVariant();
    return (
        <div className="printable-content hidden" ref={ref}>
            <div className="header">
                <img src="/assets/images/logo.png" alt="Logo" />
            </div>
            <div className="pdf-divider"></div>
            <div className="device-general-info flex-row justify-between">
                <div className="flex-column" style={{flex:1}}>
                    <span className="text-bold">Unidad</span>
                    <span>{device?.name}</span>
                </div>
                <div className="flex-column" style={{flex:.5}}>
                    <div className="flex-row justify-between">
                        <span className="text-bold">Marca</span>
                        <span>{device?.vehicle_brand || "Sin Registro"}</span>
                    </div>
                    <div className="flex-row justify-between">
                        <span className="text-bold">Modelo</span>
                        <span>{device?.vehicle_model || "Sin Registro"}</span>
                    </div>
                    <div className="flex-row justify-between">
                        <span className="text-bold">No. de serie</span>
                        <span>{device?.vehicle_serial_number || "Sin Registro"}</span>
                    </div>
                    <div className="flex-row justify-between">
                        <span className="text-bold">Placas</span>
                        <span>{device?.plates || "Sin Registro"}</span>
                    </div>
                </div>
            </div>
            <div className="pdf-divider"></div>
            <div className="insurance-info">
                <span className="title text-bold">Póliza de seguro</span>
                <table className="table-bordered mb-1">
                    <tbody>
                        <tr>
                            <td>Titular</td>
                            <td>{device?.insurance_policy?.account_holder || "Sin Registro"}</td>
                        </tr>
                        <tr>
                            <td>No. de póliza</td>
                            <td>{device?.insurance_policy?.policy_number || "Sin Registro"}</td>
                        </tr>
                        <tr>
                            <td>Expedición</td>
                            <td>{customMomentVariant(device?.insurance_policy?.expedition_date).format('DD/MM/YYYY')}</td>
                        </tr>
                        <tr>
                            <td>Expiración</td>
                            <td>{customMomentVariant(device?.insurance_policy?.expiration_date).format('DD/MM/YYYY')}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="driver-info">
                <span className="title text-bold">Datos del conductor</span>
                <table className="table-bordered mb-1">
                    <tbody>
                        <tr>
                            <td>Nombre</td>
                            <td>{device?.driver?.name || "Sin Registro"}</td>
                        </tr>
                        <tr>
                            <td>Licencia</td>
                            <td>{device?.driver?.license || "Sin Registro"}</td>
                        </tr>
                        <tr>
                            <td>Expedición</td>
                            <td>{customMomentVariant(device?.driver?.expedition).format('DD/MM/YYYY')}</td>
                        </tr>
                        <tr>
                            <td>Vencimiento</td>
                            <td>{customMomentVariant(device?.driver?.expiration).format('DD/MM/YYYY')}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="trailer-info">
                <span className="title text-bold">Datos del remolque</span>
                <table className="table-bordered mb-1">
                    <tbody>
                        <tr>
                            <td>Nombre</td>
                            <td>{device?.trailer?.name || "Sin Registro"}</td>
                        </tr>
                        <tr>
                            <td>Código</td>
                            <td>{device?.trailer?.code || "Sin Registro"}</td>
                        </tr>
                        <tr>
                            <td>Exclusivo</td>
                            <td>{device?.trailer?.exclusive ? "Sí" : "No"}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
});


const Properties: React.FC<{ device: GpsDeviceType }> = ({ device }) => {
    const printRef = useRef<HTMLDivElement>(null);
    const customMomentVariant = momentVariant();
    const downloadPDF = () => {
        const element = printRef.current;

        // Clonar el contenido a un nodo temporal
        const clone = element?.cloneNode(true) as HTMLElement;
        if (clone) {
            clone.style.display = "block"; // Asegurarse de que el clon sea visible solo para el PDF

            // Agregar el nodo temporal al DOM sin que sea visible para el usuario
            document.body.appendChild(clone);

            const opt = {
                margin:       0.5,
                filename:     `Perfil de unidad - ${device.name}.pdf`,
                image:        { type: 'jpeg', quality: 0.98 },
                html2canvas:  { scale: 2 },
                jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' }
            };

            // Generar el PDF a partir del nodo temporal
            html2pdf().from(clone).set(opt).save().then(() => {
                // Remover el nodo temporal después de generar el PDF
                document.body.removeChild(clone);
            });
        }
    };

    return (
        <div className="datasheet">
            <div className="flex-column mb-2">
                <div className="flex-row justify-between align-center">
                    <span className="title">Perfil de unidad</span>
                    <Button appearance="ghost" className="text-bold" onClick={downloadPDF}>Descargar</Button>
                </div>
            </div>
            <div className="general-info two-columns">
                <div className="flex-column">
                    <span>Marca</span>
                    <span className="text-bold">{device?.vehicle_brand}</span>
                </div>
                <div className="flex-column">
                    <span>Modelo</span>
                    <span className="text-bold">{device?.vehicle_model}</span>
                </div>
                <div className="flex-column">
                    <span>No. de serie</span>
                    <span className="text-bold">{device?.vehicle_serial_number}</span>
                </div> 
            </div>
            <div className="divider"></div>
            <div className="flex-column mb-2">
                <div className="flex-row align-center">
                    <span className="title">Póliza de seguro</span> 
                    <span className="vertical-divider">|</span> 
                    <span className={`text-bold ${customMomentVariant(device?.insurance_policy?.expiration_date).isBefore(customMomentVariant()) ? 'expired' : 'active'}`}>{customMomentVariant(device?.insurance_policy?.expiration_date).isBefore(customMomentVariant()) ? 'Vencida' : 'Activa'}</span>
                </div>
            </div>
            <div className="insurance-info two-columns">
                <div className="flex-column">
                    <span>Titular</span>
                    <span className="text-bold">{device?.insurance_policy?.account_holder}</span>
                </div>
                <div className="flex-column">
                    <span>No. de póliza</span>
                    <span className="text-bold">{device?.insurance_policy?.policy_number}</span>
                </div>
                <div className="flex-column">
                    <span>Expedición</span>
                    <span className="text-bold">{customMomentVariant(device?.insurance_policy?.expedition_date).format('DD/MM/YYYY')}</span>
                </div>
                <div className="flex-column">
                    <span>Expiración</span>
                    <span className="text-bold">{customMomentVariant(device?.insurance_policy?.expiration_date).format('DD/MM/YYYY')}</span>
                </div>
            </div>

            <PrintableContent ref={printRef} device={device} />
        </div>
    );
}

export default Properties;
