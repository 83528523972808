import React, { useState } from "react";
import { GpsDeviceType } from "../../../types/device";
import { Button, Toggle } from "rsuite";
import { Input, Modal, Form, ColorPicker } from "antd";
import "./FootPrint.scss";
import Icon from "../../Icon";
import { createLinearGeofence } from "../../../features/map/Map";
import { useUserPreferences } from "../../../contexts/UserPreferencesContext";
import { useDevices } from "../../../contexts/GpsDeviceContext";
import { useAlert } from "../../../contexts/AlertContext";

interface FootPrintProps {
    device: GpsDeviceType;
    coordinates: Array<{ lat: number, lng: number }>; // Nueva prop
}

const FootPrint: React.FC<FootPrintProps> = ({ device, coordinates }) => {
    const [form] = Form.useForm();
    const { addNewLinearGeofence } = useUserPreferences();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const { setFootPrintState } = useDevices();
    const { showAlert } = useAlert();

    const handleToggleFootPrint = (checked: boolean) => {
        setFootPrintState(device.imei, checked, device.footPrintColor); // Actualiza el estado global del dispositivo
    };

    const hexString = React.useMemo<string>(
        () => (typeof device.footPrintColor === 'string' ? device.footPrintColor : device.footPrintColor?.toHexString()),
        [device.footPrintColor],
    );

    const onFinish = async (values) => {
        setSubmitting(true);
    
        // Convertir coordinates a un objeto con índices como claves y coordenadas como valores
        let data = {
            name: values.name,
            color: device.footPrintColor,
            points: coordinates.reduce((obj, coord, index) => {
                obj[index] = {
                    lat: coord.lat,
                    lng: coord.lng,
                };
                return obj;
            }, {}), // Inicializamos como objeto vacío
        };
    
        const resp = await createLinearGeofence({ georoute: data });

        try{
            if (resp.success) {
                setShowModal(false);
                setSubmitting(false);
                form.resetFields();
        
                addNewLinearGeofence(resp.georoute);
                showAlert('success', 'Geocerca creada correctamente');
        
            } else {
                showAlert('error', 'Ocurrió un error al crear la geocerca');
                setSubmitting(false);
            }
        }catch (error) {
            showAlert('error', 'Ocurrió un error al crear la geocerca, error: ' + error);
            setSubmitting(false);
        }
    
    };

    const handleColorChange = (color) => {
        const newColor = color.toHexString();
        if (newColor !== device.quickViewColor) {
            setFootPrintState(device.imei, device.footPrintActive, newColor); // Actualiza el color del FootPrint
        }
    };

    return (
        <>
            <div className="datasheet">
                <div className="flex-column mb-1">
                    <span className="title">Huella de unidad</span>
                    <div className="toggle-sidebar">
                        <Toggle 
                            classPrefix="toggle" 
                            onChange={handleToggleFootPrint} 
                            color="blue" 
                            checked={device.footPrintActive} // Vinculado al estado global del dispositivo
                        >
                            Activar
                        </Toggle>
                    </div>
                </div>
                {device.footPrintActive && ( // Mostrar la opción si está activado
                    <>
                        <div className="general-info mb-1">
                            <div className="flex-column">
                                <span>Selecciona un color</span>
                                <div className="flex-row align-center">
                                    <Input disabled={true} value={hexString}  style={{ width: 100, marginRight: 10 }} />
                                    <ColorPicker
                                        defaultValue={device.footPrintColor}
                                        onChangeComplete={handleColorChange}
                                        // onChange={handleColorChange} // Cambia el color del dispositivo
                                    />
                                </div>
                            </div>
                        </div>

                        <Button appearance="ghost" className="text-bold" onClick={() => setShowModal(true)}> Guardar como geocerca</Button>
                    </>
                )}
            </div>

            <Modal
                title={
                    <div className="flex-row align-center">
                        <div className="modal-icon-title">
                            <Icon icon="footprint" size="18" />
                        </div>
                        <span className="modal-title">Crear Geocerca Lineal</span>
                    </div>
                }
                width={721}
                centered
                maskClosable={false}
                open={showModal}
                onCancel={() => {
                    form.resetFields();
                    setShowModal(false);
                    setSubmitting(false);
                    
                }}
                afterClose={() => {
                    form.resetFields();
                    setShowModal(false);
                    setSubmitting(false);
                }}
                footer={null}
            >
                <div className="modal-body">
                    <div className="select-lineal-geofence">
                        <Form
                            form={form}
                            name="linear-geofence-form"
                            layout="vertical"
                            style={{ maxWidth: 600 }}
                            onFinish={onFinish}
                            autoComplete="off"
                        >
                            <Form.Item
                                name="name"
                                label="Nombre de la geocerca"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor ingresa el nombre de la geocerca',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Form>
                    </div>
                </div>
                <div className="modal-footer">
                    <Button onClick={() => {
                        form.submit();
                    }} className="text-bold" appearance="primary" loading={submitting}>
                        Crear geocerca
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default FootPrint;
