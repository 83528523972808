import React, { useEffect, useState } from 'react';
import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import { useAlert } from '../../contexts/AlertContext';

type DirectionsProps = {
  origin: google.maps.LatLngAltitudeLiteral
  destination: google.maps.LatLngAltitudeLiteral
  onDirection: (coordinates: google.maps.LatLng[]) => void
};

const Directions = ({ origin, destination, onDirection }: DirectionsProps) => {
  const map = useMap();
  const routesLibrary = useMapsLibrary('routes');
  const [directionsService, setDirectionsService] = useState<google.maps.DirectionsService>();
  const [directionsRenderer, setDirectionsRenderer] = useState<google.maps.DirectionsRenderer>();
  const [coordinates, setCoordinates] = useState<google.maps.LatLng[]>([]);
  const { showAlert } = useAlert();

  // Initialize directions service and renderer
  useEffect(() => {
    if (!routesLibrary || !map) return;
    
    // Crear instancias de DirectionsService y DirectionsRenderer
    const service = new routesLibrary.DirectionsService();
    const renderer = new routesLibrary.DirectionsRenderer({
      map,
      draggable: true, // Permitir que la ruta sea arrastrable/editable
      suppressMarkers: false, // Si quieres ocultar los marcadores de inicio y fin
    });

    setDirectionsService(service);
    setDirectionsRenderer(renderer);

    // Limpiar renderer cuando el componente se desmonte
    return () => {
      renderer.setMap(null);
    };
  }, [routesLibrary, map]);


  // Actualizar coordenadas cuando cambie la ruta
  useEffect(() => {
    onDirection(coordinates);
  }, [coordinates, onDirection]);


  // Use directions service to fetch routes based on origin and destination
  useEffect(() => {
    if (!directionsService || !directionsRenderer || !origin || !destination) return;

    directionsService
      .route({
        origin: origin, // Pasar las coordenadas como origen
        destination: destination, // Pasar las coordenadas como destino
        travelMode: google.maps.TravelMode.DRIVING,
        provideRouteAlternatives: false, // No mostrar rutas alternativas
      })
      .then((response) => {
        directionsRenderer.setDirections(response);
        setCoordinates(response.routes[0].overview_path);
      })
      .catch((error) => {
        console.error('Error fetching directions:', error);
        showAlert('error', 'Error al obtener las direcciones, intente de nuevo, error: ' + error);
      });
  }, [origin, destination, directionsService, directionsRenderer]);

  return null; // No se renderiza ningún contenido en el DOM
};

export default Directions;
