import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useUserPreferences } from "../contexts/UserPreferencesContext";
import { GroupsProps } from "../types/groups";
import { useFilters } from "../contexts/FilterDevicesContext"
import { GpsDeviceType } from '../types/device';
import { useAlert } from '../contexts/AlertContext';

const getGroupsAndDevices = (groups: { [imei: string]: string }, devicesFiltered: GpsDeviceType[]): GroupsProps[] => {
    const result: GroupsProps[] = [];
    const noGroup: GroupsProps = {
        group_name: 'Sin grupo',
        key: 'Sin grupo',
        devices: []
    };

    devicesFiltered.forEach(device => {
        const groupName = groups[device.imei];
        
        if (groupName) {
            const groupIndex = result.findIndex(g => g.group_name === groupName);

            if (groupIndex !== -1) {
                result[groupIndex].devices.push(device);
            } else {
                result.push({
                    group_name: groupName as string,
                    key: groupName.trim(),
                    devices: [device]
                });
            }
        } else {
            noGroup.devices.push(device);
        }
    });

    if (noGroup.devices.length > 0) {
        result.push(noGroup);
    }

    return result;
};

export const useGroups = () => {
    const [groups, setGroups] = useState<GroupsProps[]>([]);
    const [groupsLoading, setGroupsLoading] = useState(true);
    const { preferences, loading_preferences } = useUserPreferences();
    const [apiGroups, setApiGroups] = useState<{ [imei: string]: string }>({});
    const { devicesFiltered } = useFilters();
    const { showAlert } = useAlert();

    const fetchGroups = useCallback(async () => { 
        setGroupsLoading(true);
        try {
            setApiGroups(preferences.groups);
        } catch (error) {
            showAlert('error', 'Error al obtener los grupos');
        } finally {
            setGroupsLoading(false);
        }
    }, [preferences]);

    const memoizedGroups = useMemo(() => {
        if (!apiGroups || !devicesFiltered) return [];
        return getGroupsAndDevices(apiGroups, devicesFiltered);
    }, [devicesFiltered, apiGroups]);

    useEffect(() => {
        if (!loading_preferences && devicesFiltered.length > 0 ) {
            fetchGroups();
        }
    }, [loading_preferences, devicesFiltered]);

    useEffect(() => {
        setGroups(memoizedGroups);
    }, [memoizedGroups]);

    return { groups, groupsLoading };
};
