import React from "react";
import { Form, Upload, Input, Checkbox, Col, Button, Row } from 'antd';
import { newTrailer } from "../../../features/trailers/Trailer";
import { useToaster, Message } from 'rsuite';
import Icon from "../../Icon";
import { useAlert } from "../../../contexts/AlertContext";
import {message} from "../../form/MessageError";


const TrailerForm: React.FC<{ 
    form: any, 
    customFieldsForm: any, 
    onTrailerCreated: (newTrailer: any) => void, 
    customFields: any, 
    setCustomFields: any ,
    showCustomFieldsForm: boolean,
    setShowCustomFieldsForm: any,
    onSubmiting: (submiting: boolean) => void
}> = ({ form, customFieldsForm, onTrailerCreated, customFields, setCustomFields, showCustomFieldsForm, setShowCustomFieldsForm, onSubmiting }) => {
    const toaster = useToaster();
    const { showAlert } = useAlert();

    const onFinish = async (fieldsValue) => {
        onSubmiting(true);
        // console.log('Received values of form: ', fieldsValue);
    
        // Crear un objeto FormData para enviar los datos del formulario
        const formData = new FormData();
    
        // Añadir los campos de texto y fecha al FormData
        formData.append('trailer[name]', fieldsValue['name']);
        formData.append('trailer[code]', fieldsValue['code']);
        formData.append('trailer[exclusive]', fieldsValue['exclusive']);
        formData.append('trailer[description]', fieldsValue['description']);
        
    
        // Añadir la imagen al FormData si existe
        if (fieldsValue.profile_picture && fieldsValue.profile_picture.length > 0) {
            formData.append('trailer[profile_picture]', fieldsValue.profile_picture[0].originFileObj);
        }


        //añadir campos personalizados al formData
        customFields.forEach((field, index) => {
            const key = Object.keys(field)[0].trim().toLowerCase().split(" ").join("_")
            const value = String(Object.values(field)[0]).toLowerCase()
            formData.append(`trailer[extra_data][${index}][${key}]`, value);
        });
        
        const res = await newTrailer(formData);
        try{
            if (res.success) {
                // console.log('Trailer created', res.trailer.id);
                onTrailerCreated(res.trailer);
                onSubmiting(false);
                showAlert('success', 'Remolque creado con éxito');
            } else {
                if (res.errors) {
                    for (let key in res.errors) {
                        toaster.push(message(res.errors[key]), { duration: 5000 });
                    }
                }

                onSubmiting(false);
            }

        } catch (error) {
            showAlert('error', 'Ocurrió un error al crear el remolque, error: ' + error);
            onSubmiting(false);
        }
    };

    const normFile = (e: any) => {
        // console.log('Upload event:', e);
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };


    const handleAddCustomField = (values) => {
        // console.log('Received values of custom field form: ', values);
        setCustomFields([...customFields, { [values.etiqueta]: values.entrada }]);
        customFieldsForm.resetFields();
    }


    return (
        <div className='trailer-form'>
            <div className='trailer-form-inputs'>
                <Form
                    layout="vertical"
                    form={form}
                    name="new-trailer"
                    style={{ maxWidth: "100%" }}
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <Row gutter={16}>
                        <Col span={9}>
                            <Form.Item label="Foto del remolque" valuePropName="fileList" getValueFromEvent={normFile} name="profile_picture">
                                <Upload action="#" listType="picture-card" beforeUpload={() => false} maxCount={1}>
                                    <button style={{ border: 0, background: 'none' }} type="button">
                                        <Icon icon="icon-remolque" size="48" color="#999" />
                                        <div style={{ marginTop: 8, fontWeight:"bold" }}>Subir</div>
                                    </button>
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col span={9}>
                            <Form.Item
                                label="Nombre"
                                name="name"
                                rules={[
                                    { 
                                        required: true, message: 'Por favor ingresa el nombre del remolque', 
                                    },
                                    {
                                        min: 5, message: 'El nombre debe tener al menos 5 caracteres',
                                    }
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="code"
                                label="Codigo"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor ingresa el código del remolque',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="exclusive"
                                valuePropName="checked"
                                label="Exclusivo"
                            >
                                <Checkbox />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item
                        name="description"
                        label="Descripción"
                    >
                        <Input.TextArea />
                    </Form.Item>
                </Form>
                <div className="divider"></div>  
                <Row>
                    <Col span={24}>
                        <div className="flex-row align-center">
                            <span className='title'>Campos personalizados</span>
                            <Button 
                                type="link" 
                                onClick={() => setShowCustomFieldsForm(!showCustomFieldsForm)}
                            >
                                {showCustomFieldsForm ? 'Ocultar' : 'Agregar'}
                            </Button>
                        </div>
                    </Col>
                    <Col span={12}>
                        {showCustomFieldsForm && 
                            <Form 
                                layout="vertical"
                                name="custom_fields"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                onFinish={handleAddCustomField}
                                form={customFieldsForm}
                            >
                                <Form.Item
                                    name="etiqueta"
                                    label="Etiqueta"
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name="entrada"
                                    label="Entrada"
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item wrapperCol={{span: 16 }}>
                                    <Button type="primary" htmlType="submit">
                                        Agregar
                                    </Button>
                                </Form.Item>    
                            </Form>
                        }
                    </Col>
                    <Col span={12}>
                        {showCustomFieldsForm && 
                        
                            <div className="table-container">
                                <table className="responsive-table">
                                    <thead>
                                        <tr>
                                            <th>Etiqueta</th>
                                            <th>Entrada</th>
                                            <th>Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            customFields.map((field, index) => (
                                                <tr key={index}>
                                                    <td data-label="Etiqueta">{Object.keys(field)}</td>
                                                    <td data-label="Entrada">{Object.values(field)}</td>
                                                    <td data-label="Acciones">
                                                        <Button type="link">Eliminar</Button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        }
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default TrailerForm;
