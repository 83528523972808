import ApiClient from "../../services/api_client";
import { CancelTokenSource } from 'axios';



export const getHistoric = async (imei: string,cancelToken?: CancelTokenSource): Promise<any> => {
    try {
        const response = await ApiClient.get({
            endpoint: `/config_messages/history/${imei}`,
            cancelToken: cancelToken?.token 
        });
        return response;

    } catch (error) {
        throw error;
    }
}


export const sendCommand = async (imei: string, command:{
    kind: string,
    imei: string,
    message: {
        name: string,
        data: string
        password: string
        isCustom: boolean
        gprs_only: boolean
    }
}, cancelToken?: CancelTokenSource): Promise<any> => {
    try {
        const response = await ApiClient.post({
            endpoint: `/devices/msg`,
            data: command,
            cancelToken: cancelToken?.token
        });
        return response;
    } catch (error) {
        throw error;
    }
}


