import React from 'react';
import { Checkbox, CheckboxGroup } from 'rsuite';

const CheckboxGroupComponent = ({ title, items, value, onChange, onCheckAll }) => (
    <>
        <Checkbox
            indeterminate={value.length > 0 && value.length < items.length}
            checked={value.length === items.length}
            onChange={onCheckAll}
        >
            {title}
        </Checkbox>
        <CheckboxGroup
            name={`${title.toLowerCase()}List`}
            value={value}
            onChange={onChange}
            style={{ marginLeft: 36 }}
        >
            {items.map(item => (
                <Checkbox key={item.value} value={item.value}>
                    {item.label}
                </Checkbox>
            ))}
        </CheckboxGroup>
    </>
);

export default CheckboxGroupComponent;