import moment from 'moment-timezone';
import 'moment/locale/es-mx'; // Importar el local en español

const momentVariant = () => {
  moment.locale('es-mx'); // Configurar el idioma
  moment.updateLocale('es-mx', {
   relativeTime: {
      future: "en %s",
      past: "+ %s",
      s: 'unos seg',
      ss: '%d seg',
      m: "un min",
      mm: "%d min",
      h: "una hora",
      hh: "%d horas",
      d: "un día",
      dd: "%d días",
      w: "una semana",
      ww: "%d semanas",
      M: "un mes",
      MM: "%d meses",
      y: "un año",
      yy: "%d años"
   }
  });
  return moment;
};

export default momentVariant;
