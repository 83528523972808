import React, { useState, useEffect } from "react";
import { Select, Modal, Form } from "antd";
import Icon from "../../Icon";
import { setStatusToVehicle } from '../../../features/devices/Device';
import { GpsDeviceType } from "../../../types/device";
import { useStatuses } from '../../../hooks/useStatuses';
import { useAlert } from "../../../contexts/AlertContext";
import { message } from "../../form/MessageError";
import { useToaster, Button } from 'rsuite';

const { Option } = Select;

type EventModalProps = {
    open: boolean;
    onClose: () => void;
    device: GpsDeviceType;
};

const EventModal: React.FC<EventModalProps> = ({
    open,
    onClose,  
    device,
}) => {
    const [form] = Form.useForm();
    const [submitting, setSubmitting] = useState(false);
    const {statuses, refreshStatuses} = useStatuses();
    const toaster = useToaster();
    const { showAlert } = useAlert();

    useEffect(() => {
        if (statuses.length > 0){
            if (device.device_status_id){
                form.setFieldsValue({
                    status: device.device_status_id,
                });
            }
        }
    }, [statuses]);


    const onFinish = async (values) => {
        setSubmitting(true);
        try {
            const updateDevice = {
                device_status_id: values.status? values.status : "",
            };
            const res = await setStatusToVehicle(device.imei, updateDevice);
            if (res.success) {
                showAlert('success', "Estado asignado correctamente");
                onClose();
            } else{
                if (res.errors) {
                    for (let key in res.errors) {
                        toaster.push(message(res.errors[key]), { duration: 5000 });
                    }
                }
            }
        } catch (error) {
            showAlert('error', 'Ocurrió un error al asignar el estado, error: ' + error);
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <Modal
            title={
                <div className="flex-row align-center">
                    <div className="modal-icon-title">
                        <Icon icon="warning" size="18" />
                    </div>
                    <span className="modal-title">Asignar estado</span>
                </div>
            }
            width={721}
            centered
            maskClosable={false}
            open={open}
            onCancel={() => {
                form.resetFields();
                refreshStatuses();
                onClose()
            }}
            afterClose={() => {
                form.resetFields();
                refreshStatuses();
                onClose()
            }}
            footer={null}
        >
            <div className="modal-body">
                <div className="select-driver">
                    <Form
                        form={form}
                        name="driver-form"
                        layout="vertical"
                        style={{ maxWidth: 600 }}
                        onFinish={onFinish}
                        autoComplete="off"
                    >
                        <Form.Item
                            name="status"
                            label="Selecciona un estado de la lista"
                        >
                            {statuses.length > 0 ? (
                                <Select placeholder="Por favor selecciona un estado" allowClear={true}>
                                {statuses?.map((status) => (
                                    <Option key={status.id} value={status.id}>
                                        {status.name}
                                    </Option>
                                ))}
                            </Select>
                            ) : (
                                <Select placeholder="Cargando..." allowClear={true} disabled>
                                    <Option value="loading">Cargando...</Option>
                                </Select>   
                            )}
                        </Form.Item>
                    </Form>
                </div>
            </div>
            <div className="modal-footer">
                <Button onClick={() => {
                    form.submit();
                }} className="text-bold" appearance="primary" loading={submitting}>
                    Asignar estado
                </Button>
            </div>
            
        </Modal>
    );
};

export default EventModal;
