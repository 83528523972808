import React, { createContext, useState } from 'react';
//create provider and context for external links
const ExternalLinksContext = createContext<any>(null);

export const ExternalLinksProvider = ({ children, initialData }) => {
    const [car_images, setCarImages] = useState(initialData.car_images);
    const [engine_status, setEngineStatus] = useState(initialData.engine_status);

    return (
        <ExternalLinksContext.Provider value={{ car_images, engine_status }}>
            {children}
        </ExternalLinksContext.Provider>
    );
}

export const useExternalLinks = () => {
    const context = React.useContext(ExternalLinksContext);
    if (context === undefined) {
        throw new Error('useExternalLinks must be used within a ExternalLinksProvider');
    }
    return context;
}