import React from 'react';

const StaticMap = ({ latitude, longitude, zoom = 16, apiKey }) => {
  const mapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=${zoom}&size=330x220&scale=2&maptype=roadmap&markers=color:red%7C${latitude},${longitude}&key=${apiKey}`;

  return (
    <div>
      <img src={mapUrl} alt="Mapa estático de Google Maps" width="330" />
    </div>
  );
};

export default StaticMap;