import React, { createContext, useCallback, useEffect, useState, useContext, useMemo } from 'react';
import { GpsDeviceStatus, GpsDeviceType } from "../types/device";
import { useDevices } from './GpsDeviceContext';
import { useStatusCounts } from './StatusCountContext';

const initialState = [
    {text:'Todas', number:'0', color:'#053951',  selectedcolor:'#044f71', key:'all'},
    {text:'Conduciendo', number:'0', color:'#bffc66', selectedcolor:'#80D602', key:'engine_on'},
    {text:'Ralentí', number:'0', color:'#fed099', selectedcolor:'#FF9019', key:'ralenti'},
    {text:'Detenidas', number:'0', color:'#fea5a5', selectedcolor:'#FF4141', key:'engine_off'},
    {text:'Sin Datos', number:'0', color:'#e7e7e7', selectedcolor:'#C5C5C5', key:'no_data'}
];

type FilterContextType = {
    devicesFiltered: GpsDeviceType[];
    handleFilterChange: (status: GpsDeviceStatus | 'all') => void;
    handleGroupChange: (group: string[]) => void;
    handleStatusChange: (status: string[]) => void;
    btnselected: GpsDeviceStatus | 'all';
    selectedGroups: string[];
    selectedStatuses: string[];
    statusInfo: typeof initialState;
    setDevicesFiltered: React.Dispatch<React.SetStateAction<GpsDeviceType[]>>;
    handleSearch: (term: string) => void;
    groupsAvailables?: string[];
    setGroupsAvailables?: React.Dispatch<React.SetStateAction<string[]>>;
    availableDevicesStatus?: string[];
    setAvailableDevicesStatus?: React.Dispatch<React.SetStateAction<string[]>>;
};

const FilterContext = createContext<FilterContextType | undefined>(undefined);

type FilterProviderProps = {
    children: React.ReactNode;
};

export const FilterProvider: React.FC<FilterProviderProps> = ({ children }) => {
    const { getDevicesByEngineStatus, devicesLoading, setActiveDevice } = useDevices();

    const [btnselected, setBtnselected] = useState<GpsDeviceStatus | 'all'>('all');
    const [statusInfo, setStatusInfo] = useState(initialState);
    const [searchTerm, setSearchTerm] = useState("");
    const [devicesFiltered, setDevicesFiltered] = useState<GpsDeviceType[]>([]);
    const { statusCounts } = useStatusCounts();
    const [isSearching, setIsSearching] = useState(false);
    const [groupsAvailables, setGroupsAvailables] = useState<string[]>([]);
    const [availableDevicesStatus, setAvailableDevicesStatus] = useState<string[]>([]);
    const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
    const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);

    // Memoizar los dispositivos filtrados para evitar re-cálculos innecesarios
    const filteredDevices = useMemo(() => {

        let filtered = getDevicesByEngineStatus(btnselected);

        const uniqueGroups = Array.from(new Set(filtered.map(device => device.group)));
        setGroupsAvailables(uniqueGroups);

        const uniqueStatus = Array.from(new Set(filtered.map(device => device.device_status).filter(Boolean)));
        setAvailableDevicesStatus(uniqueStatus);


        if (selectedGroups.length > 0) {
            filtered = filtered.filter(device => selectedGroups.includes(device.group));
        }

        if (selectedStatuses.length > 0) {

            //if device status change but footPrintActive is true, then still show the device
            // filtered = filtered.filter(device => device.footPrintActive === true);
            filtered = filtered.filter(device => selectedStatuses.includes(device.device_status));
        }

        if (isSearching && searchTerm) {
            filtered = filtered.filter(device =>
                device.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }

        return filtered;
    }, [btnselected, selectedGroups, selectedStatuses, isSearching, searchTerm, getDevicesByEngineStatus, statusCounts]);

    useEffect(() => {
        setDevicesFiltered(filteredDevices);
    }, [filteredDevices]);

    const handleFilterChange = useCallback((selectedStatus: GpsDeviceStatus | 'all') => {
        // console.log({selectedStatus});
        setIsSearching(false);
        setBtnselected(selectedStatus);
        setActiveDevice(null);
    }, []);

    const handleGroupChange = useCallback((groups: string[]) => {
        setSelectedGroups(groups);
        setActiveDevice(null);
    }, []);

    const handleStatusChange = useCallback((statuses: string[]) => {
        setSelectedStatuses(statuses);
        setActiveDevice(null);
    }, []);

    const handleSearch = useCallback((term: string) => {
        setActiveDevice(null);
        setSearchTerm(term);
        setIsSearching(term !== "");
    }, []);

    return (
        <FilterContext.Provider value={{
            devicesFiltered: filteredDevices,
            handleFilterChange,
            handleGroupChange,
            handleStatusChange,
            handleSearch,
            btnselected,
            selectedGroups,
            selectedStatuses,
            statusInfo,
            setDevicesFiltered,
            groupsAvailables,
            setGroupsAvailables,
            availableDevicesStatus,
            setAvailableDevicesStatus,
        }}>
            {children}
        </FilterContext.Provider>
    );
};

export const useFilters = (): FilterContextType => {
    const context = useContext(FilterContext);
    if (!context) {
        throw new Error('useFilters must be used within a FilterProvider');
    }
    return context;
};
