import React, { useRef, useState } from 'react';
import { GpsDeviceType } from "../../types/device";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import DeviceInfo from "./DeviceInfo";

export const GroupContainer = ({ group }) => {
    const [showDevices, setShowDevices] = useState(true);
    const cardBodyRef = useRef<HTMLDivElement>(null);

    const toggleShowDevices = () => {
        if (cardBodyRef.current) {
            if (showDevices) {
                cardBodyRef.current.style.maxHeight = `${cardBodyRef.current.scrollHeight}px`;
                setTimeout(() => {
                    cardBodyRef.current!.style.maxHeight = '0';
                }, 10);
            } else {
                cardBodyRef.current.style.maxHeight = `${cardBodyRef.current.scrollHeight}px`;
                setTimeout(() => {
                    cardBodyRef.current!.style.maxHeight = 'none';
                }, 300);
            }
        }
        setShowDevices(!showDevices);
    };

    return (
        <div className="group-container card">
            <div className='card-header' onClick={toggleShowDevices} aria-expanded={showDevices}>
                <span className="group-name">{group.name}</span>
                <FontAwesomeIcon 
                    icon={showDevices ? faChevronUp : faChevronDown}
                    className="toggle-icon"
                />
            </div>
            <div ref={cardBodyRef} className={`card-body ${showDevices ? 'open' : 'closed'}`}>
                {group.devices.length > 0 ? (
                    group.devices.map((device: GpsDeviceType) => (
                        <DeviceInfo 
                            key={device.imei} 
                            info={device} 
                        />
                    ))
                ) : (
                    <span style={{ fontSize: 20, color: "#bbb" }}>No se encontraron unidades</span>
                )}
            </div>
        </div>
    );
};
