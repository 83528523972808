import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef
} from 'react';

import { GoogleMapsContext, useMapsLibrary } from '@vis.gl/react-google-maps';

import type { Ref } from 'react';

type PolygonEventProps = {
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onDrag?: (e: google.maps.MapMouseEvent) => void;
  onDragStart?: (e: google.maps.MapMouseEvent) => void;
  onDragEnd?: (e: google.maps.MapMouseEvent) => void;
  onMouseOver?: (e: google.maps.MapMouseEvent) => void;
  onMouseOut?: (e: google.maps.MapMouseEvent) => void;
  onVerticesChanged?: (updatedPath: google.maps.LatLngLiteral[]) => void;
};

type PolygonCustomProps = {
  encodedPaths?: string[];
};

export type PolygonProps = google.maps.PolygonOptions &
  PolygonEventProps &
  PolygonCustomProps;

export type PolygonRef = Ref<google.maps.Polygon | null>;

function usePolygon(props: PolygonProps) {
  const {
    onClick,
    onDrag,
    onDragStart,
    onDragEnd,
    onMouseOver,
    onMouseOut,
    onVerticesChanged,
    encodedPaths,
    ...polygonOptions
  } = props;

  const polygon = useRef(new google.maps.Polygon()).current;
  const geometryLibrary = useMapsLibrary('geometry');

  // Actualizar opciones del polígono
  useMemo(() => {
    polygon.setOptions(polygonOptions);
  }, [polygonOptions]);

  const map = useContext(GoogleMapsContext)?.map;

  // Manejar el path codificado si existe
  useMemo(() => {
    if (!encodedPaths || !geometryLibrary) return;
    const paths = encodedPaths.map((path) =>
      geometryLibrary.encoding.decodePath(path)
    );
    polygon.setPaths(paths);
  }, [encodedPaths, geometryLibrary]);

  useEffect(() => {
    if (!map) {
      console.error('<Polygon> debe estar dentro de un componente Map.');
      return;
    }

    polygon.setMap(map);

    return () => {
      polygon.setMap(null);
    };
  }, [map]);

  // Manejar eventos del polígono y cambios en los vértices
  useEffect(() => {
    if (!polygon) return;

    const paths = polygon.getPath();

    // Función para manejar cambios en los vértices
    const handleVertexChange = () => {
      if (onVerticesChanged) {
        const updatedPath = paths.getArray().map((point) => ({
          lat: point.lat(),
          lng: point.lng(),
        }));
        onVerticesChanged(updatedPath); // Llamar cuando cambien los vértices
      }
    };

    // Listener para eventos en los vértices
    google.maps.event.addListener(paths, 'set_at', handleVertexChange);
    google.maps.event.addListener(paths, 'insert_at', handleVertexChange);
    google.maps.event.addListener(paths, 'remove_at', handleVertexChange);

    // Función para manejar eventos estándar (click, mouseover, etc.)
    const gme = google.maps.event;
    const eventListeners = [
      ['click', onClick],
      ['drag', onDrag],
      ['dragstart', onDragStart],
      ['dragend', onDragEnd],
      ['mouseover', onMouseOver],
      ['mouseout', onMouseOut],
    ];

    // Agregar listeners para eventos estándar
    eventListeners.forEach(([eventName, eventHandler]) => {
      if (eventHandler) {
        gme.addListener(polygon, eventName as string, eventHandler as any);
      }
    });

    return () => {
      // Remover listeners al desmontar el componente
      gme.clearListeners(paths, 'set_at');
      gme.clearListeners(paths, 'insert_at');
      gme.clearListeners(paths, 'remove_at');
      eventListeners.forEach(([eventName]) => gme.clearListeners(polygon, eventName as string));
    };
  }, [polygon, onVerticesChanged, onClick, onDrag, onDragStart, onDragEnd, onMouseOver, onMouseOut]);

  return polygon;
}

export const Polygon = forwardRef((props: PolygonProps, ref: PolygonRef) => {
  const polygon = usePolygon(props);
  useImperativeHandle(ref, () => polygon, []);
  return null;
});
