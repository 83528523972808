import React from "react";
import { Whisper } from 'rsuite';
import Icon from '../Icon';
import { Tooltip } from 'antd';

type Item = {
    label: string;
    info: string;
}


const Item = ({ label, info }: Item) => {
    return (
        <div className="info-item">
            <span className="info-label">{label}</span>
            {/* <Tooltip title={info} placement="right">
                <Icon icon="info" size={14} color="blue" className="info-icon" />
            </Tooltip> */}
        </div>
    );
}

export default Item;