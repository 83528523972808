import React from "react";
import FilterButtons from "./FilterButtons";
import FilterSelects from "./FilterSelects";

export const FiltersContainer = () => {

    return (
        <div className="filters-container">
            <FilterButtons/>
            <FilterSelects/>
        </div>
    )
}
