import { useState, useEffect } from "react";

const useCheckboxGroup = (items, initialSelected = []) => {
    const [value, setValue] = useState(initialSelected);

    const handleChange = (newValue) => setValue(newValue);

    const handleCheckAll = (value, checked) => {
        if (checked) {
            setValue(items.map(item => item.value));
        } else {
            setValue([]);
        }
    };

    return { value, handleChange, handleCheckAll };
};

export default useCheckboxGroup;
