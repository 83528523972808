import ApiClient from "../../services/api_client";
import { CancelTokenSource } from 'axios';


export const getNotifications = async (cancelToken?: CancelTokenSource): Promise<any> => {
    try {
        const response = await ApiClient.get({
            endpoint: "/notifications",
            cancelToken: cancelToken?.token 
        });
        return response;

    } catch (error) {
        throw error;
    }
}

export const getNotification = async (id: string, cancelToken?: CancelTokenSource): Promise<any> => {
    try {
        const response = await ApiClient.get({
            endpoint: `/notifications/notification/${id}`,
            cancelToken: cancelToken?.token 
        });
        return response;

    } catch (error) {
        throw error;
    }
}


export const setNotificationsSeen = async (ids: number[], seen: boolean = true, cancelToken?: CancelTokenSource): Promise<any> => {
    try {
        const response = await ApiClient.post({
            endpoint: "/notifications/seen",
            data: {ids, seen},
            cancelToken: cancelToken?.token 
        });
        return response;

    } catch (error) {
        throw error;
    }
}

export const setAllNotificationsSeen = async (cancelToken?: CancelTokenSource): Promise<any> => {
    try {
        const response = await ApiClient.post({
            endpoint: "/notifications/seen_all",
            cancelToken: cancelToken?.token 
        });
        return response;

    } catch (error) {
        throw error;
    }
}
