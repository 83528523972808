import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { GpsDeviceStatus, StatusCount } from '../types/device';
import { useDevices } from './GpsDeviceContext';

const initialStatusCounts = {
    stopped: 0,
    engine_on: 0,
    engine_off: 0,
    ralenti: 0,
    no_data: 0,
};

interface StatusCountsContextProps {
    statusCounts: StatusCount;
    getStatusCount: (engine_status: GpsDeviceStatus) => number;
  }

interface DevicesProviderProps {
    children: ReactNode;
}

const StatusCountsContext = createContext<Partial<StatusCountsContextProps> | undefined>(undefined);

export const StatusCountsProvider: React.FC<DevicesProviderProps> = ({ children }) => {
  const [statusCounts, setStatusCounts] = useState<StatusCount>(initialStatusCounts);
  const { devices } = useDevices();

  useEffect(() => {
    const newCounts = { ...initialStatusCounts };
  
    devices.forEach(device => {
      if (device.engine_status in newCounts) {
        newCounts[device.engine_status] += 1;
      }
    });
  
    setStatusCounts(newCounts);
  }, [devices]);

  const getStatusCount = (status: GpsDeviceStatus | "all"): number => {
    if (status === 'all') {
      return devices.length;
    }
    return statusCounts[status];
  };
  
  return (
    <StatusCountsContext.Provider value={{ statusCounts, getStatusCount }}>
      {children}
    </StatusCountsContext.Provider>
  );
};



export const useStatusCounts = (): StatusCountsContextProps => {
    const context = useContext(StatusCountsContext);
    if (!context) {
        throw new Error('useStatusCounts must be used within a StatusCountsProvider');
    }
    return context as StatusCountsContextProps;
};