import { useState, useEffect } from 'react';
import { getStatus } from "../features/status/Status";

let cachedStatus = null;

export const useStatuses = () => {
    const [statuses, setStatuses] = useState(cachedStatus || []);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [newEvent, setNewEvent] = useState(null);

    const fetchStatus = async () => {
        const res = await getStatus();
        if (res.success) {
            cachedStatus = res.device_statuses;
            setStatuses(res.device_statuses);
        }
    };

    useEffect(() => {
        if (!cachedStatus) {
            fetchStatus();
        }
    }, []);

    const refreshStatuses = () => {
        cachedStatus = null;
        fetchStatus();
    };

    const handleStatusSelection = (status_id) => {
        setSelectedStatus(status_id);
    };


    return {
        statuses,
        selectedStatus,
        submitting,
        newEvent,
        setSubmitting,
        handleStatusSelection,
        refreshStatuses,
    };
};
