import React, { useEffect, useState } from 'react';
import { GpsDeviceType } from '../../../types/device';
import { Button } from 'rsuite';
import './Trailer.scss';


import { useTrailers } from "../../../hooks/useTrailers";

import TrailerModal from './TrailerModal';
import TrailerInfo from './TrailerInfo';

const Trailer: React.FC<{ device: GpsDeviceType }> = ({ device }) => {
    
    const [open, setOpen] = useState(false);
    
    return (
        <div className="datasheet">
            {device?.trailer?.name ? (
                <TrailerInfo device={device} onEdit={() => setOpen(true)} />
            ) : (
                <>
                    <div className='flex-column mb-2'>
                        <span className='title'>Remolque</span>
                    </div>
                    <Button appearance='primary' className='text-bold' onClick={() => setOpen(true)}>Asignar remolque</Button>
                </>
            )}
            <TrailerModal
                open={open}
                device={device}
                onClose={() => setOpen(false)}
            />
        </div>
    );
};

export default Trailer;
