import React, { useEffect, useState } from 'react';
import { GpsDeviceType } from '../../../types/device';
import { Button } from 'rsuite';
import './Driver.scss';


import { useDrivers } from "../../../hooks/useDrivers";

import DriverModal from './DriverModal';
import DriverInfo from './DriverInfo';

const Driver: React.FC<{ device: GpsDeviceType }> = ({ device }) => {
    
    const [open, setOpen] = useState(false);
    
    return (
        <div className="datasheet">
            {device?.driver?.name ? (
                <DriverInfo device={device} onEdit={() => setOpen(true)} />
            ) : (
                <>
                    <div className='flex-column mb-2'>
                        <span className='title'>Conductor</span>
                    </div>
                    <Button appearance='primary' className='text-bold' onClick={() => setOpen(true)}>Asignar conductor</Button>
                </>
            )}
            <DriverModal
                open={open}
                device={device}
                onClose={() => setOpen(false)}
            />
        </div>
    );
};

export default Driver;
