import ApiClient from "../../services/api_client";
import MapiClient from "../../services/mapi_client";
import { CancelTokenSource } from 'axios';

export const getDevices = async (code: string, cancelToken?: CancelTokenSource): Promise<any> => {
    try {
        const response = await MapiClient.get({
            endpoint: `/devices/${code}/all`,
            cancelToken: cancelToken?.token 
        });
        return response;

    } catch (error) {
        throw error;
    }
}

export const getTodayInfo = async (imei: string, cancelToken?: CancelTokenSource): Promise<any> => {
    try {
        const response = await ApiClient.get({
            endpoint: `/current_info/${imei}`,
            cancelToken: cancelToken?.token 
        });
        return response;

    } catch (error) {
        throw error;
    }
}

export const getDevicesUpdates = async (imeis: string, cancelToken?: CancelTokenSource): Promise<any> => {
    try {
        const response = await MapiClient.post({
            endpoint: `/devices/2/list`,
            data: {
                imeis: [imeis]
            },
            cancelToken: cancelToken?.token 
        });
        return response;

    } catch (error) {
        throw error;
    }
}

export const setDriverToVehicle = async (imei: string, device: {driver_id: number, imei: string}, cancelToken?: CancelTokenSource): Promise<any> => {
    try {
        const response = await ApiClient.put({
            endpoint: `/devices/update/${imei}`,
            data: device,
            cancelToken: cancelToken?.token 
        });
        return response;

    } catch (error) {
        throw error;
    }
}

export const setTrailerToVehicle = async (imei: string, device: {trailer_id: number, imei: string}, cancelToken?: CancelTokenSource): Promise<any> => {
    try {
        const response = await ApiClient.put({
            endpoint: `/devices/update/${imei}`,
            data: device,
            cancelToken: cancelToken?.token 
        });
        return response;

    } catch (error) {
        throw error;
    }
}

export const setEventToVehicle = async (imei: string, device: {event_id: number, imei: string}, cancelToken?: CancelTokenSource): Promise<any> => {
    try {
        const response = await ApiClient.put({
            endpoint: `/devices/update/${imei}`,
            data: device,
            cancelToken: cancelToken?.token 
        });
        return response;

    }
    catch (error) {
        throw error;
    }
}

export const setStatusToVehicle = async (imei: string, device: {device_status_id: number}, cancelToken?: CancelTokenSource): Promise<any> => {
    try {
        const response = await ApiClient.put({
            endpoint: `/devices/update/${imei}`,
            data: device,
            cancelToken: cancelToken?.token 
        });
        return response;

    }
    catch (error) {
        throw error;
    }
}