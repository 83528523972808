import { useState, useEffect } from 'react';
import { getRoadEvents } from "../features/road_events/RoadEvent";

let cachedRoadEvents = null;

export const useRoadEvents = () => {
    const [road_events, setRoadEvents] = useState(cachedRoadEvents || []);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [newEvent, setNewEvent] = useState(null);

    const fetchRoadEvents = async () => {
        const res = await getRoadEvents();
        if (res.success) {
            cachedRoadEvents = res.road_events;
            setRoadEvents(res.road_events);
        }
    };

    useEffect(() => {
        if (!cachedRoadEvents) {
            fetchRoadEvents();
        }
    }, []);

    const refreshRoadEvents = () => {
        cachedRoadEvents = null;
        fetchRoadEvents();
    };


    return {
        road_events,
        submitting,
        newEvent,
        setSubmitting,
        refreshRoadEvents,
    };
};
