import { GpsDeviceType } from "../../types/device";

export const getMarkerIcon = (device: GpsDeviceType) => {
  if (device.engine_status === 'stopped' || device.engine_status === 'engine_off') {
    return "/assets/images/icon-stopped.png";
  }
  if (device.engine_status === 'engine_on') {
    return "/assets/images/icon-engine_on.png";
  }
  if (device.engine_status === 'ralenti') {
    return "/assets/images/icon-ralenti.png";
  }
  if (device.engine_status === 'no_data') {
    return "/assets/images/icon-no_data.png";
  }
  return "/assets/images/default-icon.png"; // Por si algún estado no coincide
}
