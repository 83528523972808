import MapiClient from "../../services/mapi_client";
import { CancelTokenSource } from 'axios';

export const getGeocoding = async (lat: number, lng: number, cancelToken?: CancelTokenSource): Promise<any> => {
    try {
        const response = await MapiClient.get({
            endpoint: `/geocoded/${lat}/${lng}/address`,
            cancelToken: cancelToken?.token 
        });
        return response;

    } catch (error) {
        throw error;
    }
}
