import React from 'react';
import { Battery, LastConnection, Satellites, Speed } from "../devices/DeviceHelpers";
import Icon from '../Icon';
import { APP_URL } from '../../utils/constants';
import { useExternalLinks } from '../../contexts/ExternalLinksContext';
import { engineStatuColors } from "../../constants/devices";
import momentVariant  from '../../utils/momentConfig';





const DeviceInfo = ({ info }) => {
    const { car_images, engine_status } = useExternalLinks();

    const getEngineStatus = (device) => {
        const defaultEngineStatus = 'no_data';
        const engine_statuses = engine_status ? engine_status[device.engine_status] : defaultEngineStatus;
        const interval_inactivity = 24;
        const customMomentVariant = momentVariant();
      
        const lastReportDate = customMomentVariant(device.datetime);
        const currentDate = customMomentVariant();
      
        const hoursSinceLastReport = currentDate.diff(lastReportDate, 'hours');
        let inactive = false;
        if (hoursSinceLastReport >= interval_inactivity) {
          inactive = true;
        }
      
        if (inactive) return 'no_data';
        if (engine_statuses === 'off') return 'engine_off';
        if (engine_statuses === 'on' && device.speed <= 5) return 'ralenti';
        if (engine_statuses === 'on' && device.speed > 5) return 'engine_on';
      
        return defaultEngineStatus; // Fallback return value
      };

    const getIconPath = (car_image: number) => {
        return car_images[car_image] || car_images[1];
    };

    return (
        info.connection &&  
        <div className="device-container">
            <div className='device-info-container'>
                <div className="info">
                    <div 
                        className="circle" 
                        style={{
                            borderWidth: 3,
                            borderStyle: 'solid',
                            borderColor: info.connection && engineStatuColors[getEngineStatus(info.connection)] || '#FF4141',
                            backgroundImage: `url(${APP_URL}${getIconPath(info.car_image)})`
                        }} 
                    />
                    <div className='name-container'>
                        <p className="name" title={info.name}>{info.name}</p>
                        {info.driver?.name && 
                            <div className="driver-container">
                                <Icon icon='users' size={13} color='#3F3F3F' />
                                <span className='separator'></span> 
                                <span className='driver'>{info.driver.name}</span>
                            </div>
                        }
                    </div>
                </div>
                <div className="details">
                    <Battery percentage={info.connection.battery} />
                    <Satellites satellites={info.connection.satellites} />
                    <LastConnection last_connection={info.connection.datetime} />
                    <Speed speed={info.connection.speed} unit={"km"} show_title={false} />
                </div>
            </div>
        </div>
    );
};

export default DeviceInfo;
