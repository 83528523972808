import React from "react";

import { useNotifications } from "../../contexts/NotificationContext";
import { Drawer, Button, Badge } from "rsuite";
import PlaceholderGrid from "rsuite/esm/Placeholder/PlaceholderGrid";
import Icon from "../Icon";
import "./Notifications.scss";
import { Modal } from "antd";
import StaticMap from "../map/StaticMap";
import momentVariant from "../../utils/momentConfig";



const NotificationItem = ({ notification }) => {
    const customMomentVariant = momentVariant();
    const [visible, setVisible] = React.useState(false);
    const  { setSeenNotification } = useNotifications();
    const matches = notification.message.match(/Equipo: (.*?) Hora: (.*?) Evento: (.*?) Velocidad: (.*?) Posicion: (.*)/);
    return (
        <>
            <div className="notification-item" onClick={() => {
                setVisible(true);
                setSeenNotification(notification);
            }}>
                <div className="notification-item-header">
                    <div className="alert-title">
                        <div className={`notification-item-icon ${notification.seen ? 'seen' : 'unseen'}`}>
                            <Icon icon="warning" size={16} />
                        </div>
                        {notification.seen ? <div className="divider"></div> : null}
                        <div className="notification-item-title text-bold">{notification.title}</div>
                        <Badge style={{ backgroundColor: notification.seen ? "#f0f0f0" : "#3B83FF" }} className="notification-item-badge"/>
                    </div>
                    <div className="notification-item-body">{notification.message.substring(0, 38)}...</div>
                </div>
                <div className="notification-item-date">{customMomentVariant(notification.event_date).format("DD/MM/YYYY HH:mm:ss")}</div>
            </div>
            <Modal
                title={
                    <div className="alert-title justify-start">
                        <div className={`notification-item-icon unseen`}>
                            <Icon icon="warning" size={16} />
                        </div>
                        <div className="text-bold ml-1">{notification.title}</div>
                    </div>
                }
                width={700}
                open={visible}
                onCancel={() => setVisible(false)}
                footer={null}
            >
                <div className="flex-row">
                    <StaticMap 
                        latitude={notification.lat} 
                        longitude={notification.lng} 
                        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} 
                    />
                    <div className="notification-details-container">
                        <table className="responsive-table table-compact">
                            <tbody>
                                <tr>
                                    <td className="text-bold">Equipo</td>
                                    <td>{matches[1]}</td>
                                </tr>
                                <tr>
                                    <td className="text-bold">Fecha</td>
                                    <td>{matches[2]}</td>
                                </tr>
                                <tr>
                                    <td className="text-bold">Evento</td>
                                    <td>{matches[3]}</td>
                                </tr>
                                <tr>
                                    <td className="text-bold">Velocidad</td>
                                    <td>{matches[4]}</td>
                                </tr>
                                <tr>
                                    <td className="text-bold">Posición</td>
                                    <td>{matches[5]}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </Modal>
        </>
    )
}


const Notifications = () => {
    const { show_notifications, notifications, notifications_loading, setSeenAllNotifications } = useNotifications();
    return (
        <div id="notifications" className={`notifications-container ${show_notifications ? 'show' : ''}`}>
            <Drawer size={240} placement={"right"} open={show_notifications} backdrop={false} closeButton={false} className="notifications-drawer">
                <Drawer.Header closeButton={false} className="notifications-header">
                    <div className="notifications-header-icon">
                        <Drawer.Title className="notifications-title">Notificaciones</Drawer.Title>
                        {/* <Button 
                            className='btn-info'
                        >
                                <Icon icon="info" color="#3B83FF" size={12} />
                        </Button> */}
                    </div>
                    <div className="notifications-header-actions">
                        <Button 
                            appearance="ghost"
                            className={`mb-1 text-bold`}
                            onClick={() => setSeenAllNotifications()}
                        >
                            Marcar todas como leídas
                        </Button>
                        <Button 
                            appearance="ghost"
                            className={`text-bold`}
                            href="/admin/notifications"
                            target="_blank"
                        >
                            Ver todas las notificaciones
                        </Button>
                    </div>


                </Drawer.Header>
                <Drawer.Body className="notifications-body">
                    {notifications_loading ? (
                        <PlaceholderGrid rows={5} columns={1} rowHeight={60} active />
                    ) : (
                        <div className="notifications-list">
                            {notifications.length > 0 ? (
                                notifications.map((notification, index) => (
                                    <NotificationItem key={index} notification={notification} />
                                ))
                            ) : (
                                <div className="notifications-empty">No hay notificaciones</div>
                            )}
                        </div>
                    )}
                </Drawer.Body>
            </Drawer>
        </div>
    )
}

export default Notifications;
