import React, { useState, useCallback, useEffect } from "react";
import { Map } from "@vis.gl/react-google-maps";
import CustomMarker from "../map/CustomMarker"; 
import { type Marker, MarkerUtils } from '@googlemaps/markerclusterer';
import { GpsDeviceType } from "../../types/device";
import DeviceInformation from "../devices/generalinfo/DeviceInformation";
import "./IndividualMap.scss";
import { useMap } from '@vis.gl/react-google-maps';
import { Drawer } from 'rsuite';
import { GroupContainer } from "./GroupContainer";
import ClusterComponent from "./ClusterComponent";
import momentVariant from "../../utils/momentConfig";
import { useExternalLinks } from "../../contexts/ExternalLinksContext";



// const CustomMarkerMemo = React.memo(CustomMarker);
const MapComponent = ({ external_link }) => {
    const [devices, setDevices] = useState([]);
    const {engine_status} = useExternalLinks();

    const getEngineStatus = (device) => {
        const defaultEngineStatus = 'no_data';
        const engine_statuses = engine_status ? engine_status[device.engine_status] : defaultEngineStatus;
        const interval_inactivity = 24;
        const customMomentVariant = momentVariant();
      
        const lastReportDate = customMomentVariant(device.datetime);
        const currentDate = customMomentVariant();
      
        const hoursSinceLastReport = currentDate.diff(lastReportDate, 'hours');
        let inactive = false;
        if (hoursSinceLastReport >= interval_inactivity) {
          inactive = true;
        }
      
        if (inactive) return 'no_data';
        if (engine_statuses === 'off') return 'engine_off';
        if (engine_statuses === 'on' && device.speed <= 5) return 'ralenti';
        if (engine_statuses === 'on' && device.speed > 5) return 'engine_on';
      
        return defaultEngineStatus; // Fallback return value
      };

    useEffect(() => {
        external_link.groups.forEach(group => {
            group.devices.filter(device => device.connection).forEach(device => {
                device.engine_status = getEngineStatus(device.connection);
                //actualizar el estado de los dispositivos
                setDevices(prevDevices => {
                    const index = prevDevices.findIndex(d => d._id.$oid === device._id.$oid);
                    if (index > -1) {
                        prevDevices[index] = device;
                        return [...prevDevices];
                    }
                    return [...prevDevices, device];
                });

            });
        });
    }, [external_link]);


    return (
        <div className="map-container">
            <Map
                mapId={"map"}
                defaultZoom={5}
                defaultCenter={{ lat: 24.3650554, lng: -110.8918574 }}
                gestureHandling={"greedy"}
                maxZoom={21}
                minZoom={2}
                disableDefaultUI={true}
            >
                {devices && <ClusterComponent devicesFiltered={devices} />}
            </Map>
            <Drawer size={441} placement={"left"} open={true} backdrop={false} closeButton={false} className="devices-container">
                <Drawer.Body className='devices-body'>
                    <div className="groups-container">
                        {external_link.groups.sort((a, b) => a.name.localeCompare(b.name)).map(group => (
                            <GroupContainer
                                key={group.id}
                                group={group}
                            />
                        ))}
                    </div>
                </Drawer.Body>
            </Drawer>
        </div>
    );
};

export default MapComponent;
