import {useEffect, useMemo, useRef, useContext, forwardRef, useImperativeHandle} from 'react';
import {GoogleMapsContext, useMapsLibrary} from '@vis.gl/react-google-maps';

type PolylineEventProps = {
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onDrag?: (e: google.maps.MapMouseEvent) => void;
  onDragStart?: (e: google.maps.MapMouseEvent) => void;
  onDragEnd?: (e: google.maps.MapMouseEvent) => void;
  onMouseOver?: (e: google.maps.MapMouseEvent) => void;
  onMouseOut?: (e: google.maps.MapMouseEvent) => void;
  onMouseMove?: (e: google.maps.MapMouseEvent) => void;
  onPathChanged?: (newPath: google.maps.LatLng[]) => void; // Nuevo evento que incluye el nuevo path
};

type PolylineCustomProps = {
  encodedPath?: string;
};

export type PolylineProps = google.maps.PolylineOptions &
  PolylineEventProps &
  PolylineCustomProps;

function usePolyline(props: PolylineProps) {
  const {
    onClick,
    onDrag,
    onDragStart,
    onDragEnd,
    onMouseOver,
    onMouseOut,
    onPathChanged,
    onMouseMove,
    encodedPath,
    ...polylineOptions
  } = props;

  const callbacks = useRef<Record<string, (e: unknown) => void>>({});
  Object.assign(callbacks.current, {
    onClick,
    onDrag,
    onDragStart,
    onDragEnd,
    onMouseOver,
    onMouseOut,
    onMouseMove
  });

  const geometryLibrary = useMapsLibrary('geometry');
  const polyline = useRef(new google.maps.Polyline()).current;

  useMemo(() => {
    polyline.setOptions(polylineOptions);
  }, [polyline, polylineOptions]);

  const map = useContext(GoogleMapsContext)?.map;

  useMemo(() => {
    if (!encodedPath || !geometryLibrary) return;
    const path = geometryLibrary.encoding.decodePath(encodedPath);
    polyline.setPath(path);
  }, [polyline, encodedPath, geometryLibrary]);

  useEffect(() => {
    if (!map) {
      if (map === undefined)
        console.error('<Polyline> has to be inside a Map component.');
      return;
    }
    polyline.setMap(map);

    return () => {
      polyline.setMap(null);
    };
  }, [map]);

  useEffect(() => {
    if (!polyline) return;

    const gme = google.maps.event;
    const events = [
      ['click', 'onClick'],
      ['drag', 'onDrag'],
      ['dragstart', 'onDragStart'],
      ['dragend', 'onDragEnd'],
      ['mouseover', 'onMouseOver'],
      ['mouseout', 'onMouseOut'],
      ['mousemove', 'onMouseMove']
    ];

    events.forEach(([eventName, eventCallback]) => {
      gme.addListener(polyline, eventName, (e: google.maps.MapMouseEvent) => {
        const callback = callbacks.current[eventCallback];
        if (callback) callback(e);
      });
    });

    // Detect path changes (edits)
    if (onPathChanged) {
      const path = polyline.getPath();

      // Escuchar cambios en el path
      const handlePathChanged = () => {
        const updatedPath: google.maps.LatLng[] = [];
        path.forEach((latLng) => {
          updatedPath.push(latLng);
        });
        onPathChanged(updatedPath); // Llama al evento pasando el path actualizado
      };

      gme.addListener(path, 'set_at', handlePathChanged);
      gme.addListener(path, 'insert_at', handlePathChanged);
    }

    return () => {
      gme.clearInstanceListeners(polyline);
    };
  }, [polyline, onPathChanged]);

  return polyline;
}

export const Polyline = forwardRef((props: PolylineProps, ref) => {
  const polyline = usePolyline(props);

  useImperativeHandle(ref, () => polyline, []);

  return null;
});
