import React, { useState } from "react";
import { AdvancedMarker } from '@vis.gl/react-google-maps';
import { useUserPreferences } from "../../contexts/UserPreferencesContext";
import { Polyline } from "./Polyline";

interface GeofenceLabelProps {
    position: { lat: number, lng: number };
    label: string;
}

const GeofenceLabel: React.FC<GeofenceLabelProps> = ({ position, label }) => {
    return (
        <AdvancedMarker 
            position={position}
            draggable={false}
            clickable={false}
            collisionBehavior="OPTIONAL_AND_HIDES_LOWER_PRIORITY"
        >
            <div className="geofence-label" style={{ backgroundColor: 'white', padding: '5px', borderRadius: '5px' }}>
                {label}
            </div>
        </AdvancedMarker>
    );
};

const LinearGeofences = () => {
    const [hoveredGeofence, setHoveredGeofence] = useState<string | null>(null);
    const [mousePosition, setMousePosition] = useState<{ lat: number, lng: number } | null>(null);
    const { linear_geofences, geofences_names } = useUserPreferences();

    const handleMouseMove = (event: any) => {
        // Obtener la latitud y longitud del evento del mouse
        const latLng = event.latLng;
        setMousePosition({ lat: latLng.lat(), lng: latLng.lng() });
    };

    // const calculateCentroid = (points: { lat: number, lng: number }[]) => {
    //     let totalLat = 0;
    //     let totalLng = 0;
    //     const numPoints = points.length;
    
    //     points.forEach(point => {
    //         totalLat += point.lat;
    //         totalLng += point.lng;
    //     });
    
    //     return {
    //         lat: totalLat / numPoints,
    //         lng: totalLng / numPoints
    //     };
    // };

    return (
        <>
            {linear_geofences?.map((geofence, index) => (
                <React.Fragment key={index}>
                    <Polyline
                        path={Object.values(geofence.points).map((point: any) => ({ lat: Number(point.lat), lng: Number(point.lng) }))}
                        strokeColor={geofence.color || '#FF0000'}
                        strokeOpacity={0.8}
                        strokeWeight={4}
                        zIndex={index + 99}  // Asignar un zIndex basado en el orden
                        onMouseOver={() => setHoveredGeofence(geofence.id)}
                        onMouseOut={() => {
                            setHoveredGeofence(null);
                            setMousePosition(null); // Limpiar la posición del mouse al salir
                        }}
                        onMouseMove={handleMouseMove} // Capturar la posición del mouse
                    />
                    {/* {
                        geofences_names && geofence.id && (
                            <GeofenceLabel 
                                position={calculateCentroid(Object.values(geofence.points).map((point: any) => ({ lat: Number(point.lat), lng: Number(point.lng) })))}
                                label={geofence.name}
                            />
                        )
                    } */}

                    {geofences_names && hoveredGeofence === geofence.id && mousePosition && (
                        <GeofenceLabel 
                            position={mousePosition} // Usar la posición del mouse
                            label={geofence.name}
                        />
                    )}
                </React.Fragment>
            ))}
        </>
    );
};

export default LinearGeofences;
