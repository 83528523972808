import React, { useEffect } from "react";
import { Modal, Form, Input, Select, Row, Col, Checkbox } from "antd";
import momentExternal from "../../utils/momentConfigLinks";
import { Button, DatePicker } from "rsuite";
import "./ExternalLinkModal.scss";
import { APP_URL } from "../../utils/constants";
import { getExternalLinks, deleteExternalLink, updateExternalLink, createExternalLink } from "../../features/external_link/ExternalLink";
import Icon from "../Icon";
import { useDevices } from "../../contexts/GpsDeviceContext";
import { useUserPreferences } from "../../contexts/UserPreferencesContext";

type ExternalLinkModalProps = {
    open: boolean;
    onClose?: () => void;
    handleOpen?: () => void;
};

type ExternalLink = {
    created_at: string;
    device_count: number;
    expiration_date_now: boolean;
    expiration_end: string;
    expiration_start: string;
    name: string;
    notes: string;
    selection: string[];
    selection_type: string;
    updated_at: string;
    user_id: number;
    util_long: string;
    util_number: number;
    _id: { $oid: string };
};

const ExternalLinkModal = ({ open, onClose, handleOpen }: ExternalLinkModalProps) => {
    const [external_links, setExternalLinks] = React.useState<ExternalLink[]>([]);
    const [loading, setLoading] = React.useState(true);
    const [view_mode, setViewMode] = React.useState<'list' | 'form' | 'confirmation'>('list');
    const [selected_link, setSelectedLink] = React.useState<ExternalLink | null>(null);
    const [new_link_url, setNewLinkUrl] = React.useState<string | null>(null); 
    const [form] = Form.useForm(); 
    const { devices } = useDevices(); 
    const { groups_for_select, preferences } = useUserPreferences();
    const [loading_form, setLoadingForm] = React.useState(false);

    // Estado para manejar las opciones dinámicas en el Select
    const [selectionOptions, setSelectionOptions] = React.useState<any[]>([]);

    const [isExpirationStartDisabled, setIsExpirationStartDisabled] = React.useState(false);
    const customMoment = momentExternal();


    useEffect(() => {
        const fetchExternalLinks = async () => {
            try {
                const response = await getExternalLinks();
                if (response.success) {
                    setExternalLinks(response.external_links);
                }
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };
        fetchExternalLinks();
    }, []);

    // Función para manejar cambios en el formulario
    const handleValuesChange = (changedValues: any, allValues: any) => {
        if (changedValues.selection_type) {
            if (changedValues.selection_type === "groups") {
                // Si selecciona "groups", carga las opciones de grupos
                setSelectionOptions(groups_for_select
                    .map(group => ({
                        label: group.label,
                        value: group.value
                    }))
                );
            } else if (changedValues.selection_type === "devices") {
                // Si selecciona "devices", carga las opciones de dispositivos
                setSelectionOptions(devices.map(device => ({
                    label: device.name,
                    value: device.imei
                })));
            }
        }

        if (changedValues.expiration_date_now !== undefined) {
            setIsExpirationStartDisabled(changedValues.expiration_date_now);
        }
    };

    const handleDelete = async (id: string) => {
        if (confirm("¿Estás seguro de eliminar este link?")) {
            const originalLinks = [...external_links];
            setExternalLinks(prevLinks => prevLinks.filter(link => link._id.$oid !== id));

            try {
                const response = await deleteExternalLink(id);
                if (!response.success) {
                    setExternalLinks(originalLinks);
                    alert("Error al eliminar el link");
                } else {
                    alert("Link eliminado exitosamente");
                }
            } catch (error) {
                setExternalLinks(originalLinks);
                alert("Error en el servidor al eliminar el link");
            }
        }
    };

    const handleNewLink = () => {
        setSelectedLink(null); 
        setViewMode('form');   
        form.resetFields();    
    };

    const handleEditLink = (link) => {
        setSelectedLink(link); 
        setViewMode('form');

        // Configurar el estado inicial del checkbox y deshabilitar el campo si es necesario
        const expirationDateNow = link.expiration_date_now || false;
        setIsExpirationStartDisabled(expirationDateNow);

        setSelectionOptions(link.selection_type === "groups" ? groups_for_select : devices.map(device => ({ label: device.name, value: device.imei })));


        if(link.selection_type === "groups") {
            link.selection = link.selection.map(group => parseInt(group));
        }

        // Establecer valores del formulario, incluidos los valores iniciales del checkbox y las fechas
        form.setFieldsValue({
            ...link,
            expiration_start: link.expiration_start ? customMoment(link.expiration_start).toDate() : null,
            expiration_date_now: expirationDateNow,
        });
    };

    const handleFormSubmit = (values: any) => {
        setLoadingForm(true);
        
        if (values.expiration_date_now) {
            values.expiration_start = customMoment().format('YYYY-MM-DD HH:mm:ss');
        } else {
            const currentMoment = customMoment();
            const timePart = currentMoment.format('HH:mm:ss'); // Hora actual en formato HH:mm:ss
            values.expiration_start = customMoment(values.expiration_start, 'DD/MM/YYYY').format(`YYYY-MM-DD ${timePart}`);
        }
        
        if(values.util_long != 'hours') {
            values.expiration_start = customMoment(values.expiration_start).format('YYYY-MM-DD');
        }
        values.expiration_end = customMoment(values.expiration_start).add(values.util_number, values.util_long)

        if (values.util_long != 'hours') {
            values.expiration_end = customMoment(values.expiration_end).format('YYYY-MM-DD');
        } else {
            values.expiration_end = customMoment(values.expiration_end).format('YYYY-MM-DD HH:mm:ss');
        }

        if (selected_link) {
            updateExternalLink(selected_link._id.$oid, {external_link: values}).then(response => {
                if (response.success) {
                    const linkUrl = `${APP_URL}/external_links/show/${selected_link._id.$oid}`;

                    setExternalLinks(prevLinks => prevLinks.map(link => {
                        if (link._id.$oid === selected_link._id.$oid) {

                            if(response.external_link.util_long != 'hours') {
                                response.external_link.expiration_start = customMoment(response.external_link.expiration_start).format('YYYY-MM-DD');
                                response.external_link.expiration_end = customMoment(response.external_link.expiration_end).format('YYYY-MM-DD');
                            }

                            return {
                                ...link,
                                ...response.external_link
                            };
                        }
                        return link;
                    }));

                    setNewLinkUrl(linkUrl);
                    setViewMode('confirmation');

                } else {
                    alert("Error al actualizar el link");
                }
            }).catch(error => {
                console.error(error);
                alert("Error en el servidor al actualizar el link");
            }).finally(() => {
                setLoadingForm(false);
            });
        }else {
            createExternalLink({external_link: values}).then(response => {
                if (response.success) {
                    const linkUrl = `${APP_URL}/external_links/show/${response.external_link._id.$oid}`;
                    if(response.external_link.util_long != 'hours') {
                        response.external_link.expiration_start = customMoment(response.external_link.expiration_start).format('YYYY-MM-DD');
                        response.external_link.expiration_end = customMoment(response.external_link.expiration_end).format('YYYY-MM-DD');
                    }

                    setExternalLinks(prevLinks => [
                        ...prevLinks,
                        response.external_link
                    ]);

                    setNewLinkUrl(linkUrl);
                    setViewMode('confirmation');

                } else {
                    alert("Error al crear el link");
                }
            }).catch(error => {
                console.error(error);
                alert("Error en el servidor al crear el link");
            }).finally(() => {
                setLoadingForm(false);
            });
        }


    };

    const renderLinkStatus = (start: string, end: string, unit: string) => {
        const now = customMoment().tz("America/"+preferences.user_preferences.timezone);

        if(unit != 'hours') {
            start = customMoment(start).format('YYYY-MM-DD');
            end = customMoment(end).format('YYYY-MM-DD');
        }

        const startDate = customMoment(start)
        const endDate = customMoment(end)

        if (startDate.isAfter(now)) {
            return (
                <span className="badge badge-warning">
                    Inicia en {startDate.fromNow(true)}
                </span>
            );
        } else if (startDate.isBefore(now) && endDate.isAfter(now)) {
            return (
                <span className="badge badge-success">
                    Activo, expira en {endDate.fromNow(true)}
                </span>
            );
        } else if (endDate.isBefore(now)) {
            return (
                <span className="badge badge-danger">
                    Expiró {endDate.fromNow()}
                </span>
            );
        }
    };

    // Pantalla de confirmación para copiar el link creado/editado
    const renderConfirmationScreen = () => (
        <>
            <div className="confirmation">
                <div className="circle-success"></div>
                <h4>¡Link creado exitosamente!</h4>
                <a className="shared-link" target="_blank" href={new_link_url}>
                    {new_link_url}
                </a>
                <Button
                    onClick={() => {
                        navigator.clipboard.writeText(new_link_url || '');
                        alert("Link copiado al portapapeles");
                    }}
                    appearance="ghost"
                >
                    <Icon icon="icon-copy" size={18} /> Copiar link
                </Button>
                <div className="horizontal-divider" />
                <Button appearance="primary" onClick={() => setViewMode('list')}>
                    Continuar
                </Button>
            </div>
        </>
    );


    return (
        <Modal
            title={
                view_mode != 'confirmation' ?
                    <div className="flex-row align-center">
                        <div className="modal-icon-title">
                            <Icon icon="external-link" size="18" />
                        </div>
                        <span className="modal-title">Links de seguimiento externo</span>
                    </div>
                : null
            }
            closable={view_mode != 'confirmation'}
            open={open}
            centered
            onCancel={() => {
                form.resetFields();
                setLoadingForm(false);
                if (view_mode === 'confirmation') {
                    setViewMode('list');
                } else {
                    handleOpen();
                }
            }}
            footer={null}
            width={800}
        >
            <div className="modal-body pb-0">
                {view_mode === 'list' ? (
                    <>
                        <Button onClick={handleNewLink} appearance="primary" className="btn-new-link font-bold">
                            Crear nuevo link de seguimiento
                        </Button>

                        <table className="table-striped">
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Estado</th>
                                    <th>Unidades</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td colSpan={4}>Cargando...</td>
                                    </tr>
                                ) : external_links.length > 0 ? (
                                    external_links.map((externalLink: ExternalLink) => (
                                        <tr key={externalLink._id.$oid}>
                                            <td>{externalLink.name}</td>
                                            <td>{renderLinkStatus(externalLink.expiration_start, externalLink.expiration_end, externalLink.util_long)}</td>
                                            <td>{externalLink.device_count}</td>
                                            <td>
                                                <Button
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(`${APP_URL}/external_links/show/${externalLink._id.$oid}`);
                                                        alert("Link copiado al portapapeles");
                                                    }}
                                                    className="btn-copy"
                                                >
                                                    <Icon icon="icon-copy" size={18} color="#5c71f9" />
                                                </Button>
                                                <Button className="btn-edit" onClick={() => handleEditLink(externalLink)}>
                                                    <Icon icon="edit" size={14} color="#5c71f9" />
                                                </Button>
                                                <Button className="btn-delete" onClick={() => handleDelete(externalLink._id.$oid)}>
                                                    <Icon icon="delete" size={14} color="#ff3130" />
                                                </Button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={4}>No hay links de seguimiento</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </>
                ) : view_mode === 'form' ? (
                    <Form form={form} onFinish={handleFormSubmit} layout="vertical" onValuesChange={handleValuesChange}>
                        <span className="title">Unidades</span>
                        <Row gutter={16}>
                            <Col span={9}>
                                <Form.Item
                                    name={["selection_type"]}
                                    label="Tipo de selección de unidades"
                                    rules={[{ required: true, message: 'Por favor selecciona un tipo de selección' }]}
                                >
                                    <Select onChange={() => form.setFieldsValue({ selection: [] })} placeholder="Selecciona un tipo">
                                        <Select.Option value="groups">Por grupos</Select.Option>
                                        <Select.Option value="devices">Por unidades</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={14}>
                                <Form.Item
                                    name={["selection"]}
                                    label="Seleccion de unidades"
                                    rules={[{ required: true, message: 'Por favor selecciona una unidad' }]}
                                >
                                    <Select mode="multiple" 
                                        placeholder="Selecciona una o más unidades"
                                        showSearch
                                        options={selectionOptions}
                                        optionFilterProp="label"

                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <div className="horizontal-divider" />
                        <span className="title">Tiempo de duración</span>
                        <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                name="expiration_start"
                                label="Tiempo de activación"
                                rules={[{ required: !isExpirationStartDisabled, message: 'Por favor selecciona una fecha' }]}
                            >  
                                <DatePicker 
                                    format="dd/MM/yyyy"
                                    oneTap
                                    style={{ width: '100%' }}
                                    disabled={isExpirationStartDisabled} // Deshabilitar si expiration_date_now está activo
                                />
                            </Form.Item>
                            <Form.Item name="expiration_date_now" valuePropName="checked">
                                <Checkbox>Activar ahora</Checkbox>
                            </Form.Item>
                        </Col>
                            <Col span={8}>
                                <Form.Item
                                    name={["util_long"]}
                                    label="Duración"
                                    rules={[{ required: true, message: 'Por favor selecciona una duración' }]}
                                >
                                    <Select placeholder="Selecciona una duración">
                                        <Select.Option value="hours">Horas</Select.Option>
                                        <Select.Option value="days">Días</Select.Option>
                                        <Select.Option value="weeks">Semanas</Select.Option>
                                        <Select.Option value="months">Meses</Select.Option>
                                        <Select.Option value="years">Años</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name={["util_number"]}
                                    label="Cantidad"
                                    rules={[{ required: true, message: 'Por favor selecciona una cantidad' }]}
                                >
                                    <Input type="number" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <div className="horizontal-divider" />
                        <span className="title">Datos generales</span>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name={["name"]}
                                    label="Nombre"
                                    rules={[{ required: true, message: 'Por favor ingresa un nombre' }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name={["notes"]}
                                    label="Notas"
                                >
                                    <Input.TextArea />
                                </Form.Item>
                            </Col>
                        </Row>
                        <div className="horizontal-divider" />

                        <div className="form-footer flex-row justify-between">
                            <Button 
                                appearance="ghost" 
                                onClick={() => {
                                    setViewMode('list');
                                    form.resetFields();
                                    setLoadingForm(false);
                                }}
                                className="cancel text-bold"
                            >
                                Cancelar
                            </Button>
                            <Button 
                                appearance="primary" 
                                type="submit" 
                                loading={loading_form}
                                className="save text-bold"
                            >
                                Guardar link
                            </Button>
                        </div>

                    </Form>
                ) : view_mode === 'confirmation' ? (
                    renderConfirmationScreen()
                ) : null}
            </div>
        </Modal>
    );
};

export default ExternalLinkModal;
