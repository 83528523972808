import React, { useState, useEffect } from "react";
import { Select, Modal, Form } from "antd";
import { Button, Tabs } from 'rsuite';
import Icon from "../../Icon";
import TrailerForm from "./TrailerForm";
import { useTrailers } from "../../../hooks/useTrailers";
import { setTrailerToVehicle } from '../../../features/devices/Device';
import { GpsDeviceType } from "../../../types/device";
import { useAlert } from "../../../contexts/AlertContext";

const { Option } = Select;

type TrailerModalProps = {
    open: boolean;
    onClose: () => void;
    device: GpsDeviceType;
};

const TrailerModal: React.FC<TrailerModalProps> = ({
    open,
    onClose,  
    device,
}) => {

    const [activeTab, setActiveTab] = useState("1");
    const [form] = Form.useForm();
    const [customFieldsForm] = Form.useForm();
    const [showCustomFieldsForm, setShowCustomFieldsForm] = useState(false);
    const { 
        trailers, 
        handleTrailerSelection, 
        selectedTrailer, 
        submitting, 
        setSubmitting, 
        handleTrailerCreation, 
        newTrailer 
    } = useTrailers();
    const { showAlert } = useAlert();

    // Nuevo estado para manejar los campos personalizados en el TrailerForm
    const [customFields, setCustomFields] = useState([]);

    useEffect(() => {
        if (device?.trailer?.id != 0) {
            handleTrailerSelection(device.trailer.id);
        }
    }, [device, trailers]);

    useEffect(() => {

        if (newTrailer) {
            handleSubmitSelectedTrailer();
        }

    }, [newTrailer])

    const handleSubmitSelectedTrailer = async () => {
        setSubmitting(true);
        try {
            const updateDevice = {
                imei: device.imei,
                trailer_id: selectedTrailer || newTrailer?.id,
            };
            const res = await setTrailerToVehicle(device.imei, updateDevice);
            if (res.success) {
                onClose();
                showAlert("success", "Remolque asignado correctamente");
            }else{
                showAlert("error", "Error al asignar el remolque");
            }
        } catch (error) {
            showAlert("error", "Error al asignar el remolque, intente nuevamente, error: " + error);
        } finally {
            setSubmitting(false);
        }
    };

    const handleSave = () => {
        if (activeTab === "2") {
            form.submit(); // Enviar el formulario de creación de remolque
        } else {
            handleSubmitSelectedTrailer(); // Guardar el remolque seleccionado
        }
    };

    const handleTrailerCreated = (newTrailer) => {
        handleTrailerCreation(newTrailer); // Añadir y seleccionar el nuevo remolque
    };

    return (
        <Modal
            title={
                <div className="flex-row align-center">
                    <div className="modal-icon-title">
                        <Icon icon="icon-remolque" size="18" />
                    </div>
                    <span className="modal-title">Asignar remolque</span>
                </div>
            }
            width={721}
            centered
            maskClosable={false}
            open={open}
            onCancel={() => {
                onClose();
                form.resetFields(); // Restablecer el formulario y la pestaña activa
                customFieldsForm.resetFields();
                setCustomFields([]); // Limpiar los campos personalizados
                setShowCustomFieldsForm(false);
                setActiveTab("1");
            }}
            afterClose={() => {
                customFieldsForm.resetFields();
                form.resetFields();
                setCustomFields([]); // Limpiar los campos personalizados
                setShowCustomFieldsForm(false);
                setActiveTab("1");
            }}
            footer={null}
        >
            <Tabs activeKey={activeTab} onSelect={(key) => setActiveTab(String(key))} className="trailer-tabs" appearance="subtle">
                <Tabs.Tab eventKey="1" title="Elegir remolque">
                    <div className="modal-body custom-padding">
                        {trailers.length > 0 ? (
                            <div className="select-trailer">
                                <label>Selecciona un remolque de la lista</label>
                                <Select
                                    showSearch
                                    style={{ width: 400 }}
                                    placeholder="Selecciona un remolque de la lista"
                                    optionLabelProp="label"
                                    filterOption={(input, option) => {
                                        const label = option?.label;
                                        return typeof label === "string" && label.toLowerCase().includes(input.toLowerCase());
                                    }}
                                    value={selectedTrailer}
                                    onChange={handleTrailerSelection}
                                    allowClear
                                >
                                    <Option value="" label="No asignar remolque" className="no-trailer">
                                        <span className="flex-row align-center justify-between">
                                            No asignar remolque <Icon icon="exit" color="#FF0000" size={12} />
                                        </span>
                                    </Option>
                                    {trailers.map((trailer) => (
                                        <Option key={trailer.id} value={trailer.id} label={trailer.name}>
                                            {trailer.name}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        ) : (
                            <span style={{ textAlign: "center", fontWeight: "bold" }}>Cargando remolques...</span>
                        )}
                    </div>
                    <div className="modal-footer">
                        <Button onClick={handleSave} appearance="primary" className="save" loading={submitting}>
                            Guardar
                        </Button>
                    </div>
                </Tabs.Tab>
                <Tabs.Tab eventKey="2" title="Crear remolque">
                    <div className="modal-body custom-padding">
                        <TrailerForm 
                            form={form} 
                            customFieldsForm={customFieldsForm}
                            onSubmiting={setSubmitting}
                            onTrailerCreated={handleTrailerCreated} 
                            customFields={customFields} 
                            setCustomFields={setCustomFields}
                            showCustomFieldsForm={showCustomFieldsForm}
                            setShowCustomFieldsForm={setShowCustomFieldsForm}
                        />
                    </div>
                    <div className="modal-footer">
                        <Button onClick={handleSave} appearance="primary" className="save" loading={submitting}>
                            Guardar
                        </Button>
                    </div>
                </Tabs.Tab>
            </Tabs>
        </Modal>
    );
};


export default TrailerModal;
