import axios, { AxiosInstance, AxiosError } from 'axios';
import { ApiConfig, ApiResponse } from '../types/api';
import { storage } from './storage';
import { MAPI_URL } from '../utils/constants';

class MAPI {
    private static instance: MAPI;
    private axiosInstance: AxiosInstance;
  
    private constructor() {
      this.axiosInstance = axios.create({
        baseURL: MAPI_URL,
        timeout: 10000,
      });
      
      this.setAuthHeaders();
    
      this.axiosInstance.defaults.headers['App'] = 'mobilityweb';

    }

    private async setAuthHeaders() {
      const setToken = this.setTokenHeader();
      const setUuid = this.setUUIDHeader();

      return await Promise.all([setToken, setUuid]);
      
    }

    private async setUUIDHeader() {
      const uuid = await storage.getUUID();
      if (uuid) {
          this.axiosInstance.defaults.headers['Uuid'] = `${uuid}`;
      }else{
        //remove uuid header
        delete this.axiosInstance.defaults.headers['Uuid'];
      }
    }

    private async setTokenHeader() {
      const userToken = await storage.getUserToken(); 
      if (userToken) {
        this.axiosInstance.defaults.headers['X-Api-Key'] = `${userToken}`;
      }else{
        //remove token header
        delete this.axiosInstance.defaults.headers['X-Api-Key'];
      }
    }
  
    public static getInstance(): MAPI {
      if (!MAPI.instance) {
        MAPI.instance = new MAPI();
      }
  
      return MAPI.instance;
    }

    public setHeaders(headers: Record<string, string>): void {
      this.axiosInstance.defaults.headers = { ...this.axiosInstance.defaults.headers, ...headers };
    }
  
    async get({ endpoint, params, cancelToken }: ApiConfig): Promise<ApiResponse> {
      return this.setAuthHeaders().then(() => {
        return this.makeRequest('GET', { endpoint, params, cancelToken });
      });
    }
  
    async post({ endpoint, data, cancelToken }: ApiConfig): Promise<ApiResponse> {

      return this.setAuthHeaders().then(() => {
        return this.makeRequest('POST', { endpoint, data, cancelToken });
      });

    }
  
    async put({ endpoint, data, cancelToken }: ApiConfig): Promise<ApiResponse> {

      return this.setAuthHeaders().then(() => {
        return this.makeRequest('PUT', { endpoint, data, cancelToken });
      });

    }
  
    async delete({ endpoint, params, cancelToken }: ApiConfig): Promise<ApiResponse> {
      
      return this.setAuthHeaders().then(() => {
        return this.makeRequest('DELETE', { endpoint, params, cancelToken });
      });

    }
  
    private async makeRequest(method: 'GET' | 'POST' | 'PUT' | 'DELETE', config: ApiConfig): Promise<ApiResponse> {

      // console.log({ method, config, headers: this.axiosInstance.defaults.headers, baseURL: this.axiosInstance.defaults.baseURL });

      try {
        const response = await this.axiosInstance.request({
          method,
          url: config.endpoint,
          data: config.data,
          params: config.params,
          cancelToken: config.cancelToken
        });
  
        return response.data;
      } catch (error: any) {
        
        //validate 401
        if(error.response?.status === 401){
          let clearStorage =storage.clear();
          if (clearStorage) {
            window.location.href = '/logout';
          }
        }

        if(axios.isCancel(error)) {
          throw error;
        }
        
        throw {
          success: false,
          error: error.response?.data?.error,
          code: error.response?.status
        }
      }
    }
}

const MapiClient = MAPI.getInstance();
export default MapiClient;