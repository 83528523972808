import React, { useEffect, useState, createContext } from 'react';
import { APIProvider } from "@vis.gl/react-google-maps";
import { getExternalLink } from '../../features/external_link/ExternalLink';
import IndividualTopBar from '../individual_tracking/IndividualTopBar';
import ExternalLinksMap from './ExternalLinksMap';
import { ExternalLinksProvider } from '../../contexts/ExternalLinksContext';

const ExternalLinks: React.FC<any> = ({ id, car_images, engine_status }) => {
    // console.log({car_images, engine_status});
    const [externalLink, setExternalLink] = useState(null);
    const [devices_white_list, setDevicesWhiteList] = useState([]);

    const fetchExternalLink = async (id: string) => {
        getExternalLink(id).then((response) => {  
            if(response.success){
                setExternalLink(response.external_link);
                response.external_link.groups.forEach(group => {
                    group.devices.filter(device => device.connection).forEach(device => {
                        //actualizar el estado de los dispositivos
                        setDevicesWhiteList(prevDevices => {
                            const index = prevDevices.findIndex(d => d.imei === device.imei);
                            if (index > -1) {
                                prevDevices[index] = device.imei;
                                return [...prevDevices];
                            }
                            return [...prevDevices, device.imei];
                        });
                    });
                });
            }
        })
        .catch((error) => {
            console.log('error', error);
        });
    }

    useEffect(() => {
        if (id) {
            fetchExternalLink(id);
        }

        return () => {
            // Limpiar el estado cuando el componente se desmonte
            setExternalLink(null);
        }

    }, [id]);

    useEffect(() => {
        let websocket: WebSocket | null = null;
        if (externalLink?.code) {
            // Crear WebSocket
            websocket = new WebSocket(`wss://app.mobility-go.mx:5000/api/v1/channel/${externalLink.code}/ws`);
            websocket.onopen = () => {
                console.log("WebSocket connected");
            };
            websocket.onmessage = (event) => {
                var msg = event.data.split("|");
                if(msg[0] == "3"){

                    if(devices_white_list.includes(msg[1])){
                        fetchExternalLink(id);
                    }
                    
                }
            };
            websocket.onclose = () => {
                console.log("WebSocket closed");
            };
        }
        
        return () => {
            // Limpiar WebSocket cuando externalLink cambie o el componente se desmonte
            if (websocket) {
                websocket.close();
            }
        };
    }, [externalLink?.code]);

    return (
        <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
            <ExternalLinksProvider initialData={{ car_images, engine_status }}>
                <IndividualTopBar />
                {externalLink && <ExternalLinksMap external_link={externalLink} />}
            </ExternalLinksProvider>
        </APIProvider>
    );
};

export default ExternalLinks;
