import React, { createContext, useContext, useState, ReactNode, ReactElement } from 'react';

// Tipos para la alerta
type AlertType = 'success' | 'warning' | 'error' | 'info';

// Tipos para el estado de la alerta
interface AlertState {
  isOpen: boolean;
  type: AlertType;
  message: string | ReactNode;
  icon?: string | ReactElement;
}

// Tipos para el contexto
interface AlertContextProps {
  alert: AlertState;
  showAlert: (type: AlertType, message: string | ReactNode, icon?: string | ReactElement) => void;
  closeAlert: () => void;
}

// Crear el contexto con valor inicial vacío
const AlertContext = createContext<AlertContextProps | undefined>(undefined);

// Proveedor del contexto de alertas
export const AlertProvider = ({ children }: { children: ReactNode }) => {
  const [alert, setAlert] = useState<AlertState>({
    isOpen: false,
    type: 'info',
    message: '',
  });

  // Función para mostrar la alerta
  const showAlert = (type: AlertType, message: string, icon: string = '') => {
    setAlert({
      isOpen: true,
      type,
      message,
      icon,
    });
  };

  // Función para cerrar la alerta
  const closeAlert = () => {
    setAlert({
      ...alert,
      isOpen: false,
    });
  };

  return (
    <AlertContext.Provider value={{ alert, showAlert, closeAlert }}>
      {children}
    </AlertContext.Provider>
  );
};

// Hook para usar el contexto de alertas en cualquier componente
export const useAlert = (): AlertContextProps => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error('useAlert must be used within an AlertProvider');
  }
  return context;
};
