import React, { useState } from "react";
import { Polygon } from "./Polygon";
import { AdvancedMarker } from '@vis.gl/react-google-maps';
import { useUserPreferences } from "../../contexts/UserPreferencesContext";

interface GeofenceLabelProps {
    position: { lat: number, lng: number };
    label: string;
}

const GeofenceLabel: React.FC<GeofenceLabelProps> = ({ position, label }) => {
    return (
        <AdvancedMarker 
            position={position}
            draggable={false}
            clickable={false}
            collisionBehavior="OPTIONAL_AND_HIDES_LOWER_PRIORITY"
        >
            <div className="geofence-label" style={{ backgroundColor: 'white', padding: '5px', borderRadius: '5px' }}>
                {label}
            </div>
        </AdvancedMarker>
    );
};

// Función para calcular el centroide de un polígono
const calculateCentroid = (points: { lat: number, lng: number }[]) => {
    let totalLat = 0;
    let totalLng = 0;
    const numPoints = points.length;

    points.forEach(point => {
        totalLat += point.lat;
        totalLng += point.lng;
    });

    return {
        lat: totalLat / numPoints,
        lng: totalLng / numPoints
    };
};

const PolygonalGeofences = () => {
    const [hoveredGeofence, setHoveredGeofence] = useState<string | null>(null);
    const { polygonal_geofences, geofences_names } = useUserPreferences();

    return (
        <>
            {polygonal_geofences?.map((geofence, index) => (
                <React.Fragment key={index}>
                    <Polygon
                        paths={geofence.polygon_points.map((point: any) => ({ lat: Number(point.lat), lng: Number(point.lng) }))}
                        fillColor={geofence.color || '#FF0000'}
                        strokeColor={geofence.color || '#FF0000'}
                        fillOpacity={0.35}
                        strokeOpacity={0.8}
                        strokeWeight={2}
                        zIndex={index + 99}  // Asignar un zIndex basado en el orden
                        onMouseOver={() => setHoveredGeofence(geofence.id)}
                        onMouseOut={() => setHoveredGeofence(null)}
                    />
                    {geofences_names && hoveredGeofence === geofence.id && (
                        <GeofenceLabel 
                            position={calculateCentroid(geofence.polygon_points.map((point: any) => ({ lat: Number(point.lat), lng: Number(point.lng) })))}
                            label={geofence.name}
                        />
                    )}
                </React.Fragment>
            ))}
        </>
    );
};

export default PolygonalGeofences;
