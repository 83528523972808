import React from "react";
import Icon from "../Icon";
import { Button } from "rsuite";

const ToolsButton = ({ icon, id, onClick, active }) => (
    <Button 
        key={id}
        className={`tool-btn ${active ? 'active' : ''}`}
        id={id}
        onClick={onClick}
    >
        <Icon icon={icon} size={24} color="white" />
    </Button>
);

export default ToolsButton;