import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { type Marker, MarkerClusterer, MarkerUtils } from '@googlemaps/markerclusterer';
import { useMap } from '@vis.gl/react-google-maps';
import { useDevices } from '../../contexts/GpsDeviceContext';
import { useFilters } from '../../contexts/FilterDevicesContext';
import CustomMarker from './CustomMarker';

const CustomMarkerMemo = React.memo(CustomMarker);

const ClusterComponent = ({devicesFiltered}) => {
  const [markers, setMarkers] = useState<{ [key: string]: Marker }>({});
  const map = useMap();
  const sidebarWidth = 440; // Ancho del sidebar en píxeles


  const clusterer = useMemo(() => {
    if (!map) return null;
    return new MarkerClusterer({
      map,
      onClusterClick: (event, cluster) => {
        const bounds = cluster.bounds;
        if (bounds) {
          const padding = { top: 0, right: 0, bottom: 0, left: sidebarWidth };
          map.fitBounds(bounds, padding);
        }
      }
    });
  }, [map, sidebarWidth]);

  useEffect(() => {
    if (!clusterer) return;
    clusterer.clearMarkers();
    clusterer.addMarkers(Object.values(markers));

    
  }, [clusterer, markers]);


  const setMarkerRef = useCallback((marker: Marker | null, id: number) => {
    setMarkers(prevMarkers => {
      if (prevMarkers[id] === marker) {
        return prevMarkers; // No hacer nada si no hay cambios
      }
      const newMarkers = { ...prevMarkers };
      if (marker) {
        newMarkers[id] = marker;
      } else {
        delete newMarkers[id];
      }
      return newMarkers;
    });
  }, []);

  return (
    <>
      {devicesFiltered.map(device => (
        <CustomMarkerMemo key={device.imei} device={device} setMarkerRef={setMarkerRef} />
      ))}
    </>
  );
};

export default ClusterComponent;
