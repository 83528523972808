import ApiClient from "../../services/api_client";
import { CancelTokenSource } from 'axios';


export const getSpecialMessages = async (cancelToken?: CancelTokenSource):Promise<any> => {
    try {
        const response = await ApiClient.get({
            endpoint: `/special_messages`,
            cancelToken: cancelToken?.token 
        });
        return response;

    } catch (error) {
        throw error;
    }
}