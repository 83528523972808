
import React, {useEffect, useState}  from 'react';

import { useStatusCounts } from "../../contexts/StatusCountContext";
import { useDevices } from "../../contexts/GpsDeviceContext";


const Filterbutton = (props:any) => {
    const [selected, setSelected] = useState(false)
    const { getStatusCount } = useStatusCounts();
    const {devicesLoading} = useDevices();

    useEffect(() => {
      if(props.info.key == props.isSelected){
          setSelected(true)
      }else{
          setSelected(false)  
      }
    }, [props.isSelected]);

    if(devicesLoading){
      return (
        <button className={"btn-container" + (selected ? " selected" : "") + " " +props.info.key}>
          <div className="number-container">
            <span className='text'>...</span>
          </div>
          <span className="text"> {props.info.text} </span>
        </button>
      );
    }

    return (
        <button className={"btn-container" + (selected ? " selected" : "") + " " +props.info.key} onClick={props.selectStatus}>
          <div className="number-container">
            <span className="number"> {getStatusCount(props.info.key)} </span>
          </div>
          <span className="text"> {props.info.text} </span>
        </button>   
    );
  }


export default Filterbutton