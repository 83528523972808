import React from "react";

const IndividualTopBar = () => {
  return (
    <div className="topbar">
            <div id="topbar-left">
                <figure id="logo">
                    <img src="/assets/images/logo.png" alt="Logo" />
                </figure>
            </div>
        </div>
  );
};

export default IndividualTopBar;