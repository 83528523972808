import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { GpsDeviceStatus, GpsDeviceType, GpsDeviceRespType } from "../types/device";
import { getDevices, getDevicesUpdates } from "../features/devices/Device";
import { useUserPreferences } from './UserPreferencesContext';
import type { ColorPickerProps, GetProp } from 'antd';
import momentVariant from '../utils/momentConfig';

type Color = Extract<GetProp<ColorPickerProps, 'value'>, string | { cleared: any }>;

interface GpsDevicesContextProps {
  devices: GpsDeviceType[];
  devicesLoading: boolean;
  error: string | null;
  activeDevice: string | null; // Añadido
  setActiveDevice: (imei: string | null) => void; // Añadido
  updateDevicesFromWebSocket: (device: string) => void;
  getDevicesByEngineStatus: (engine_status: GpsDeviceStatus | "all") => GpsDeviceType[];
  getDevice: (imei: string) => GpsDeviceType | undefined;
  removeDevices: () => void;
  loadDevices: () => void;
  groupsInfo: GroupsInfo;
  groupsInfoLoading: boolean;
  setDeviceVisibility: (imei: string, visible: boolean) => void;
  setFootPrintState: (imei: string, isActive: boolean, color?: Color) => void;
  setQuickViewState: (imei: string, isActive: boolean, color?: Color, coordinates?: Array<{ lat: number, lng: number, orientation?: number }>) => void;
}

const DevicesContext = createContext<GpsDevicesContextProps | undefined>(undefined);

interface DevicesProviderProps {
  children: ReactNode;
  initialGroups?: any;
}

interface GroupsInfo {
  [imei: string]: string;
}

export const DevicesProvider: React.FC<DevicesProviderProps> = ({ children, initialGroups = [] }) => {
  const [devices, setDevices] = useState<GpsDeviceType[]>([]);
  const [devicesLoading, setDevicesLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [activeDevice, setActiveDevice] = useState<string | null>(null); // Añadido
  const { preferences, loading_preferences } = useUserPreferences();
  const [groupsInfo, setGroupsInfo] = useState<GroupsInfo>(initialGroups);
  const customMomentVariant = momentVariant();

  const getEngineStatus = (device: GpsDeviceRespType): GpsDeviceStatus => {
    const defaultEngineStatus = 'no_data';
    const engine_status = preferences?.engine_status ? preferences.engine_status[device.engine_status] : defaultEngineStatus;
    const interval_inactivity = preferences?.user_preferences.device_alert_interval || 24;
  
    const lastReportDate = customMomentVariant(device.last_connection);
    const currentDate = customMomentVariant();
  
    const hoursSinceLastReport = currentDate.diff(lastReportDate, 'hours');
    let inactive = false;
    if (hoursSinceLastReport >= interval_inactivity) {
      inactive = true;
    }
  
    if (inactive) return 'no_data';
    if (engine_status === 'off') return 'engine_off';
    if (engine_status === 'on' && device.speed <= 5) return 'ralenti';
    if (engine_status === 'on' && device.speed > 5) return 'engine_on';
  
    return defaultEngineStatus; // Fallback return value
  };

  const parseDevices = (devices: GpsDeviceRespType[]): GpsDeviceType[] => {
    return devices.map((device) => {
        const hasValidCoordinates = device.coordinates && device.coordinates.length >= 2;
        return {
            ...device,
            engine_status: getEngineStatus(device),
            coordinates: {
                lat: hasValidCoordinates ? device.coordinates[1] : 0,
                lng: hasValidCoordinates ? device.coordinates[0] : 0,
            },
            visible: true,
            group: groupsInfo[device.imei] || "Sin grupo",
            footPrintActive: false,
            footPrintColor: '#1677ff',
            quickViewActive: false,
            quickViewColor: '#1677ff',
            quickViewCoordinates: [] // Inicializa las coordenadas del QuickView
        };
    });
};

  const setFootPrintState = (imei: string, isActive: boolean, color?: Color) => {
    setDevices(prevDevices =>
        prevDevices.map(device =>
            device.imei === imei 
                ? { ...device, footPrintActive: isActive, footPrintColor: color || device.footPrintColor } 
                : device
        )
    );
  };


  const setQuickViewState = (imei: string, isActive: boolean, color?: string, coordinates?: Array<{ lat: number, lng: number, orientation?: number }>) => {
    setDevices(prevDevices =>
        prevDevices.map(device =>
            device.imei === imei 
                ? { 
                    ...device, 
                    quickViewActive: isActive, 
                    quickViewColor: color || device.quickViewColor,
                    quickViewCoordinates: coordinates || [] // Añadir coordenadas al estado del dispositivo
                  } 
                : device
        )
    );
};


  const loadDevices = async () => {
    try {
      setDevicesLoading(true);
      const response = await getDevices(preferences?.user_preferences.code || '');
      if (response && response.devices) {
        setDevices(parseDevices(response.devices));
      }
    } catch (err) {
      console.error(err);
      setError('Error loading devices');
    } finally {
      setDevicesLoading(false);
    }
  };

  useEffect(() => {
    if (preferences && !loading_preferences) {
      loadDevices();
    }
  }, [preferences, loading_preferences]);

  const removeDevices = () => {
    setDevices([]);
    setDevicesLoading(true);
    setError(null);
  };

  const updateDevicesFromWebSocket = async (imei: string) => {
    try {
      const response = await getDevicesUpdates(imei);
      if (!response || !response.devices || response.devices.length === 0) return;
  
      setDevices((prevDevices) =>
        prevDevices.map((device) => {
          const updatedDevice = response.devices.find((newDevice: GpsDeviceRespType) => newDevice.imei === device.imei);
          if (updatedDevice) {
            return {
              ...device,
              ...updatedDevice,
              engine_status: getEngineStatus(updatedDevice),
              footPrintActive: device.footPrintActive, // Mantener el estado del FootPrint
              footPrintColor: device.footPrintColor, // Mantener el color del FootPrint
              quickViewActive: device.quickViewActive, // Mantener el estado del QuickView
              quickViewColor: device.quickViewColor, // Mantener el color del QuickView
              visible: device.visible,
              coordinates: {
                lat: updatedDevice.coordinates[1],
                lng: updatedDevice.coordinates[0],
              },
            };
          }
          return device;
        })
      );
    } catch (error) {
      console.error("Failed to update devices from WebSocket", error);
    }
  };

  const getDevicesByEngineStatus = (status: GpsDeviceStatus | 'all'): GpsDeviceType[] => {
    if (status === 'all') return devices;
    
    //if device footPrintActive is true, then still show the device
    // return devices.filter(device => device.footPrintActive === true || device.engine_status === status);

    return devices.filter(device => device.engine_status === status);
  };

  const getDevice = (imei: string): GpsDeviceType | undefined => {
    return devices.find((device) => device.imei === imei);
  };

  const setDeviceVisibility = (imei: string, visible: boolean) => {
    setDevices(prevDevices =>
      prevDevices.map(device =>
        device.imei === imei ? { ...device, visible } : device
      )
    );
  };

  return (
    <DevicesContext.Provider value={{
      devices,
      devicesLoading,
      error,
      activeDevice, 
      setActiveDevice, 
      updateDevicesFromWebSocket,
      getDevicesByEngineStatus,
      getDevice,
      removeDevices,
      loadDevices,
      groupsInfo,
      groupsInfoLoading: devicesLoading,
      setDeviceVisibility,
      setFootPrintState,
      setQuickViewState,
    }}>
      {children}
    </DevicesContext.Provider>
  );
};

export const useDevices = (): GpsDevicesContextProps => {
  const context = useContext(DevicesContext);
  if (!context) {
    throw new Error('useDevices must be used within a DevicesProvider');
  }
  return context;
};
