import ApiClient from "../../services/api_client";
import { CancelTokenSource } from 'axios';



export const getDrivers = async (cancelToken?: CancelTokenSource): Promise<any> => {
    try {
        const response = await ApiClient.get({
            endpoint: `/drivers/`,
            cancelToken: cancelToken?.token 
        });
        return response;

    } catch (error) {
        throw error;
    }
}

export const getDriversLicenses = async (cancelToken?: CancelTokenSource): Promise<any> => {
    try {
        const response = await ApiClient.get({
            endpoint: `/drivers/licenses/`,
            cancelToken: cancelToken?.token 
        });
        return response;

    } catch (error) {
        throw error;
    }
}

export const newDriver = async (data: any, cancelToken?: CancelTokenSource): Promise<any> => {
    try {
        const response = await ApiClient.post({
            endpoint: `/drivers/`,
            data,
            cancelToken: cancelToken?.token 
        });
        return response;

    } catch (error) {
        throw error;
    }
}

