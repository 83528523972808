import React, { useCallback } from "react";

import { getMarkerIcon } from "./MarkerIcon";
import type { Marker } from '@googlemaps/markerclusterer';
import { AdvancedMarker } from '@vis.gl/react-google-maps';

import { useUserPreferences } from "../../contexts/UserPreferencesContext";

interface PoiMarkerProps {
  poi: any;
  setMarkerRef: (marker: Marker | null, id: number) => void;
}

const PoiMarker: React.FC<PoiMarkerProps> = (props: PoiMarkerProps) => {
    const { poi, setMarkerRef } = props;
    const { poi_names } = useUserPreferences();

    const ref = useCallback((marker: Marker | null) => setMarkerRef(marker, poi.id), [setMarkerRef, poi.id]);

    const markerStyle: React.CSSProperties = {
        position: 'absolute',
        transform: 'translate(-50%, -50%) translateY(-35%)',
    };

    const imageStyle: React.CSSProperties = {
        width: '30px',
        height: '30px',
    };

    const labelStyle: React.CSSProperties = {
        position: 'absolute',
        top: '-20px', // Ajusta según sea necesario
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: 'white',
        color: 'black',
        padding: '2px 5px',
        borderRadius: '5px',
        boxShadow: '0 2px 6px rgba(0,0,0,0.3)',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: '100px',
        zIndex: 1,
        fontSize: '12px', // Ajusta según sea necesario
    };

    const iconContainerStyle: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    };

    return (
        <AdvancedMarker position={{ lat: Number(poi.latitude), lng: Number(poi.longitude) }} ref={ref} style={markerStyle}>
        <div style={iconContainerStyle}>
            {poi_names && <div style={labelStyle}>{`${poi.name}`}</div>}
        </div>
        <img src="assets/images/poi_marker.png" style={{ width: '13px', height: '24px', padding: "0 10px" }} alt="marker" />
        </AdvancedMarker>
    );
};

export default PoiMarker;
