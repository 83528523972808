export const engineStatuColors = {
    "engine_on": '#80D602',
    "ralenti": '#FF9019',
    "engine_off": '#FF4141',
    "no_data": '#C5C5C5',
    "stopped": '#FF4141',
};

export const engineStatusLocales = {
    "engine_on": 'Conduciendo',
    "ralenti": 'Ralenti',
    "engine_off": 'Detenido',
    "no_data": 'Sin datos',
    "stopped": 'Detenido',
};