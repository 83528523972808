import React, { createContext, useContext, useState, ReactNode, useEffect, useRef } from 'react';
import { getSpecialMessages } from '../features/special_messages/SpecialMessage';
import axios, { CancelTokenSource } from 'axios';


interface SpecialMessagesContextProps {
    specialMessages: any;
}


const SpecialMessagesContext = createContext<SpecialMessagesContextProps | undefined>(undefined);

export const SpecialMessagesProvider = ({ children }: { children: ReactNode }) => {
    const [specialMessages, setSpecialMessages] = useState<any>([]);
    const cancelTokenRef = useRef<CancelTokenSource | null>(null);

    useEffect(() => {
      
        const fetchData = async () => {

            // Si existe una solicitud en curso, la cancelamos antes de hacer una nueva
            if (cancelTokenRef.current) {
                cancelTokenRef.current.cancel('Request canceled due to new request.');
            }
            
            // Creamos un nuevo token de cancelación para la nueva solicitud
            cancelTokenRef.current = axios.CancelToken.source();
            try {
                const res = await getSpecialMessages(cancelTokenRef.current);
                if (res.success) {
                    setSpecialMessages(res.special_messages);
                }

            } catch (error) {
                console.error({specialMessagesError: error});
            }
        }

        fetchData();

        return () => {
            if (cancelTokenRef.current) {
                cancelTokenRef.current.cancel('Request canceled due to component unmounting.');
            }
        }
        
    }, []);


    return (
        <SpecialMessagesContext.Provider value={{ specialMessages}}>
            {children}
        </SpecialMessagesContext.Provider>
    );
};

// Hook para usar el contexto de alertas en cualquier componente
export const useSpecialMessages = (): SpecialMessagesContextProps => {
    const context = useContext(SpecialMessagesContext);
    if (!context) {
        throw new Error('useSpecialMessages must be used within an SpecialMessagesProvider');
    }
    return context;
};
