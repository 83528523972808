import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import { getNotifications, setNotificationsSeen, setAllNotificationsSeen, getNotification } from '../features/notifications/Notification';
import { useUserPreferences } from './UserPreferencesContext';
import { useAlert } from './AlertContext';
import { Notification, useToaster } from 'rsuite';
import Icon from '../components/Icon';

// Tipos
export interface Notification {
    id: number;
    category: string;
    event_date: string;
    lat: number;
    lng: number;
    link: string;
    message: string;
    seen: boolean;
    title: string;
    type: string;
}


export interface NotificationContextProps {
    notifications: Notification[];
    updateNotifications: (notification: Notification[]) => void;
    updateNotificationsFromWebSocket: (notification_id: string) => void;
    new_notifications: boolean;
    unred_notifications: number;
    show_notifications: boolean;
    setShowNotifications: React.Dispatch<React.SetStateAction<boolean>>;
    notifications_loading: boolean;
    setSeenNotification: (notification: Notification) => void;
    setSeenAllNotifications: () => void;
}

const NotificationContext = createContext<NotificationContextProps | undefined>(undefined);

const message = (title: string, message: string) => {
    return (
        <Notification closable>
            <div className="notification-title flex-row align-center">
                <Icon icon="warning" size={16} className='notification-icon'/> <span className='font-bold'>{title}</span>
            </div>
            <div className="notification-message">{message}</div>
        </Notification>
    )
}

export const NotificationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const notificationsRef = useRef<Notification[]>([]); // Crear el useRef para almacenar el estado más reciente
    const [new_notifications, setNewNotifications] = useState<boolean>(false);
    const [unred_notifications, setUnredNotifications] = useState<number>(0);
    const { loading_preferences, notification_sound_url } = useUserPreferences();
    const [show_notifications, setShowNotifications] = useState<boolean>(false);
    const [notifications_loading, setNotificationsLoading] = useState<boolean>(true);
    const { showAlert } = useAlert();
    const toaster = useToaster();

    useEffect(() => {
        notificationsRef.current = notifications;
    }, [notifications]);

    // console.log({notifications})
    
    useEffect(() => {
        if (!loading_preferences) {
            // console.log(notification_sound_url);
            setTimeout(() => {
                requestNotifications();
            }, 1000);
        }
    }, [loading_preferences]);


    const updateNotificationsFromWebSocket = (notification_id: string) => {
        // reproducir sonido
        
        getNotification(notification_id).then((resp) => {
            if (resp.success) {
                const audio = new Audio(notification_sound_url);
                audio.play();
                // console.log('notifications from ref:', notificationsRef.current);
                const newNotifications = [resp.notification, ...notificationsRef.current];
                if(newNotifications.length > 10){
                    newNotifications.pop();
                }
                setNotifications(newNotifications);
                setUnredNotifications((prev) => prev + 1);
                setNewNotifications(true);
                toaster.push(message(resp.notification.title, resp.notification.message), {placement: 'topEnd'});
            }
        }).catch((error) => {
            // console.log('error', error);
            showAlert('error', 'Error al obtener la notificación, error: ' + error);
        });
    };

    const updateNotifications = (notifications: Notification[]) => {
        setNotifications(notifications);
        requestNotifications();
    }

    const requestNotifications = async () => {
        try {
            const response = await getNotifications();
            if('notifications' in response){
                setNotifications(response.notifications);
                setUnredNotifications(response.unred_notifications);
                setNewNotifications(response.new_notifications);
            }
        } catch (error) {
            // console.log('error', error);
            showAlert('error', 'Error al obtener las notificaciones, error: ' + error);
        } finally {
            setNotificationsLoading(false);
        }
    }


    const setSeenNotification = async (notification: Notification) => {
        notification.seen = true;
        setNotifications(notifications.map(n => n.id === notification.id ? notification : n));
        setUnredNotifications(unred_notifications - 1);
        setNewNotifications(unred_notifications - 1 > 0);
        try{
            await setNotificationsSeen([notification.id]);
        } catch (error) {
            notification.seen = false;
            setNotifications(notifications.map(n => n.id === notification.id ? notification : n));
            setUnredNotifications(unred_notifications + 1);
            setNewNotifications(true);
        }
    }

    const setSeenAllNotifications = async () => {
        // Almacena el estado actual de las notificaciones antes de modificarlas
        const previousNotifications = notifications.map(notification => ({ ...notification }));
        
        // Marca todas las notificaciones como vistas
        const updatedNotifications = notifications.map((n) => {
            if (!n.seen) {
                n.seen = true;
            }
            return n;
        });
    
        // Actualiza el estado local
        setNotifications(updatedNotifications);
        setUnredNotifications(0);
        setNewNotifications(false);
    
        try {
            // Realiza la petición para marcar todas como vistas
            const resp = await setAllNotificationsSeen();

            if (resp.success) {
                showAlert('success', 'Todas las notificaciones han sido marcadas como leídas');
            }

        } catch (error) {
            // Si hay un error, restaura el estado previo de las notificaciones
            setNotifications(previousNotifications);
            // Recalcula el número de notificaciones no vistas
            const unredCount = previousNotifications.filter(n => !n.seen).length;
            setUnredNotifications(unredCount);
            setNewNotifications(unredCount > 0);
        }
    };
    
    



    return (
        <NotificationContext.Provider value={{ 
            notifications, 
            new_notifications, 
            unred_notifications, 
            updateNotifications,
            updateNotificationsFromWebSocket,
            show_notifications,
            setShowNotifications,
            notifications_loading,
            setSeenNotification,
            setSeenAllNotifications
        }}>
            {children}
        </NotificationContext.Provider>
    );
}


export const useNotifications = () => {
    const context = useContext(NotificationContext);
    if (!context) {
        throw new Error('useNotifications debe ser usado dentro de un NotificationProvider');
    }
    return context;
}
