import ApiClient from "../../services/api_client";
import { CancelTokenSource } from 'axios';



export const getExternalLinks = async (cancelToken?: CancelTokenSource): Promise<any> => {
    try {
        const response = await ApiClient.get({
            endpoint: `/external_links/`,
            cancelToken: cancelToken?.token 
        });
        return response;

    } catch (error) {
        throw error;
    }
}

export const getExternalLink = async (id: string, cancelToken?: CancelTokenSource): Promise<any> => {
    try {
        const response = await ApiClient.get({
            endpoint: `/external_links/show/${id}`,
            cancelToken: cancelToken?.token 
        });
        return response;

    } catch (error) {
        throw error;
    }
}

export const createExternalLink = async (data: any, cancelToken?: CancelTokenSource): Promise<any> => {
    try {
        const response = await ApiClient.post({
            endpoint: `/external_links/create`,
            data: data,
            cancelToken: cancelToken?.token 
        });
        return response;

    } catch (error) {
        throw error;
    }
}

export const updateExternalLink = async (id: string, data: any, cancelToken?: CancelTokenSource): Promise<any> => {
    try {
        const response = await ApiClient.put({
            endpoint: `/external_links/update/${id}`,
            data: data,
            cancelToken: cancelToken?.token 
        });
        return response;

    } catch (error) {
        throw error;
    }
}


export const deleteExternalLink = async (id: string, cancelToken?: CancelTokenSource): Promise<any> => {
    try {
        const response = await ApiClient.delete({
            endpoint: `/external_links/destroy/${id}`,
            cancelToken: cancelToken?.token 
        });
        return response;

    } catch (error) {
        throw error;
    }
}

