// src/contexts/TourContext.tsx
import React, { createContext, useContext, useRef, useState, ReactNode, MutableRefObject } from 'react';

interface TourContextType {
  ref1: MutableRefObject<HTMLDivElement | null>;
  ref2: MutableRefObject<HTMLDivElement | null>;
  open: boolean;
  setOpen: (open: boolean) => void;
}

const TourContext = createContext<TourContextType | undefined>(undefined);

export const TourProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const ref1 = useRef<HTMLDivElement | null>(null);
    const ref2 = useRef<HTMLDivElement | null>(null);
    const [open, setOpen] = useState(true);

    return (
        <TourContext.Provider value={{ ref1, ref2, open, setOpen }}>
        {children}
        </TourContext.Provider>
    );
};

export const useTour = (): TourContextType => {
  const context = useContext(TourContext);
  if (!context) {
    throw new Error('useTour must be used within a TourProvider');
  }
  return context;
};
