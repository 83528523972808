import React, { useEffect, useState, useRef } from "react";
import { GpsDeviceType } from "../../../types/device";
import { Button, Toggle } from "rsuite";
import { Input, Modal, Form, ColorPicker } from "antd";
import "./QuickView.scss";
import Icon from "../../Icon";
import { createLinearGeofence } from "../../../features/map/Map";
import { useUserPreferences } from "../../../contexts/UserPreferencesContext";
import { useDevices } from "../../../contexts/GpsDeviceContext";
import { useAlert } from "../../../contexts/AlertContext";
import axios, { CancelTokenSource } from "axios";
import momentVariant from "../../../utils/momentConfig";
import { getDeviceQuickView } from "../../../features/map/Map";

interface QuickViewProps {
    device: GpsDeviceType;
    // coordinates: Array<{ lat: number, lng: number }>; // Nueva prop
}

const QuickView: React.FC<QuickViewProps> = ({ device }) => {
    const [form] = Form.useForm();
    const { addNewLinearGeofence } = useUserPreferences();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const { setQuickViewState } = useDevices();
    const { showAlert } = useAlert();
    const cancelTokenRef = useRef<CancelTokenSource | null>(null);
    const isQuickViewActiveRef = useRef<boolean>(device.quickViewActive); // Almacena el estado más reciente del toggle



    const calculateDistance = (lat1, lng1, lat2, lng2) => {
        const toRad = (value) => (value * Math.PI) / 180;
        const R = 6371e3; // Radio de la tierra en metros
        const φ1 = toRad(lat1);
        const φ2 = toRad(lat2);
        const Δφ = toRad(lat2 - lat1);
        const Δλ = toRad(lng2 - lng1);
    
        const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
                  Math.cos(φ1) * Math.cos(φ2) *
                  Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    
        return R * c; // Distancia en metros
    };

    const sanitizeCoordinates = (events) => {
        return events
            .filter(event => event.lat !== null && event.lng !== null)
            .map(event => ({ lat: event.lat, lng: event.long, orientation: event.orientation }))
            .filter((coord, index, self) => 
                index === 0 || // Mantener el primer punto
                (coord.lat !== self[index - 1].lat || coord.lng !== self[index - 1].lng)) // Eliminar duplicados
            .filter((coord, index, self) => {
                if (index === 0) return true; // Mantener el primer punto
                const prev = self[index - 1];
                const distance = calculateDistance(prev.lat, prev.lng, coord.lat, coord.lng);
                return distance > 15; // Mantener solo los puntos que están separados por al menos 15 metros
            });
    };

    const handleToggleQuickView = async (checked: boolean) => {
        // Actualiza el estado local inmediatamente
        isQuickViewActiveRef.current = checked;
        setQuickViewState(device.imei, checked, device.quickViewColor);
    
        if (checked) {
            try {
                const customMomentVariant = momentVariant();
                let startDate = customMomentVariant().startOf('day').format('YYYY-MM-DD HH:mm');
                let endDate = customMomentVariant().format('YYYY-MM-DD HH:mm');
    
                if (cancelTokenRef.current) {
                    cancelTokenRef.current.cancel('Request canceled due to new request or toggle deactivation.');
                }
    
                cancelTokenRef.current = axios.CancelToken.source();
    
                const resp = await getDeviceQuickView(device.imei, startDate, endDate, cancelTokenRef.current);
    
                // Verificar el valor más reciente del ref para saber si el toggle sigue activo
                if (isQuickViewActiveRef.current) {
                    // console.log('Actualizando QuickView:', resp);
                    if ("events" in resp && resp.events?.length > 0) {
                        const sanitizedCoordinates = sanitizeCoordinates(resp.events);
                        setQuickViewState(device.imei, true, device.quickViewColor, sanitizedCoordinates);
                    } else {
                        setQuickViewState(device.imei, true, device.quickViewColor, []);
                    }
                }
            } catch (error) {
                if (axios.isCancel(error)) {
                    // console.log('Request canceled:', error.message);
                } else {
                    showAlert('error', 'Ocurrió un error al obtener los eventos de la vista rápida, error: ' + error);
                    setQuickViewState(device.imei, false, device.quickViewColor);  // Si hay error, desactiva QuickView
                }
            }
        } else {
            // Si se desactiva el toggle, cancelar cualquier request pendiente
            if (cancelTokenRef.current) {
                cancelTokenRef.current.cancel('Request canceled due to toggle deactivation.');
            }
    
            // Restablecer el estado
            setQuickViewState(device.imei, false, device.quickViewColor, []);
        }
    };
    
    

    const hexString = React.useMemo<string>(
        () => (typeof device.quickViewColor === 'string' ? device.quickViewColor : device.quickViewColor),
        [device.quickViewColor],
    );

    const onFinish = async (values) => {
        setSubmitting(true);
        // Convertir coordinates a un objeto con índices como claves y coordenadas como valores
        let data = {
            name: values.name,
            color: device.quickViewColor,
            points: device.quickViewCoordinates.reduce((obj, coord, index) => {
                obj[index] = {
                    lat: coord.lat,
                    lng: coord.lng,
                };
                return obj;
            }, {}), // Inicializamos como objeto vacío
        };
    
        const resp = await createLinearGeofence({ georoute: data });

        try{
            if (resp.success) {
                setShowModal(false);
                setSubmitting(false);
                form.resetFields();
        
                addNewLinearGeofence(resp.georoute);
                showAlert('success', 'Geocerca creada correctamente');
        
            } else {
                showAlert('error', 'Ocurrió un error al crear la geocerca');
                setSubmitting(false);
            }
        } catch (error) {  
            showAlert('error', 'Ocurrió un error al crear la geocerca, error: ' + error);
            setSubmitting(false);
        }
    
    };

    const handleColorChange = (color) => {
        const newColor = color.toHexString();
        if (newColor !== device.quickViewColor) {
            setQuickViewState(device.imei, device.quickViewActive, newColor, device.quickViewCoordinates);
        }
    };

    return (
        <>
            <div className="datasheet">
                <div className="flex-column mb-1">
                    <span className="title">Recorrido</span>
                    <div className="toggle-sidebar">
                        <Toggle 
                            classPrefix="toggle" 
                            onChange={handleToggleQuickView} 
                            color="blue" 
                            checked={device.quickViewActive} // Vinculado al estado global del dispositivo
                        >
                            Activar
                        </Toggle>
                    </div>
                </div>
                {device.quickViewActive && ( // Mostrar la opción si está activado
                    <>
                        <div className="general-info mb-1">
                            <div className="flex-column">
                                <span>Selecciona un color</span>
                                <div className="flex-row align-center">
                                    <Input disabled={true} value={hexString}  style={{ width: 100, marginRight: 10 }} />
                                    <ColorPicker
                                        defaultValue={hexString}
                                        onChangeComplete={
                                            (color) => handleColorChange(color)
                                        }
                                        // onChange={handleColorChange} // Cambia el color del dispositivo
                                    />
                                </div>
                            </div>
                        </div>

                        <Button appearance="ghost" className="text-bold" onClick={() => setShowModal(true)}> Guardar como geocerca</Button>
                    </>
                )}
            </div>

            <Modal
                title={
                    <div className="flex-row align-center">
                        <div className="modal-icon-title">
                            <Icon icon="route" size="18" />
                        </div>
                        <span className="modal-title">Crear Geocerca Lineal</span>
                    </div>
                }
                width={721}
                centered
                maskClosable={false}
                open={showModal}
                onCancel={() => {
                    form.resetFields();
                    setShowModal(false);
                    setSubmitting(false);
                    
                }}
                afterClose={() => {
                    form.resetFields();
                    setShowModal(false);
                    setSubmitting(false);
                }}
                footer={null}
            >
                <div className="modal-body">
                    <div className="select-lineal-geofence">
                        <Form
                            form={form}
                            name="linear-geofence-form"
                            layout="vertical"
                            style={{ maxWidth: 600 }}
                            onFinish={onFinish}
                            autoComplete="off"
                        >
                            <Form.Item
                                name="name"
                                label="Nombre de la geocerca"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor ingresa el nombre de la geocerca',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Form>
                    </div>
                </div>
                <div className="modal-footer">
                    <Button onClick={() => {
                        form.submit();
                    }} className="text-bold" appearance="primary" loading={submitting}>
                        Crear geocerca
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default QuickView;
