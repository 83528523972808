import React, {useRef, useEffect, useState} from 'react';
import {useMapsLibrary} from '@vis.gl/react-google-maps';
import { Button } from 'rsuite';
import Icon from '../Icon';
import { useUserPreferences } from '../../contexts/UserPreferencesContext';

interface Props {
  onPlaceSelect: (place: google.maps.places.PlaceResult | null) => void;
}

// This is an example of the classic "Place Autocomplete" widget.
// https://developers.google.com/maps/documentation/javascript/place-autocomplete
export const PlaceAutocompleteClassic = ({onPlaceSelect}: Props) => {
  const [placeAutocomplete, setPlaceAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const places = useMapsLibrary('places');

  const {setSearchPlacesActive} = useUserPreferences();

  useEffect(() => {
    if (!places || !inputRef.current) return;

    const options = {
      fields: ['geometry', 'name', 'formatted_address']
    };

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
  }, [places]);

  useEffect(() => {
    if (!placeAutocomplete) return;

    placeAutocomplete.addListener('place_changed', () => {
      onPlaceSelect(placeAutocomplete.getPlace());
    });
  }, [onPlaceSelect, placeAutocomplete]);

  return (
    <div className="map-tool-container">
        <div className='header'>
            <Button onClick={() => setSearchPlacesActive(false)} appearance='subtle'>
                <Icon icon="exit" size={10} />
            </Button>
        </div>
        <div className='content'>
            <span className='title'>Buscar ubicaciones</span>
            <p>Ingresa una dirección o lugar para buscar</p>
        </div>
        <input ref={inputRef} className='search-places'/>
    </div>
  );
};