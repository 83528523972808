import { useState, useEffect } from 'react';
import { getDrivers } from '../features/drivers/Driver';

let cachedDrivers = null;

export const useDrivers = () => {
    const [drivers, setDrivers] = useState(cachedDrivers || []);
    const [selectedDriver, setSelectedDriver] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [newDriver, setNewDriver] = useState(null);

    const fetchDrivers = async () => {
        const res = await getDrivers();
        if (res.success) {
            cachedDrivers = res.drivers;
            setDrivers(res.drivers);
            // Si el conductor seleccionado no está en la nueva lista, deseleccionarlo
            if (!res.drivers.some(driver => driver.id === selectedDriver)) {
                setSelectedDriver(null);
            }
        }
    };

    useEffect(() => {
        if (!cachedDrivers) {
            fetchDrivers();
        }
    }, []);

    const refreshDrivers = () => {
        cachedDrivers = null;
        fetchDrivers();
    };

    const handleDriverSelection = (driverId) => {
        setSelectedDriver(driverId);
    };

    const handleDriverCreation = (newDriver) => {
        setDrivers(prevDrivers => [...prevDrivers, newDriver]);
        setNewDriver(newDriver);
        handleDriverSelection(newDriver.id);
    };

    return {
        drivers,
        selectedDriver,
        submitting,
        newDriver,
        setSubmitting,
        handleDriverSelection,
        refreshDrivers,
        handleDriverCreation,
    };
};
