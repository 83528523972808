
//LOCAL

// export const API_URL = "http://localhost:3000/v1";
// export const MAPI_URL = "https://lite.dumaxst.com:5000/v1";
// export const APP_URL = "http://localhost:3000";
// export const APP_URL = "https://lite.dumaxst.com";

//STAGING
export const API_URL = "https://app.mobility-go.mx/v1";
export const MAPI_URL = "https://app.mobility-go.mx:5000/v1";
export const APP_URL = "http://app.mobility-go.mx";