import React from "react";
import { Button } from "rsuite";
import { GpsDeviceType } from "../../../types/device";
import { getHistoric, sendCommand } from "../../../features/config_messages/ConfigMessage";
import { useAlert } from "../../../contexts/AlertContext";
import momentVariant from "../../../utils/momentConfig";
import { Modal, Form, Input } from "antd";
import { useUserPreferences } from "../../../contexts/UserPreferencesContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import Icon from "../../Icon";

const CommandModal = ({ open, handleOpen, commands }) => {
    const customMomentVariant = momentVariant();
    return(
        <Modal
            title={
                <div className="flex-row align-center">
                    <div className="modal-icon-title">
                        <Icon icon="console" size="18" />
                    </div>
                    <span className="modal-title">Acciones (Consola)</span>
                </div>
            }
            width={721}
            centered
            maskClosable={false}
            open={open}
            footer={null}
            onCancel={handleOpen}
        >
            <div className="table-container">
                <table className="responsive-table">
                        <thead>
                            <tr>
                                <th>Enviado por</th>
                                <th>Comando</th>
                                <th>Tipo</th>
                                <th>Fecha</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                                commands.map((command, index) => (
                                    <tr key={command._id.$oid}>
                                        <td data-label="Enviado por">{command.user.username}</td>
                                        <td data-label="Comando">{command.command}</td>
                                        <td data-label="Tipo">{command.channel_type}</td>
                                        <td data-label="Fecha">{customMomentVariant(command.created_at).format('DD/MM/YYYY HH:mm:ss')}</td>
                                    </tr>
                                ))
                        }
                        </tbody>
                </table>
            </div>
        </Modal>
        
    )
}

const ConfirmModal = ({ open, onCancel, handleConfirm, command, device, submiting }) => {
    const {preferences} = useUserPreferences();
    const customMoment = momentVariant();
    const [password, setPassword] = React.useState("");
    const [additional_data, setAdditionalData] = React.useState("");
    const [form] = Form.useForm();

    if (command.name.toLowerCase() === "activar bloqueo de motor" || command.name.toLowerCase() === "desactivar bloqueo de motor"){
        return(
            <Modal
                title={
                    <div className="flex-row align-center">
                        <div className="modal-icon-title">
                            <Icon icon="console" size="18" />
                        </div>
                        <span className="modal-title">{command.name}</span>
                    </div>
                }
                width={721}
                centered
                maskClosable={false}
                open={open}
                footer={null}
                onCancel={onCancel}
            >
                <div className="horizontal-divider"></div>
                <div className="modal-content-confirm text-center ">
                    <p>Usted está a punto de realizar la acción de <span className={command.name.toLowerCase() === "activar bloqueo de motor" ? 'text-bold text-warning' : 'text-bold text-danger'}>{command.name}</span> de la unidad</p>
                    <p className="heighlight">{device.name}</p>
                    <p>Actualmente el estado del motor es</p>
                    <p className="heighlight">{device.engine_status} | {device.speed} {preferences?.user_preferences.units.odometer} / h | Reportó hace {customMoment(device.last_connection).fromNow()}</p>

                    <div className="horizontal-divider"></div>

                    <p className="text-danger text-bold">Detener o reactivar la unidad pudiera causar un accidente</p>
                    <p className="mb-1">Si está seguro de que desea continuar, por favor introduzca la clave de autorización</p>
                    <div className="flex-column align-center">
                        <div style={{width: 240}} className="flex-column">
                            <Form 
                                layout="vertical"
                                onFinish={() => handleConfirm(password, null)}
                                form={form}
                            >
                                <Form.Item
                                    label="Clave de autorización"
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor ingrese la clave de autorización',
                                        }
                                    ]}
                                >
                                    <Input 
                                        placeholder="Clave de autorización"
                                        type="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </Form.Item>
                                
                            </Form>
                        </div>
                    </div>

                    <div className="horizontal-divider"></div>

                    <div className="flex-row justify-between">
                        <Button 
                            appearance="ghost"
                            className='cancel mr-1'
                            onClick={onCancel}
                        >
                            Cancelar
                        </Button>
                        <Button 
                            appearance="primary"
                            className='save'
                            onClick={() => form.submit()}
                            loading={submiting}
                        >
                            Confirmar
                        </Button>
                    </div>
                </div>
            </Modal>
        )

    }


    return(
        <Modal
            title={
                <div className="flex-row align-center">
                    <div className="modal-icon-title">
                        <Icon icon="console" size="18" />
                    </div>
                    <span className="modal-title">Confirmar acción</span>
                </div>
            }
            width={721}
            centered
            maskClosable={false}
            open={open}
            footer={null}
            onCancel={onCancel}
        >
            <div className="modal-content">
                <span>¿Está seguro de realizar esta acción?</span>
                <div className="flex-row justify-end">
                    <Button 
                        appearance="ghost"
                        className='btn-cancel'
                        onClick={onCancel}
                    >
                        Cancelar
                    </Button>
                    <Button 
                        className='btn-confirm'
                        onClick={() => handleConfirm(password, additional_data)}
                    >
                        Confirmar
                    </Button>
                </div>
            </div>
        </Modal>
        
    )
}


const HistoricCommands = ({ historicCommands }) => {
    const [open, setOpen] = React.useState(false);
    const customMomentVariant = momentVariant();

    return (
        <>
            {
                historicCommands? 
                    historicCommands.slice(0,3).map((command, index) => (
                        <div className="flex-row justify-between command-container" key={command._id.$oid}>
                            <div className="send-for flex-column">
                                <span>Enviado por:</span>
                                <span className="text-bold">{command.user.username}</span>
                            </div>
                            <div className="command flex-column">
                                <span>Comando:</span>
                                <span className="text-bold">{command.command}</span>
                            </div>
                            <div className="channel_type flex-column">
                                <span>Tipo:</span>
                                <span className="text-bold">{command.channel_type}</span>
                            </div>
                            <div className="date flex-column">
                                <span>{customMomentVariant(command.created_at).format('DD/MM/YYYY HH:mm:ss')}</span>
                            </div>
                        </div>  
                    ))
                :
                <div className="command">
                    <span className="command-name">No hay comandos</span>
                </div>
            }

            <div className="show-more flex-column align-center">
                <Button 
                    appearance="ghost"
                    className='text-bold'
                    onClick={() => setOpen(!open)}
                >
                    {"Ver más >"}
                </Button>
            </div>
            <CommandModal open={open} handleOpen={() => setOpen(!open)} commands={historicCommands} />
        </>
    )
}

const ModalSync = ({ open, onCancel }) => {
    const [showCloseButton, setShowCloseButton] = React.useState(false);

    React.useEffect(() => {
        let timer;
        // Si el modal está abierto, iniciar el temporizador de 10 segundos
        if (open) {
            timer = setTimeout(() => {
                setShowCloseButton(true);
            }, 10000); // 10 segundos
        }
        
        // Limpiar el temporizador si el modal se cierra antes de los 10 segundos
        return () => {
            clearTimeout(timer);
            setShowCloseButton(false); // Reiniciar el estado cuando el modal se cierre
        };
    }, [open]);

    return(
        <Modal
            title={null}
            closable={false}
            width={485}
            centered
            maskClosable={false}
            open={open}
            footer={null}
            onCancel={onCancel}
            keyboard={false}
        >
            <div className="alert-content">
                <div className={`alert-circle info`}>
                    <span className="alert-icon"><FontAwesomeIcon icon={faSync} /></span>
                </div>
                <div className='alert-message-container'>
                    <h3 className="alert-message-title">Actualizando dispositivo</h3>
                    <p className="alert-message">Espere un momento mientras se actualiza la conexión.</p>
                    <p className="alert-message">No es necesario realizar ninguna acción.</p>
                </div>

                {showCloseButton && (
                    <>
                        <div className="horizontal-divider"></div>
                        <div className="flex-row justify-center mt-3 alert-center">
                            <Button appearance="ghost" onClick={onCancel}>
                                Cerrar
                            </Button>
                        </div>
                    </>
                )}

            </div>
        </Modal>
        
    )
}

const Commands: React.FC<{ device: GpsDeviceType }> = ({ device }) => {
    const [historicCommands, setHistoricCommands] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [commandActive, setCommandActive] = React.useState(null);
    const [commandActiveSended, setCommandActiveSended] = React.useState(null);
    const [submiting, setSubmiting] = React.useState(false);
    const [waitingForReport, setWaitingForReport] = React.useState(false);
    const { showAlert } = useAlert();
    const previousLastConnection = React.useRef(device.last_connection);

    React.useEffect(() => {
        const fetchHistoric = async () => {
            try {
                const response = await getHistoric(device.imei);
                if (response.success) {
                    setHistoricCommands(response.commands_history);
                }
            } catch (error) {
                showAlert("error", "Error al cargar el historial de comandos", error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchHistoric();

    }, [device]);
   

    // Efecto para verificar cuando el dispositivo reporte el cambio de estado
    React.useEffect(() => {
        if (waitingForReport && device?.params && commandActiveSended) {
            const commandName = commandActiveSended.name.toLowerCase();
    
        
            if (commandName === "activar bloqueo de motor" && device?.params?.engine_stopped) {
                setWaitingForReport(false);
                setCommandActiveSended(null);

            } else if (commandName === "desactivar bloqueo de motor" && !device?.params?.engine_stopped) {
                setWaitingForReport(false);
                setCommandActiveSended(null);
            } 
            // Validación adicional: Si el comando no es de motor, revisa también si el last_connection ha cambiado
            else if (commandName !== "activar bloqueo de motor" && commandName !== "desactivar bloqueo de motor" && device?.params?.text) {
                if (device.last_connection !== previousLastConnection.current) {
                    setWaitingForReport(false);
                    setCommandActiveSended(null);
                }
            }
        }
    }, [device.params, device.last_connection, waitingForReport, commandActiveSended]);
    
    

    const handleSendCommand = (command, passwordConfirmation, additionalData) => {
        setSubmiting(true);
        
        const data = {
            kind: "imei",
            imei: device.imei,
            message: {
                name: command.name,
                data: additionalData,
                password: passwordConfirmation,
                isCustom: command.isCustom || false,
                gprs_only: command.gprs_only
            }
        };
        
        sendCommand(device.imei, data)
            .then((res) => {
                if (res.success) {
                    setCommandActive(null);
                    setCommandActiveSended(command);
                    setWaitingForReport(true);
                    
                    showAlert("success", "Comando enviado", res.message);
                } else {
                    showAlert("error", "Error al enviar el comando", res.message);
                    setCommandActive(null);
                    setWaitingForReport(false);
                    setCommandActiveSended(null);
                }
            })
            .catch((err) => {
                showAlert("error", "Error al enviar el comando", err.message);
                setCommandActive(null);
                setWaitingForReport(false);
                setCommandActiveSended(null);
            })
            .finally(() => {
                setSubmiting(false);
            });
    };

    const handleSendCommandCommon = (command, passwordConfirmation, additionalData) => {
        setSubmiting(true);
        setCommandActive(null);
        setCommandActiveSended(command);
        setWaitingForReport(true);
        // Guarda el valor actual de last_connection antes de enviar el comando
        previousLastConnection.current = device.last_connection;
        
        const data = {
            kind: "imei",
            imei: device.imei,
            message: {
                name: command.name,
                data: additionalData,
                password: passwordConfirmation,
                isCustom: command.isCustom || false,
                gprs_only: command.gprs_only
            }
        };
        
        sendCommand(device.imei, data)
            .then((res) => {
                if (!res.success) {
                    showAlert("error", "Error al enviar el comando", res.message);
                    setCommandActive(null);
                    setWaitingForReport(false);
                    setCommandActiveSended(null);
                }
            })
            .catch((err) => {
                showAlert("error", "Error al enviar el comando", err.message);

                setCommandActive(null);
                setWaitingForReport(false);
                setCommandActiveSended(null);

            })
            .finally(() => {
                setSubmiting(false);
            });
    }
    

    const handleCommandClick = (command) => {
        if (command.password || command.data) {
            setCommandActive(command);
            return;
        }

        if(command.name.toLowerCase() !== "activar bloqueo de motor" || command.name.toLowerCase() !== "desactivar bloqueo de motor"){
            handleSendCommandCommon(command, null, null);
        }else{
            handleSendCommand(command, null, null);
        }
    };

    return (
        <div className="datasheet">
            <div className="flex-row mb-2">
                <span className="title">Acciones (Comandos)</span>
            </div>
            <div className="commands">
                {device.shortcuts
                    .filter((command) => {
                        if (device?.params?.engine_stopped && command.name.toLowerCase() === "activar bloqueo de motor") return false;
                        if (!device?.params?.engine_stopped && command.name.toLowerCase() === "desactivar bloqueo de motor") return false;
                        return true;
                    })
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((shortcut, index) => (
                        <Button
                            appearance="ghost"
                            key={index}
                            className={"text-bold"}
                            onClick={() => handleCommandClick(shortcut)}
                        >
                            {shortcut.name}
                        </Button>
                    ))}
            </div>

            <div className="historic-commands">
                <span className="title">Últimas acciones</span>
                {loading ? (
                    <div className="loading">
                        <span>Cargando...</span>
                    </div>
                ) : (
                    <HistoricCommands historicCommands={historicCommands} />
                )}
            </div>

            {commandActive && (
                <ConfirmModal
                    open={!!commandActive.password || !!commandActive.data}
                    onCancel={() => {
                        setCommandActive(null);
                        setSubmiting(false);
                    }}
                    handleConfirm={(passwordConfirmation, additionalData) => handleSendCommand(commandActive, passwordConfirmation, additionalData)}
                    command={commandActive}
                    submiting={submiting}
                    device={device}
                />
            )}

            {waitingForReport && (
                <ModalSync
                    open={waitingForReport}
                    onCancel={() => {
                        setWaitingForReport(false);
                        setCommandActiveSended(null);
                    }}
                />
            )}

        </div>
    );
};

export default Commands;
